import {
  AppstoreOutlined,
  EyeFilled,
  EyeInvisibleFilled,
  EyeInvisibleOutlined,
  EyeOutlined,
  SearchOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Button, Col, Input, Modal, Row } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  SideMenu,
  get_currencies,
  headerTitles,
} from "../../actions/masterdata/masterdata";
import {
  get_person_accounts,
  GetPersonAccounts,
} from "../../actions/z_accounts";
import { apis } from "../../config/APIs";
import { currencyMapper, exchageRates } from "../../config/helper";
import usePayAxios from "../../config/useAxios";
import { amountFormat, reverseCurrencyConverter } from "../../utils";
import AccountCard, { AccountList } from "./AccountCard";
import AddAccount from "./AddAccount";
import Transfer from "./Transfer";
import LazyLoading from "../Common/Lazyloading";

const PrimaryAcc = ({}) => {
  const [openNewAcc, setOpenNewAcc] = useState(false);
  const [openTransfer, setOpenTransfer] = useState(false);
  const [showList, setShowList] = useState(true);

  const location = useLocation();
  const { t } = useTranslation();
  const bankAcc = useSelector(
    (state) => state?.z_accs?.accounts?.data?.data || []
  );
  const bankAccObj = useSelector((state) => state?.z_accs?.accounts || []);
  const { onCall } = usePayAxios({
    api: apis.accountBalanceByMasterID,
    method: "get",
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const [showBalance, setShowBalance] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [page, setPage] = useState(1);
  const [showBalanceObj, setShowBalanceObj] = useState({});

  useEffect(() => {
    if (location.state?.success) {
      alert(t("bank_account_added"));
      history.replace(location.pathname);
    }

    if (location.state?.success === false) {
      alert(t("failed_add_accounts"));
      history.replace(location.pathname);
    }

    if (location.state?.from || location.state?.to) {
      setOpenTransfer(true);
      // history.replace(location.pathname);
    }

    dispatch(
      get_person_accounts({
        params: { page_number: page },
        person_id: "ID-1146",
      })
    );
    
    dispatch(
      headerTitles({
        title: "Accounts",
        description: "Manage all your global accounts.",
      })
    );
    let locationSplit = location?.pathname?.split("/");
    dispatch({
      type: SideMenu,
      payload: {
        firstNav: "/" + locationSplit?.[1],
        secondNav: "/" + locationSplit?.[2],
        showChildren: true,
        childrenMenu: "/" + locationSplit?.[3],
      },
    });
    dispatch(
      get_currencies({
        all: true,
      })
    );
  }, [location.state]);

  useEffect(() => {
    if (bankAcc?.length) {
      setAccounts((accs) => _.uniqBy([...accs, ...bankAcc], "account_number"));
      dispatch({
        type: GetPersonAccounts.RESET,
      });
    }
  }, [bankAcc]);

  const accs =
    accounts?.filter((ele) => ele?.account_type === "CURRENCY").length === 0
      ? accounts
      : accounts?.filter((ele) => ele?.account_type === "CURRENCY");

  return (
    <>
      {/* <AddNewPrimaryAcc
        open={openNewAcc && hasWriteAccess}
        onClose={() => {
          setOpenNewAcc(false);
        }}
      /> */}
      {openNewAcc && (
        <Modal
          visible={openNewAcc}
          className="right-alinged-modal"
          onCancel={() => {
            setOpenNewAcc(false);
          }}
          title={t("Add Account")}
        >
          <AddAccount
            setOpenAddNew={setOpenNewAcc}
            cb={() => {
              dispatch(
                get_person_accounts({
                  params: { page_number: page },
                  person_id: "ID-1146",
                })
              );
            }}
          />
        </Modal>
      )}
      {openTransfer && (
        <Modal
          visible={openTransfer}
          className="right-alinged-modal"
          onCancel={() => {
            setOpenTransfer(false);
          }}
          title={t("Transfer")}
        >
          <Transfer
            setOpenAddNew={setOpenTransfer}
            cb={() => {
              dispatch(
                get_person_accounts({
                  params: { page_number: page },
                  person_id: "ID-1146",
                })
              );
            }}
          />
        </Modal>
      )}
      <>
        <Row wrap={false} style={{ flexGrow: 1, display: "flex" }}>
          <Col flex="auto" style={{ display: "flex", flexGrow: "1" }}>
            <div style={{ flexGrow: 1 }}>
              <div style={{ flexGrow: 1, paddingTop: "22px" }}>
                <div
                  style={{
                    paddingLeft: "7px",
                    display: "flex",
                    direction: "row",
                    alignItems: "center",
                  }}
                  className="acc-header"
                >
                  <div className="send-payment" style={{ flexGrow: 1 }}>
                    <div className="item">
                      <span className="label">Total Available Balance</span>
                      <span className="value font-big">
                        <>
                          ${" "}
                          {showBalance["all"] ? (
                            showBalanceObj["all"]?.loading ? (
                              <span className="shimmer">
                                <span
                                  style={{
                                    width: "15rem",
                                    height: "2.4rem",
                                    marginLeft: 10,
                                  }}
                                ></span>
                              </span>
                            ) : (
                              amountFormat(showBalanceObj["all"]?.balance)
                            )
                          ) : (
                            "******".toUpperCase()
                          )}
                          <Button
                            className="eye mlHalf"
                            type="link"
                            icon={
                              !showBalance["all"] ? (
                                <EyeInvisibleFilled />
                              ) : (
                                <EyeFilled />
                              )
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              const accId = bankAcc?.filter(
                                (ele) => ele.account_type === "CURRENCY"
                              );
                              if (accId.length && !showBalance["all"]) {
                                setShowBalanceObj((obj) => ({
                                  ...obj,
                                  all: {
                                    loading: true,
                                  },
                                }));
                                Promise.all(
                                  accId?.map((ele) => {
                                    return onCall({
                                      params: {
                                        account_id: ele?.id,
                                      },
                                    });
                                  })
                                ).then((vals) => {
                                  let totalBal = 0;
                                  vals?.forEach((element) => {
                                    console.log(element);

                                    totalBal +=
                                      element?.data?.[0]
                                        ?.availableBalanceAmount;
                                  });
                                  setShowBalanceObj((obj) => ({
                                    ...obj,
                                    all: {
                                      loading: false,
                                      balance: totalBal,
                                    },
                                  }));
                                });
                              }
                              setShowBalance((bal) => ({
                                ...bal,
                                ["all"]: !showBalance["all"],
                              }));
                            }}
                          />
                        </>
                      </span>
                      <span className="label">
                        Your total balance was in USD as of now.
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div className="mr1">
                      <Input
                        className="pp-input"
                        size="large"
                        onChange={(e) => {
                          dispatch(
                            get_person_accounts({
                              params: { page_number: page },
                              person_id: "ID-1146",
                              search_key: e.target.value?.toLowerCase(),
                            })
                          );
                        }}
                        placeholder={t("searchh")}
                        prefix={<SearchOutlined style={{ color: "#B9B9B9" }} />}
                        style={{
                          backgroundColor: "#FAFAFA",
                          border: "1px solid #EAEAEA",
                          borderRadius: "5px",
                          width: "20rem",
                        }}
                      />
                    </div>
                    <div className="d-flex action-btns">
                      {/* <Button
                        disabled={!hasWriteAccess}
                        type="primary"
                        className="send-btn"
                        onClick={() => setOpenTransfer(true)}
                      >
                        <span>{t("Transfer")}</span>
                      </Button> */}
                      {/* <Button
                        disabled={!hasWriteAccess}
                        type="primary"
                        className="send-btn"
                        icon={
                          <div style={{ paddingRight: "10px" }}>
                            <PlusCircleFilled />
                          </div>
                        }
                        onClick={() => setOpenNewAcc(true)}
                      >
                        <span>{t("addNew")}</span>
                      </Button> */}
                      {/* Grid Icon */}

                      <button
                        className="sec-btn"
                        onClick={() => {
                          setShowList((b) => !b);
                        }}
                      >
                        {showList ? (
                          <UnorderedListOutlined />
                        ) : (
                          <AppstoreOutlined />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {showList ? (
                <AccountCard
                  onClick={(data) => {
                    history.push("/account/detail", {
                      account: data,
                    });
                  }}
                  data={accs}
                  addNew={() => setOpenNewAcc(true)}
                  showAcc={(record, data) => {
                    return (
                      <>
                        {showBalance[data.id] ? (
                          data?.account_type === "CURRENCY" ? (
                            showBalanceObj[data?.id]?.loading ? (
                              <span className="shimmer">
                                <span
                                  style={{
                                    width: "10rem",
                                    height: "1.8rem",
                                    marginLeft: 10,
                                  }}
                                ></span>
                              </span>
                            ) : (
                              <>
                                {
                                  currencyMapper[
                                    showBalanceObj[data?.id]?.balance?.[0]
                                      ?.currencyCode
                                  ]
                                }{" "}
                                {amountFormat(
                                  showBalanceObj[data?.id]?.balance?.[0]
                                    ?.availableBalanceAmount
                                )}
                              </>
                            )
                          ) : (
                            amountFormat(record?.balance)
                          )
                        ) : (
                          "****".toUpperCase()
                        )}
                        <Button
                          className="eye mlHalf"
                          type="link"
                          icon={
                            !showBalance[data.id] ? (
                              <EyeInvisibleFilled />
                            ) : (
                              <EyeFilled />
                            )
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            if (data?.account_type === "CURRENCY") {
                              setShowBalanceObj((obj) => ({
                                ...obj,
                                [data?.id]: { loading: true },
                              }));
                              onCall({
                                params: {
                                  account_id: data?.id,
                                },
                              }).then((res) => {
                                if (res?.data?.length) {
                                  setShowBalanceObj((obj) => ({
                                    ...obj,
                                    [data?.id]: {
                                      loading: false,
                                      balance: res?.data,
                                    },
                                  }));
                                }
                              });
                            }
                            setShowBalance((bal) => ({
                              ...bal,
                              [data.id]: !showBalance[data.id],
                            }));
                          }}
                        />
                      </>
                    );
                  }}
                />
              ) : (
                <AccountList
                  onClick={(data) => {
                    history.push("/account/detail", {
                      account: data,
                    });
                  }}
                  data={accs}
                  addNew={() => setOpenNewAcc(true)}
                  showAcc={(record, data) => (
                    <>
                      {showBalance[data.id] ? (
                        data?.account_type === "CURRENCY" ? (
                          showBalanceObj[data?.id]?.loading ? (
                            <span className="shimmer">
                              <span
                                style={{
                                  width: "7rem",
                                  height: "1.5rem",
                                  marginLeft: 10,
                                }}
                              ></span>
                            </span>
                          ) : (
                            <>
                              {
                                currencyMapper[
                                  showBalanceObj[data?.id]?.balance?.[0]
                                    ?.currencyCode
                                ]
                              }{" "}
                              {amountFormat(
                                showBalanceObj[data?.id]?.balance?.[0]
                                  ?.availableBalanceAmount
                              )}
                            </>
                          )
                        ) : (
                          amountFormat(record?.balance)
                        )
                      ) : (
                        "****".toUpperCase()
                      )}
                      {!showBalance[data.id] ? (
                        <EyeInvisibleOutlined
                          className="ml1"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (data?.account_type === "CURRENCY") {
                              setShowBalanceObj((obj) => ({
                                ...obj,
                                [data?.id]: { loading: true },
                              }));
                              onCall({
                                params: {
                                  account_id: data?.id,
                                },
                              }).then((res) => {
                                if (res?.data?.length) {
                                  setShowBalanceObj((obj) => ({
                                    ...obj,
                                    [data?.id]: {
                                      loading: false,
                                      balance: res?.data,
                                    },
                                  }));
                                }
                              });
                            }
                            setShowBalance((bal) => ({
                              ...bal,
                              [data.id]: !showBalance[data.id],
                            }));
                          }}
                        />
                      ) : (
                        <EyeOutlined
                          className="ml1"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowBalance((bal) => ({
                              ...bal,
                              [data.id]: !showBalance[data.id],
                            }));
                          }}
                        />
                      )}
                    </>
                  )}
                />
              )}
              <LazyLoading
                onPagination={() => {
                  setPage((p) => p + 1);
                  dispatch(
                    get_person_accounts({
                      params: { page_number: page + 1 },
                      person_id: "ID-1146",
                    })
                  );
                }}
                isLastPage={accounts?.length > 0 && accounts?.length < bankAccObj?.data?.total_count}
                loading={bankAccObj?.loading}
              />
            </div>
          </Col>
        </Row>
      </>
    </>
  );
};

export default PrimaryAcc;
