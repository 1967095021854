import { ReducerFunctions } from "../../utils";
import { Dashboard, Dashboard2, ErrorModal, InvoiceDashboard, PayDashboard } from "./dashboard";

export const dashboard_reducer = (state = {}, action) => {
  let data = {};
  if (action.reqPayload && action.reqPayload?.dashboard) {
    data[action.reqPayload?.dashboard] = action.payload;
    if (action.reqPayload?.dashboard === "fourth") {
      delete data[action.reqPayload?.dashboard]?.response?.dashboard?.spend_by_cost_center?.unassigned
      delete data[action.reqPayload?.dashboard]?.response?.dashboard?.spend_by_project?.unassigned
      delete data[action.reqPayload?.dashboard]?.response?.dashboard?.spend_by_user?.unassigned
    }
  } else {
    data["first"] = action.payload;
  }
  switch (action?.type) {
    case Dashboard.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Dashboard.SUCCESS: {
      return {
        ...state,
        ...data,
        loading: false,
        success: true,
      };
    }
    case Dashboard.FAILED:
      return {
        ...state,
        ...action.payload,
        loading: false,
        success: false,
      };
    case Dashboard.RESET: {
      delete state[action.payload];
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
};
export const pay_dashboard_reducer = ReducerFunctions(PayDashboard, {});
export const dashboard2_reducer = ReducerFunctions(Dashboard2, {});
export const invoice_dashboard_reducer = ReducerFunctions(InvoiceDashboard, {});


export const error_modal_reducer = (state = { open: false, errMsg: "" }, action) => {
  switch (action?.type) {
    case ErrorModal.REQUEST:
      return {
        ...state,
      };
    case ErrorModal.SUCCESS: {
      return {
        ...state,
        ...action.payload
      };
    }
    case ErrorModal.RESET: {
      return {
        open: false, errMsg: ""
      };
    }

    default:
      return state;
  }
};
