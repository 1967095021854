import { Button, message } from "antd";
import React from "react";
import { apis } from "../../../../config/APIs";
import useNotificationAxios from "../../../../config/useNotification";
import OTPComponent from "../../../Common/OTPComponent";
import { useHistory } from "react-router-dom";
import Bowser from "bowser";
import axios from "axios";
import { isValidEmail } from "../../../Auth/LoginForm";
const OTPForm = ({
  source,
  setPageCount,
  pageCount,
  setOtp,
  otp,
  userData,
  setToken,
  isEmp,
}) => {
  const history = useHistory();
  const {
    onCall: verifyOtp,
    loading: verifyLoading,
    setLoading,
  } = useNotificationAxios({
    api: apis.payment_verify_otp,
    method: "post",
  });

  const callVerfiy = (payload = {}) => {
    verifyOtp({
      data: {
        source,
        otp: otp.otp,
        otp_token: userData?.otp_token,
        application_type: "LEGAL",
        type: "LEGAL",
        ...payload,
      },
      params: {
        application_type: "LEGAL",
        type: "LEGAL",
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.error === false) {
          if (res.error === false && res?.data?.auth_token) {
            delete userData?.token;
            if (source === "LOGIN" && !isEmp) {
              localStorage.setItem(
                "company_logo",
                res?.data?.company_logo?.logo_url
              );
              localStorage.setItem("country", res?.data?.country);
              localStorage.setItem("token", res?.data?.auth_token);
              localStorage.setItem("tokenPayApi", res?.data?.auth_token);
              localStorage.setItem("name", res?.data?.name);
              localStorage.setItem("role", res?.data?.role);
              localStorage.setItem("user", res?.data?.user);
              localStorage.setItem("user_id", res?.data?.user_id);
              localStorage.setItem("tourDone", res?.data?.tour_guide);
              localStorage.setItem(
                "user_account_access_type",
                res?.data?.user_account_access_type
              );
              localStorage.setItem(
                "custom_price_plan",
                res?.data?.custom_price_plan
              );
              localStorage.setItem(
                "is_kyb_completed",
                res?.data?.is_kyb_completed
              );
              localStorage.setItem("is_accepted", res?.data?.terms_accepted);
              localStorage.setItem(
                "menu",
                JSON.stringify(res?.data?.menu ? res?.data?.menu : {})
              );
              const timeO = setTimeout(() => {
                if (
                  res?.data?.role?.includes("Applicant") &&
                  res?.data?.multi_org
                ) {
                  history.push("/organisation");
                } else {
                  window.location.href = "/dashboard";
                }
                clearTimeout(timeO);
              }, 300);
            } else {
              setLoading(false);
              localStorage.setItem("token", res?.data?.auth_token);
              localStorage.setItem("step", res?.data?.step + 1);
              localStorage.setItem("tokenPayApi", res?.data?.auth_token);
              const timeO = setTimeout(() => {
                history.push("/onboard/businesskyc", {
                  country: "IN",
                });
                clearTimeout(timeO);
              }, 300);
            }
          } else {
            setLoading(false);
            message.error(<span className="messageText">{res?.message}</span>);
          }
        }
      })
      .catch((err) => {
        message.error(
          <span className="messageText">{err?.response?.data?.message}</span>
        );
      });
  };
  return (
    <React.Fragment>
      <div
        className="pp-form-item field_length d-flex flex-col"
        style={{ alignItems: "center" }}
      >
        {pageCount == 2 && (
          <OTPComponent
            value={otp.otp}
            loading={false}
            onChange={(otp) => {
              setOtp((o) => ({
                ...o,
                otp,
              }));
            }}
            source={source}
            token={userData?.otp_token}
            otp_token={userData?.otp_token}
            username={
              isValidEmail(userData?.business_email)
                ? ""
                : userData?.business_email
            }
            email_id={
              isValidEmail(userData?.business_email)
                ? userData?.business_email
                : ""
            }
            setToken={setToken}
          />
        )}
        <div className={`pp-form-item field_length flex-center`}>
          <button
            type="button"
            className="send-btn"
            id="save"
            block={true}
            onClick={async () => {
              const payload = {};
              setLoading(true);
              if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                  async (position) => {
                    const { latitude, longitude } = position.coords;
                    try {
                      const response = await axios.get(
                        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
                      );
                      payload.login_country = response?.data?.address?.country;
                      payload.location = response?.data?.address?.city;
                      const browser = Bowser.parse(navigator?.userAgent);
                      payload.device_source = `${browser.browser.name ?? ""} ${
                        browser.browser.version ?? ""
                      } ${browser.os.name ?? ""} ${
                        browser.platform.type ?? ""
                      }`;
                      callVerfiy(payload);
                    } catch (error) {
                      console.error("Error fetching country:", error);
                      callVerfiy({});
                    }
                  },
                  (error) => {
                    console.error("Geolocation error:", error);
                    callVerfiy({});
                  }
                );
              } else {
                callVerfiy({});
              }
            }}
            disabled={verifyLoading}
          >
            Verify OTP
          </button>
        </div>
      </div>
      <div className="otp_info">
        <span>
          Check your email!{" "}
          <span style={{ fontWeight: "800" }}>{userData?.business_email}</span>{" "}
          We've sent an OTP to your linked email.
        </span>
        <div style={{ marginTop: "0.5rem" }}>
          <span className="footer-note">
            <b
              className="cursor"
              style={{ color: "#3F81F4", textDecoration: "underline" }}
              onClick={() => {
                setPageCount(1);
              }}
            >
              Change{" "}
              {isValidEmail(userData?.business_email) ? "Email ID" : "Username"}
            </b>
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OTPForm;
