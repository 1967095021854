import { Button, Result, Steps, message } from "antd";
import { sumBy } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { headerTitles } from "../../../actions/masterdata/masterdata";
import {
  SaveBulkUploadPaymentLink,
  SaveBulkUploads,
  saveBulkUploadPaymentLink,
  saveBulkUploads,
} from "../../../actions/transfers";
import { apis } from "../../../config/APIs";
import { getSymbol } from "../../../config/helper";
import usePayAxios from "../../../config/useAxios";
import useNotificationAxios from "../../../config/useNotification";
import { uploadFile } from "../../../utils";
import OTPComponent from "../../Common/OTPComponent";
import "../transfer.css";
import UploadPaymentFirst from "./UploadPaymentFirst";
import UploadPaymentsSecond from "./UploadPaymentSecond";
import close from "../../../assets/icons/close.png";
import { app_loading } from "../../../actions/auth/auth";

const { Step } = Steps;
const UploadPayments = ({ type, hasWriteAccess, from, setOpenAddNew, cb }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [formSteps, setFormSteps] = useState(0);
  const [data1, setdata] = useState([]);
  const [attachments, setAttachments] = useState({});
  const [fileErrs, setFileErrs] = useState({});
  const [titleHeader, setTitleHeader] = useState("");
  const [isFileUpload, setIsFileUpload] = useState("");
  const [policyViolations, setPolicyViolations] = useState({});
  const [otp, setOtp] = useState({
    otpSent: false,
    otp: "",
  });
  const { onCall } = useNotificationAxios({
    api: apis.gen_otp,
    method: "post",
  });
  const bankAcc = useSelector(
    (state) => state?.z_accs?.accounts?.data?.data || []
  );
  const {
    onCall: verifyOtp,
    data: VerifyData,
    loading: verifyLoading,
    reset: VerifyReset,
  } = useNotificationAxios({
    api: apis.payment_verify_otp,
    method: "post",
  });
  const { onCall: budgetCheck, loading: budgetLoading } = usePayAxios({
    api: apis.budgetCriteriaFlowCheck,
    method: "post",
  });
  const callLoading = useSelector(
    (state) =>
      state?.transfers?.save_bulk_uploads?.loading ||
      state?.transfers?.save_bulk_upload_payment_link?.loading
  );
  const uploadBulkResponse = useSelector(
    (state) => state?.transfers?.save_bulk_uploads
  );
  const uploadPaymentResponse = useSelector(
    (state) => state?.transfers?.save_bulk_upload_payment_link
  );
  const { t } = useTranslation();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      schedule_payment_date: moment(),
      batch_name: "",
      batch_description: "",
      status: "",
      file_name: "",
      debit_account_type: "",
      debit_account_id: "",
      list: [],
    },
    shouldUseNativeValidation: false,
  });

  useEffect(() => {
    if (uploadBulkResponse?.data?.error == false) {
      message.success(
        <span className="messageText">{uploadBulkResponse?.data?.message}</span>
      );
      dispatch({
        type: SaveBulkUploads.RESET,
      });
      VerifyReset();
    } else if (uploadBulkResponse?.data?.error == true) {
      message.error(
        <span className="messageText">{uploadBulkResponse?.data?.message}</span>
      );
      dispatch({
        type: SaveBulkUploads.RESET,
      });
    }
  }, [uploadBulkResponse]);
  const onSubmit = (data, event) => {
    if (event.nativeEvent?.submitter?.id === "payBtn") {
      if (!VerifyData?.data) {
        dispatch(app_loading(true));
        verifyOtp({
          data: {
            user_id: localStorage.getItem("user_id"),
            email_id: localStorage.getItem("user"),
            source: "PAYMENT",
            otp: otp.otp,
            otp_token: otp.token,
          },
        })
          .then((res) => {
            if (res.error === false) {
              if (type === "bulk_payment") {
                let mydata = JSON.parse(JSON.stringify(data));

                let lists = mydata?.list.map((ele, i) => ({
                  ...ele,
                  payment_fileName: data.file_name,
                  payment_batchName: data.batch_name,
                  description: data.batch_description,
                  purpose_code: data1?.[i]?.purpose_code,
                }));
                let payload = {
                  batch_description: data.batch_description,
                  batch_name: data.batch_name,
                  file_name: data.file_name,
                  list: lists,
                };

                if (formSteps > 0) {
                  dispatch(saveBulkUploadPaymentLink(payload));
                }
              } else {
                let mydata = JSON.parse(JSON.stringify(data));
                let mydataSplit = mydata.debit_account_id;
                const account_name = bankAcc?.find(
                  (ele) => ele.id === mydataSplit
                ).account_name;

                let lists = mydata?.list.map((ele, i) => ({
                  ...ele,
                  schedule_payment_date: moment(
                    data.schedule_payment_date
                  ).format(),
                  debit_account_id: mydataSplit,
                  description: data.batch_description,
                  debit_account_name: account_name,
                  payment_fileName: data.file_name,
                  payment_batchName: data.batch_name,
                  payment_link: "",
                  payment_attachment_uuid: "",
                  purpose_code: data1?.[i]?.purpose_code,
                }));

                let payload = {
                  schedule_payment_date: moment(
                    data.schedule_payment_date
                  ).format(),
                  batch_description: data.batch_description,
                  batch_name: data.batch_name,
                  status: "",
                  file_name: data.file_name,
                  debit_account_type: "CORPORATE",
                  debit_account_id: data?.debit_account_id,
                  file_uuid: "",
                  list: lists,
                };
                console.log({ payload });

                if (formSteps > 0) {
                  dispatch(saveBulkUploads(payload));
                }
              }
            }
          })
          .catch((err) => {
            console.log({ err });
            dispatch(app_loading(false));
            message.error(
              <span className="messageText">
                {err?.response?.data?.message}
              </span>
            );
          });
      } else {
        if (type === "bulk_payment") {
          let mydata = JSON.parse(JSON.stringify(data));

          let lists = mydata?.list.map((ele, i) => ({
            ...ele,
            payment_fileName: data.file_name,
            payment_batchName: data.batch_name,
            description: data.batch_description,
            purpose_code: data1?.[i]?.purpose_code,
          }));
          let payload = {
            batch_description: data.batch_description,
            batch_name: data.batch_name,
            file_name: data.file_name,
            list: lists,
          };

          if (formSteps > 0) {
            dispatch(saveBulkUploadPaymentLink(payload));
          }
        } else {
          let mydata = JSON.parse(JSON.stringify(data));
          let mydataSplit = mydata.debit_account_uuid.split(",");

          let lists = mydata?.list.map((ele, i) => ({
            ...ele,
            schedule_payment_date: moment(data.schedule_payment_date).format(),
            debit_account_id: mydataSplit?.[1],
            description: data.batch_description,
            debit_account_name: mydataSplit?.[2],
            payment_fileName: data.file_name,
            payment_batchName: data.batch_name,
            purpose_code: ele.purpose_code,
            payment_link: "",
            payment_attachment_uuid: "",
            purpose_code: data1?.[i]?.purpose_code,
          }));

          let payload = {
            schedule_payment_date: moment(data.schedule_payment_date).format(),
            batch_description: data.batch_description,
            batch_name: data.batch_name,
            status: "",
            file_name: data.file_name,
            debit_account_type: "CORPORATE",
            debit_account_uuid: mydataSplit?.[1],
            file_uuid: "",
            list: lists,
          };
          if (formSteps > 0) {
            dispatch(saveBulkUploads(payload));
          }
        }
      }
    } else if (event.nativeEvent?.submitter?.id === "conBtn") {
      setFormSteps(formSteps + 1);
    } else {
      budgetCheck({
        data: {
          amount: sumBy(data?.list, "payment_amount") + "",
        },
      })
        .then((res) => {
          let obj = {};
          if (res?.data?.errorPolicy) {
            Object.keys(res?.data?.errorPolicy).forEach((ele) => {
              if (res?.data?.limitUpto[ele] && res?.data?.errorPolicy[ele])
                obj[ele] = `Policy violation (${ele}): Amount for ${
                  res?.data?.errorPolicy[ele]
                }  exceeds the limit of ${getSymbol()} ${
                  res?.data?.limitUpto[ele]
                }`;
            });
          } else if (res?.error && res?.message) {
            message.error(<span className="errorMsg">{res?.message}</span>);
          }
          if (res.error === false && Object.keys(obj).length === 0) {
            onCall({
              data: {
                user_id: localStorage.getItem("user_id"),
                email_id: localStorage.getItem("user"),
                source: "PAYMENT",
              },
            }).then((res) => {
              if (res.error === false) {
                message.success(
                  <span className="messageText">{res?.message}</span>
                );
                setOtp((o) => ({ ...o, otpSent: true, token: res?.data }));
              }
              if (res.error) {
                message.error(
                  <span className="messageText">{res?.message}</span>
                );
              }
            });
          }
          setPolicyViolations(obj);
        })
        .catch((err) => {
          if (err.response?.data?.message) {
            message.error(
              <span className="messageText">
                {err?.response?.data?.message}
              </span>
            );
          }
        });
    }
  };

  useEffect(() => {
    if (type === "bulk_payment") {
      setTitleHeader("bulk_payment_links");
    } else {
      setTitleHeader("upload_bulk_payments");
    }
  }, [type]);
  useEffect(() => {
    dispatch(
      headerTitles({
        title: titleHeader,
        description: "execute_payments",
      })
    );
    // eslint-disable-next-line
  }, [titleHeader]);

  useEffect(() => {
    if (type === "bulk_payment") {
      if (uploadPaymentResponse?.data?.data) {
        const formData = new FormData();
        formData.append("attachments[]", attachments);
        formData.append("record_type", "bulk_payments_master");
        formData.append("id", uploadPaymentResponse?.data?.data);
        dispatch({
          type: SaveBulkUploadPaymentLink.RESET,
        });
        uploadFile(formData).then((res) => {
          setOtp({
            otpSent: false,
            otp: "",
          });
          dispatch(app_loading(false));
        });
        setPolicyViolations({});
        setFileErrs({});
      }
    } else {
      if (uploadBulkResponse?.data?.error === false) {
        const formData = new FormData();
        formData.append("files", attachments);
        formData.append("name", "attachments");
        formData.append("record_type", "bulk_payments_master");
        formData.append("record_id", uploadBulkResponse?.data?.data);
        dispatch({
          type: SaveBulkUploads.RESET,
        });
        uploadFile(formData).then((res) => {
          if (res.status) {
            setFormSteps(formSteps + 1);
          }
          dispatch(app_loading(false));
          setOtp({
            otpSent: false,
            otp: "",
          });
        });
        setFileErrs({});
        setPolicyViolations({});
      }
    }
    // eslint-disable-next-line
  }, [uploadBulkResponse, uploadPaymentResponse]);

  const handlerDecrement = () => {
    if (otp.otpSent) {
      setOtp({
        otpSent: false,
        otp: "",
      });
      VerifyReset({});
    } else {
      formSteps === 1 ? setFormSteps(formSteps - 1) : setValue(formSteps);
      setOtp({
        otpSent: false,
        otp: "",
      });
    }
    setPolicyViolations({});
  };
  const handlerViewDetails = () => {
    if (type === "bulk_payment") {
      history.push("/transfers/bulkpayment/history", {
        setSideMenu: true,
        type,
      });
    } else {
      setOpenAddNew(false);
      cb?.();
    }
  };

  const to_acc = watch("debit_account_id");

  const to_acnt = bankAcc?.find((ele) => ele.id === to_acc);

  const isInsufficient =
    to_acnt?.balance?.balance < sumBy(data1, "payment_amount");

  return (
    <>
      <div className="upload_payment_parent send-payment">
        <div className="flex-between sec-head">
          <div className="tabsSection">
            <span className="active">Bulk Payments</span>
          </div>
          <img
            src={close}
            width={20}
            onClick={() => {
              setOpenAddNew(false);
              cb?.();
            }}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="step_parent send-payment">
              <div className="step_div">
                <Steps
                  current={formSteps}
                  status={formSteps === 2 ? "finish" : "process"}
                >
                  <Step title={t("uploads")} />
                  <Step title={t("review")} className="step-2" />
                  <Step title={t("complete")} />
                </Steps>
              </div>
            </div>
            <div className="container">
              <UploadPaymentFirst
                setdata={setdata}
                register={register}
                errors={errors}
                control={control}
                setValue={setValue}
                setAttachments={setAttachments}
                attachments={attachments}
                type={type}
                setIsFileUpload={setIsFileUpload}
                setFileErrs={setFileErrs}
                hasWriteAccess={hasWriteAccess}
                values={getValues()}
                step={formSteps}
                watch={watch}
                from={from}
              />

              <UploadPaymentsSecond
                setdata={setdata}
                data={data1}
                type={type}
                fileErrs={fileErrs}
                step={formSteps}
                setFileErrs={setFileErrs}
                isInsufficient={isInsufficient}
              />
              {otp.otpSent && (
                <OTPComponent
                  value={otp.otp}
                  loading={verifyLoading}
                  onChange={(otp) => {
                    setOtp((o) => ({
                      ...o,
                      otp,
                    }));
                  }}
                  step={formSteps}
                  source={"PAYMENT"}
                  setToken={(token) => {
                    setOtp((o) => ({
                      ...o,
                      otp: {
                        ...o.otp,
                        token,
                      },
                    }));
                  }}
                />
              )}

              <div style={{ display: formSteps === 2 ? "block" : "none" }}>
                <Result
                  status="success"
                  title={t("success_bulk_payments")}
                  subTitle=""
                  extra={[
                    <button
                      className="send-btn"
                      type="button"
                      onClick={handlerViewDetails}
                    >
                      {t("view_details")}
                    </button>,
                  ]}
                  step={formSteps}
                />
              </div>
            </div>
          </div>
          <div className="btn_div">
            {Object.keys(policyViolations).length > 0 && (
              <div style={{ marginTop: "40px", color: "red" }}>
                {Object.keys(policyViolations).map((ele) => (
                  <p>{policyViolations[ele]}</p>
                ))}
              </div>
            )}
          </div>
          {formSteps === 2 ? null : (
            <div className="btn_div">
              {formSteps === 0 ? (
                <>
                  <button
                    className="send-btn"
                    disabled={
                      !isFileUpload || !hasWriteAccess || data1?.length === 0
                    }
                    id="conBtn"
                  >
                    {t("continue")}
                  </button>
                  <div className="errorMsg ml1">
                    {isFileUpload && data1?.length === 0
                      ? "There are no records in excel sheet."
                      : ""}
                  </div>
                </>
              ) : otp.otpSent ? (
                <button
                  className="send-btn"
                  id="payBtn"
                  disabled={
                    otp.otp.length !== 6 ||
                    verifyLoading ||
                    callLoading ||
                    uploadBulkResponse?.loading
                  }
                >
                  <span>{t("Submit")}</span>
                </button>
              ) : (
                <button
                  className="send-btn"
                  id="sendOtp"
                  disabled={
                    Object.keys(fileErrs).length > 0 ||
                    budgetLoading ||
                    isInsufficient
                  }
                >
                  {t("sendOtp")}
                </button>
              )}
              {formSteps >= 1 && (
                <button
                  className="back-btn"
                  type="button"
                  style={{ marginLeft: "2rem" }}
                  onClick={handlerDecrement}
                >
                  {t("back")}
                </button>
              )}
            </div>
          )}
        </form>
      </div>
    </>
  );
};
export default UploadPayments;
