import { Menu } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { DownSVG } from "../assets/ff_icons/side-nav/AnalyticsSVG";
import Close from "../assets/icons/close.png";
import { SubMenuItems } from "../components/LeftNavigation/LeftNavigation";
import back_arrow from "../components/Payments/SendMoney/assets/back_arrow.png";
import { LeftNavContext } from "../LeftNavProvider";

const LeftNav = ({
  items,
  activeMenu,
  setActiveSubMenu,
  setNavChildren,
  activeSubMenu,
  handleMenuItemClick,
  toggle,
  showOtherMenu,
  otherMenu,
  setChildrenMenu,
  showChildren,
  setShowChildren,
  childrenMenu,
}) => {
  const history = useHistory();
  const [menuBack, setMenuBack] = useState(false);
  const { isMobMenuOpen, setIsMobMenuOpen } = useContext(LeftNavContext);
  const mobNav = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mobNav.current && !mobNav.current.contains(event.target)) {
        setIsMobMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`mob-left-nav ${isMobMenuOpen ? "" : "hide"}`} ref={mobNav}>
      <div className="nav-head">
        <div className="d-flex align-items-center cursor gap1">
          <div className="circle">
            <span className="first-letter">
              {localStorage.getItem("name")
                ? localStorage.getItem("name")?.[0]
                : "E"}
            </span>
          </div>
          <div className="d-flex flex-col">
            <span className="user-name"> {localStorage.getItem("name")}</span>
            <span className="email"> {localStorage.getItem("user")}</span>
          </div>
          <span
            className="close cursor"
            onClick={() => {
              setIsMobMenuOpen(false);
            }}
          >
            <img src={Close} width={20} alt={"close"} />
          </span>
        </div>
      </div>
      <div className="nav-list mt1">
        {menuBack ? (
          <>
            <span
              className="back-link cursor"
              onClick={() => {
                setMenuBack(false);
              }}
              style={{ fontSize: "1.2rem", marginLeft: "1.2rem" }}
            >
              <img src={back_arrow} width={10} className="mrHalf" />
              Back
            </span>
            {otherMenu?.map(
              (itm) =>
                itm.hasAccess && (
                  <>
                    {itm.titleLabel && (
                      <h4 className="titleLabel">{itm.titleLabel}</h4>
                    )}
                    <div
                      key={itm.key}
                      className={
                        activeSubMenu === itm.key
                          ? `sub-menu-item sub-menu-selected ${
                              itm.children ? "has-children" : ""
                            }`
                          : `sub-menu-item ${
                              itm.children ? "has-children" : ""
                            }`
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        setActiveSubMenu(itm.key);
                        if (itm.children) {
                          const indexValue = itm.children.findIndex(
                            (item) => item.hasAccess
                          );
                          setChildrenMenu(itm.children?.[indexValue].key);
                          setShowChildren(true);
                          setIsMobMenuOpen(false);
                          history.push(
                            activeMenu +
                              itm.key +
                              (itm.children?.[indexValue].key ??
                                itm.altAdminKey)
                          );
                          return;
                        } else {
                          setShowChildren(false);
                        }
                        history.push(activeMenu + itm.key);
                        setIsMobMenuOpen(false);
                      }}
                    >
                      <span className="nav-label">{itm.label}</span>
                    </div>
                    {showChildren &&
                      itm.children &&
                      activeSubMenu === itm.key && (
                        <div className="sub-menu-children">
                          {itm.children?.map(
                            (ele) =>
                              ele.hasAccess && (
                                <div
                                  className={
                                    childrenMenu === ele.key
                                      ? `sub-menu-children-item sub-menu-selected`
                                      : `sub-menu-children-item`
                                  }
                                  onClick={() => {
                                    setChildrenMenu(ele.key);
                                    history.push(
                                      activeMenu + activeSubMenu + ele.key
                                    );
                                    // setIsMobMenuOpen(false);
                                  }}
                                >
                                  <span className="nav-label">{ele.label}</span>
                                </div>
                              )
                          )}
                        </div>
                      )}
                    {itm.divider && (
                      <div className="sub-menu-item-1">
                        {itm.navTitle && (
                          <div className="titleLabel">{itm.navTitle}</div>
                        )}
                        <div className="line" />
                      </div>
                    )}
                  </>
                )
            )}
          </>
        ) : (
          <Menu mode="inline" defaultSelectedKeys={["1"]}>
            {items.map(
              (menuItem) =>
                menuItem.hasAccess && (
                  <div key={menuItem.key} id={menuItem.key}>
                    <div
                      className={
                        activeMenu === menuItem.key &&
                        menuItem.children.length === 0
                          ? `menu-item menu-selected `
                          : `menu-item`
                      }
                      onClick={() => {
                        handleMenuItemClick(menuItem);
                        if (showOtherMenu[menuItem.key]) {
                          setMenuBack(true);
                        } else {
                          setIsMobMenuOpen(false);
                        }
                      }}
                    >
                      <span className="icon">{menuItem.icon}</span>
                      <div className="flex-between w80">
                        <span className="nav-label">{menuItem.label}</span>
                        {menuItem.children?.length > 0 && (
                          <span
                            className={`svg  ${
                              toggle[menuItem.key] ? "" : "rotate"
                            }`}
                          >
                            <DownSVG fill={"#fff"} />
                          </span>
                        )}
                      </div>
                    </div>
                    {menuItem.divider && (
                      <div className="sub-menu-item-1">
                        <div className="line" />
                      </div>
                    )}
                    {menuItem.children?.length > 0 ? (
                      <div
                        className={`submenu-wrap ${
                          toggle[menuItem.key] ? "open" : ""
                        }`}
                      >
                        <SubMenuItems
                          activeMenu={menuItem.key}
                          activeSubMenu={activeSubMenu}
                          setActiveSubMenu={setActiveSubMenu}
                          items={items}
                          setNavChildren={setNavChildren}
                        />
                      </div>
                    ) : null}
                    <br />
                  </div>
                )
            )}
          </Menu>
        )}
      </div>
    </div>
  );
};

export default LeftNav;
