import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteConstants, objectToFormData } from "../../utils";
import Input from "../FormComponents/InputComponent";
import LeftArrow from "../../assets/icons/arrow-left.svg";
import PasswordIcon from "../../assets/icons/password.svg";
import { Link } from "react-router-dom";
import { Button, Typography, message } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ForgotPassword, forgotPassword } from "./../../actions/auth";
import { APP_BASE_URL } from "./../../config/Axios";
import { useTranslation } from "react-i18next";
const { Text, Title } = Typography;

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

const resetPasswordSchema = Yup.object().shape({
  email: Yup.string().required("Username or Email is required."),
});

message.config({
  maxCount: 3,
});

const ResetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [emailSent, setEmailSent] = React.useState(false);
  const forgotPasswordResponse = useSelector(
    (state) => state.auth.forgotPassword || {}
  );

  React.useEffect(() => {
    setEmailSent(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const { data: { message: res_message = "", error = "" } = {} } =
      forgotPasswordResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      setEmailSent(true);
      dispatch({
        type: ForgotPassword.RESET,
      });
    } else if (error) {
      res_message &&
        message.error(<span className="messageText">{res_message}</span>);
      setEmailSent(false);
      dispatch({
        type: ForgotPassword.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forgotPasswordResponse]);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      if (!isValidEmail(values.email)) {
        values.username = values.email;
        delete values.email;
      }
      dispatch(forgotPassword({ ...values, redirect_url: APP_BASE_URL }));
    },
  });
  return (
    <div className="login-form">
      <div className="head-sec rtl-text-right">
        <Title level={3} className="mt2">
          {t("reset_ur_password")}
        </Title>
        {emailSent ? (
          <>
            <Text className="text-dim">
              {t("check_inbox")} <span className="text-white">{""}</span>
            </Text>
            <br />
            <br />
            <Text className="text-dim">{t("check_spam")}</Text>
          </>
        ) : (
          <Text className="text-dim">{t("email_link")}</Text>
        )}
      </div>
      {emailSent ? null : (
        <form
          onSubmit={formik.handleSubmit}
          className="rtl-text-right login-form"
        >
          <Input
            label={
              <>
                Username or Email <strong className="errorMsg">*</strong>
              </>
            }
            placeholder={t("email_add")}
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            errMsg={formik.touched.email && formik.errors.email}
            className="ant-input"
          />
          <div className="mt2">
            <button
              className="send-btn"
              disabled={forgotPasswordResponse?.loading}
            >
              {t("reset_ur_password")}
            </button>
          </div>
        </form>
      )}
      <div className="mt2 rtl-text-right">
        <Link to={RouteConstants.ROOT}>
          <span className="text-dim cursor">
            <img
              src={LeftArrow}
              alt={"LeftArrow"}
              className="leftArrow mr1"
              width={10}
            />
            {t("back_to_login")}
          </span>
        </Link>
      </div>
    </div>
  );
};

export default ResetPassword;
