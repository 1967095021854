import { yupResolver } from "@hookform/resolvers/yup";
import { Input, Typography, message } from "antd";
import jwt_decode from "jwt-decode";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import * as yup from "yup";
import { RouteConstants } from "../../utils";
import OTPComponent from "../Common/OTPComponent";
const { Text, Title } = Typography;

message.config({
  maxCount: 3,
});

const ValidationSchema = yup.object().shape({
  otp: yup.string().min(6).max(6).required("Please provide valid email."),
});

const TwoStepVerify = () => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
  } = useForm({
    defaultValues: {
      otp: "",
    },
    resolver: yupResolver(ValidationSchema),
  });
  const [otp, setOtp] = useState("");
  const loginResponse = useSelector((state) => state.auth.login || {});
  const history = useHistory();

  const onSubmit = (values) => {
    const payload = {};

    ["otp"].forEach((item) => {
      payload[item] = values[item];
    });

    ValidationSchema.validate(payload)
      .then(function ({ ...value }) {
        try {
          history.push(RouteConstants.DASHBOARD);  
        } catch (error) {
          console.error(error);
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  return (
    <div className="login-form">
      <div className="head-sec">
        <Title level={3}>{t("two_step")}</Title>
        <Text>{t("enter_otp")}</Text>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <OTPComponent
          value={otp}
          onChange={(value) => {
            setOtp(value);
            setValue("otp", value);
            trigger("otp");
          }}
        />
        <Input
          {...register("otp")}
          placeholder={t("enter_otp")}
          type="hidden"
        />
        {errors.otp?.message && (
          <div className="errorMsg">{errors.otp.message}</div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button type="submit" className="send-btn mt2">
            {loginResponse?.loading ? (
              <ClipLoader
                loading={loginResponse?.loading}
                size={20}
                color="#fff"
              />
            ) : (
              t("verify_otp")
            )}
          </button>
        </div>
      </form>
      {/* <div className="mt4" style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <span className="text-dim footer-note">
          {t("have_an_account")}
          <b className="cursor" onClick={() => {
            history.push("/")
          }}>{t("login")}</b>
        </span>
      </div> */}
    </div>
  );
};

export default TwoStepVerify;
