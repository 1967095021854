import { Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { customers } from "../../../actions/customers";
import {
  get_currencies,
  headerTitles,
} from "../../../actions/masterdata/masterdata";
import { SUBMIT_PAYMENT } from "../../../actions/payments";
import { get_purposes } from "../../../actions/z_accounts";
import close from "../../../assets/icons/close.png";
import AdditionalInfo from "./AdditionalInfo";
import PaymentConfirmation from "./PaymentReview";
import Recipients from "./Recipients";
import SchedulePaymentForm from "./SendPay";
const { Step } = Steps;

const SendMoney = ({ setOpenAddNew, cb, from }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [dbAcc, setDBAcc] = useState({});
  const [crAcc, setCrAcc] = useState({});
  const [mainData, setMainData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const userReportSubmitResponse = useSelector(
    (state) => state.payments.submit_payment || {}
  );
  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };
  React.useEffect(() => {
    if (userReportSubmitResponse?.data?.error === false) {
      dispatch({
        type: SUBMIT_PAYMENT.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReportSubmitResponse]);
  const steps = [
    {
      title: "Recipients",
      content: (
        <Recipients
          next={next}
          setDBAcc={setCrAcc}
          acc={crAcc}
          mainData={mainData}
          onSubmit={(data) => {
            setMainData((b) => ({
              ...b,
              ...data,
            }));
          }}
        />
      ),
    },
    {
      title: "Amount & source",
      content: (
        <SchedulePaymentForm
          next={next}
          back={prev}
          setDBAcc={setDBAcc}
          acc={dbAcc}
          crAcc={crAcc}
          mainData={mainData}
          from={from}
          onSubmit={(data) => {
            setMainData((b) => ({
              ...b,
              ...data,
            }));
          }}
        />
      ),
    },
    {
      title: "Additional info",
      content: (
        <AdditionalInfo
          next={next}
          back={prev}
          dbAcc={dbAcc}
          crAcc={crAcc}
          mainData={mainData}
          setMainData={setMainData}
        />
      ),
    },
    // {
    //   title: "Review",
    //   content: (
    //     <Review
    //       dbAcc={dbAcc}
    //       crAcc={crAcc}
    //       mainData={mainData}
    //       setMainData={setMainData}
    //       toStart={() => {
    //         setCurrentStep(0);
    //         setCrAcc({});
    //         setDBAcc({});
    //         setMainData({});
    //         cb?.();
    //       }}
    //     />
    //   ),
    // },
  ];

  useEffect(() => {
    dispatch(
      headerTitles({
        title: "Send Money",
        description: "Payments Transfer to Recipients Accounts",
      })
    );
    dispatch(
      customers({
        type: "individual",
        page_number: 1,
        params: {
          pagination: false,
        },
      })
    );
    dispatch(
      get_currencies({
        all: true,
      })
    );
    dispatch(get_purposes());
  }, []);

  return (
    <div className="upload_payment_parent send-payment">
      <div className="flex-between sec-head">
        <div className="tabsSection">
          <span className="active">Send Money</span>
        </div>
        <img
          src={close}
          width={20}
          onClick={() => {
            if (from) {
              history.goBack();
            } else {
              setOpenAddNew(false);
              cb?.();
            }
          }}
        />
      </div>
      <div className="p2">
        {mainData?.paid ? (
          <PaymentConfirmation
            paid={true}
            dbAcc={dbAcc}
            crAcc={crAcc}
            mainData={mainData}
            setMainData={setMainData}
            toStart={() => {
              setCurrentStep(0);
              setCrAcc({});
              setDBAcc({});
              setMainData({});
            }}
          />
        ) : (
          <>
            <Steps current={currentStep}>
              {steps.map((item, index) => (
                <Step key={index} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content" style={{ marginTop: 20 }}>
              {steps[currentStep].content}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SendMoney;
