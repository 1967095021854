import { DatePicker, Input, Modal, Select } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const CompanyDetailsModal = ({ company, formik, hasWriteAccess }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const updateCompanyProfileResponse = useSelector(
    (state) => state.companySetting.updateCompanyProfile || {}
  );

  React.useEffect(() => {
    const { data: { error = "" } = {} } = updateCompanyProfileResponse;
    if (error === false) {
      setIsModalVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCompanyProfileResponse]);

  return (
    <>
      <Modal
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        title="Edit Company Details"
        className="right-alinged-modal"
        getContainer={false}
      >
        <div className="pp-form-item">
          <label className="required">First Name</label>
          <Input
            className="pp-form-input"
            placeholder="Enter First Name"
            value={formik?.values?.first_name}
            onChange={formik.handleChange}
            name="first_name"
          />
        </div>
        <div className="pp-form-item">
          <label>Middle Name</label>
          <Input
            className="pp-form-input"
            placeholder="Enter Middle Name"
            value={formik?.values?.middle_name}
            onChange={formik.handleChange}
            name="middle_name"
          />
        </div>
        <div className="pp-form-item">
          <label className="required">Last Name</label>
          <Input
            className="pp-form-input"
            placeholder="Enter Last Name"
            value={formik?.values?.last_name}
            onChange={formik.handleChange}
            name="last_name"
          />
        </div>

        <div className="pp-form-item">
          <label className="required">Email</label>
          <Input
            className="pp-form-input"
            placeholder="Enter Email"
            value={formik?.values?.email}
            onChange={formik.handleChange}
            name="email"
            disabled
          />
        </div>
        <div className="pp-form-item">
          <label className="required">Phone Number</label>
          <Input
            className="pp-form-input"
            placeholder="Enter Phone Number"
            value={formik?.values?.primary_phone}
            onChange={formik.handleChange}
            name="primary_phone"
            disabled
          />
        </div>
        <div className="pp-form-item">
          <label className="required">Gender</label>
          <div className="pp-dropdown">
            <Select
              className="pp-dropdown pp-dropdown-inline"
              placeholder="Select"
              style={{ width: "100%" }}
              value={formik?.values?.gender}
              onChange={(val) => formik.setFieldValue("gender", val)}
              name="gender"
              options={[
                {
                  label: "Male",
                  value: "Male",
                },
                {
                  label: "Female",
                  value: "Female",
                },
                {
                  label: "Others",
                  value: "Others",
                },
              ]}
            />
          </div>
        </div>
        <div className="pp-form-item">
          <label className="required">DOB</label>
          <div className="pp-dropdown">
            <DatePicker
              className="pp-form-input"
              placeholder="Enter DOB"
              format={["DD/MM/YYYY"]}
              style={{ width: "100%" }}
              value={formik?.values?.dob}
              onChange={(e) => {
                formik.setFieldValue("dob", e);
              }}
              name="dob"
            />
          </div>
        </div>
        <div className="action-btns mt2">
          <button className="send-btn">Update Details</button>
          <button
            className="back-btn"
            type="button"
            onClick={() => {
              setIsModalVisible(false);
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <div className="flex-between mb1">
        <h3>Your Details</h3>
        <button
          onClick={() => {
            setIsModalVisible(true);
          }}
          type="button"
          disabled={!hasWriteAccess}
        >
          Edit
        </button>
      </div>
      <div className="company-details">
        <div className="item">
          <label>Company Name</label>
          <span>{company?.companyDetails?.name}</span>
        </div>
        <div className="item">
          <label>First Name</label>
          <span>
            {company?.profile?.first_name ? company?.profile?.first_name : "-"}
          </span>
        </div>
        <div className="item">
          <label>Middle Name</label>
          <span>
            {" "}
            {company?.profile?.middle_name
              ? company?.profile?.middle_name
              : "-"}
          </span>
        </div>
        <div className="item">
          <label>Last Name</label>
          <span>
            {company?.profile?.last_name ? company?.profile?.last_name : "-"}
          </span>
        </div>
        <div className="item">
          <label>DOB</label>
          <span>{moment(company?.profile?.dob).format("DD/MM/YYYY")}</span>
        </div>
        <div className="item">
          <label>Gender</label>
          <span>{company?.profile?.gender}</span>
        </div>
        <div className="item">
          <label>Phone Number</label>
          <span>{company?.profile?.primary_phone}</span>
        </div>
        <div className="item">
          <label>Email</label>
          <span>{company?.profile?.email}</span>
        </div>
      </div>
    </>
  );
};

export default CompanyDetailsModal;
