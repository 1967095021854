import { Divider, Input, message, Modal, Switch, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { ChangePassword, changePassword, logout } from "../../actions/auth";
import { headerTitles } from "../../actions/masterdata/masterdata";
import { apis } from "../../config/APIs";
import usePayAxios from "../../config/useAxios";
import OTPComponent from "../Common/OTPComponent";
import PasswordCheck from "../Common/PasswordCheck";
import SecurityTables from "./AlertsNSecurityComps/SecurityTables";
const { Text, Title } = Typography;
const ValidationSchema = yup.object().shape({
  old_password: yup
    .string()
    .trim()
    .required("Current Password is required")
    .min(8),
  new_password: yup
    .string()
    .trim()
    .required("Password is required")
    .min(8)
    .notOneOf(
      [yup.ref("old_password"), null],
      "Current Password and Password must not match"
    ),
  password_confirmation: yup
    .string()
    .trim()
    .required("Password Confirmation is required")
    .min(8)
    .oneOf([yup.ref("new_password"), null], "Passwords must match"),
});
const Security = () => {
  const [otp, setOtp] = useState({
    otpSent: false,
    otp: "",
  });
  const { onCall: verifyOtp, loading: verifyLoading } = usePayAxios({
    api: apis.payment_verify_otp,
    method: "post",
  });
  const { onCall: gen_otp, loading: genLoading } = usePayAxios({
    api: apis.gen_otp,
    method: "post",
  });
  const [passwordCheck, setPasswordCheck] = React.useState(true);
  const { t } = useTranslation();

  const {
    handleSubmit,
    reset: resetForm,
    formState: { errors },
    register,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      old_password: "",
      new_password: "",
      password_confirmation: "",
    },
  });
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const changePasswordResponse = useSelector(
    (state) => state.auth.changePassword
  );

  const dispatch = useDispatch();
  const {
    onCall,
    data: auth,
    reset,
  } = usePayAxios({
    api: apis.enabletwoFactorAuth,
    method: "post",
  });

  const onSubmit = (vals, event) => {
    if (event.nativeEvent?.submitter?.id === "sendOtp") {
      gen_otp({
        data: {
          user_id: localStorage.getItem("user_id"),
          email_id: localStorage.getItem("user"),
          source: "CHANGE_PASSWORD",
          oldPassword: vals.old_password,
          newPassword: vals.new_password,
          confirmPassword: vals.password_confirmation,
        },
      })
        .then((res) => {
          if (res.error === false) {
            message.success(
              <span className="messageText">{res?.message}</span>
            );
            setOtp({
              ...otp,
              otpSent: true,
              token: res?.data,
            });
          }
          if (res.error) {
            message.error(<span className="messageText">{res?.message}</span>);
          }
        })
        .catch((err) => {
          message.error(
            <span className="messageText">{err?.response?.data?.message}</span>
          );
        });
    } else {
      verifyOtp({
        data: {
          user_id: localStorage.getItem("user_id"),
          email_id: localStorage.getItem("user"),
          source: "CHANGE_PASSWORD",
          otp: otp.otp,
          otp_token: otp.token,
        },
      })
        .then((res) => {
          if (res.error === false) {
            dispatch(changePassword(vals));
          }
        })
        .catch((err) => {
          message.error(
            <span className="messageText">{err?.response?.data?.message}</span>
          );
        });
    }
  };

  React.useEffect(() => {
    const { data: { message: res_message = "", error = undefined } = {} } =
      changePasswordResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch({
        type: ChangePassword.RESET,
      });
      resetForm();
      dispatch(logout());
    } else if (error) {
      res_message &&
        message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: ChangePassword.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePasswordResponse]);

  useEffect(() => {
    dispatch(
      headerTitles({
        title: "Security",
        description: "Configure security",
      })
    );
    onCall({});
  }, []);

  const handleCancel = () => {
    setIsModalVisible(false);
    setOtp({
      otpSent: false,
      otp: "",
    });
    resetForm();
  };
  const newPassword = watch("new_password");
  const renderChangePassword = () => {
    return (
      <div className="psd_content">
        {otp?.otpSent ? null : (
          <>
            <Title level={3} className="mt1">
              {t("set_ur_pwd")}
            </Title>
            <Text>{t("please_create_new_pwd")}</Text>
            <br />
          </>
        )}
        <form className="change_password" onSubmit={handleSubmit(onSubmit)}>
          {otp?.otpSent ? (
            <div className="flex-center flex-col w100 mb3">
              <div className="otp_info" style={{ maxWidth: "80%" }}>
                <div>
                  <b
                    className="mt-2 mb-1"
                    style={{
                      fontSize: "1.6rem",
                    }}
                  >
                    Enter the OTP
                  </b>
                </div>
                <span>
                  Check your email!{" "}
                  <span style={{ fontWeight: "800" }}>
                    {localStorage.getItem("user")}
                  </span>{" "}
                  We've sent an OTP to your linked email.
                </span>
              </div>
              <OTPComponent
                source={"CHANGE_PASSWORD"}
                label={"Enter the OTP"}
                hideTitle={true}
                value={otp.otp}
                userData={otp}
                token={otp.token}
                setUserData={setOtp}
                loading={changePasswordResponse?.loading || verifyLoading}
                onChange={(otp) => {
                  setOtp((o) => ({
                    ...o,
                    otp,
                  }));
                }}
                setToken={(token) => {
                  setOtp((o) => ({
                    ...o,
                    token,
                  }));
                }}
              />
              <button
                className="send-btn mt2"
                id="otp"
                disabled={changePasswordResponse?.loading}
              >
                Verify OTP
              </button>
            </div>
          ) : (
            <>
              <div className="item">
                <label>{t("current_pwd")}</label>
                <Input.Password
                  placeholder={t("enter_current_pwd")}
                  {...register("old_password", {
                    required: t("enter_current_pwd"),
                  })}
                  onChange={(e) => {
                    setValue("old_password", e.target.value.trim());
                  }}
                />
                {errors?.old_password?.message && (
                  <p className="error">{errors?.old_password?.message}</p>
                )}
              </div>

              <div className="item">
                <label>{t("new_pwd")}</label>
                <Input.Password
                  placeholder={t("enter_new_pwd")}
                  {...register("new_password", {
                    required: t("enter_new_pwd"),
                    minLength: {
                      value: 8,
                      message: t("password_min_length_error"),
                    },
                  })}
                  onChange={(e) => {
                    setValue("new_password", e.target.value.trim());
                  }}
                />
                <PasswordCheck
                  password={newPassword}
                  setPasswordCheck={setPasswordCheck}
                />
                {errors?.new_password?.message && (
                  <p className="error">{errors?.new_password?.message}</p>
                )}
              </div>

              <div className="item mb1">
                <label>{t("confirm_new_pwd")}</label>
                <Input.Password
                  placeholder={t("enter_confirm_new_pwd")}
                  {...register("password_confirmation", {
                    required: t("enter_confirm_new_pwd"),
                    validate: (value) =>
                      value === newPassword || t("Passwords does not match."),
                  })}
                  onChange={(e) => {
                    setValue("password_confirmation", e.target.value.trim());
                  }}
                />
                {errors?.password_confirmation?.message && (
                  <p className="error">
                    {errors?.password_confirmation?.message}
                  </p>
                )}
              </div>

              <button
                id="sendOtp"
                className="send-btn mt1"
                disabled={
                  passwordCheck || changePasswordResponse?.loading || genLoading
                }
              >
                Change Password
              </button>
              <button
                type="button"
                className="back-btn ml1 mt1"
                onClick={() => handleCancel()}
              >
                {t("cancel")}
              </button>
            </>
          )}
        </form>
      </div>
    );
  };
  return (
    <>
      {isModalVisible && (
        <Modal
          title="Change Password"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[]}
          className="center-aligned-modal"
        >
          {renderChangePassword()}
        </Modal>
      )}
      <div className="upload_payment_parent alerts">
        <div className="sec-head">
          <h2>Security</h2>
          <p className="alert-info">
            Stay updated with critical security alerts related to your account,
            such as unauthorized access attempts and password changes.
          </p>
          {/* <img src={close} width={20} className="close" /> */}
        </div>
        <Divider />
        <div className="p2 security">
          <div className="alert-row flex-between">
            <div>
              <label>Password</label>
              <span className="sub">
                Set a unique password to protect your account.
              </span>
            </div>
            <button
              className="btn"
              onClick={() => {
                setIsModalVisible(true);
              }}
            >
              Change Password
            </button>
          </div>
          <div className="alert-row flex-between">
            <div>
              <label>Two-factor authentication</label>
              <span className="sub">
                Two-factor authentication (2FA) keeps accounts secure by adding
                an extra layer of protection beyond a password. By default, we
                require you to set up a 2FA app that can generate 2FA codes, but
                you can add a security key to log in even quicker.
              </span>
            </div>
            <div>
              <Switch
                checked={auth?.data?.["2FA"]}
                onChange={(checked) => {
                  onCall({
                    params: {
                      is_update: true,
                    },
                    data: {
                      is_enable_two_fa: checked,
                      two_fa_method: {
                        email: checked,
                      },
                    },
                  });
                }}
              >
                Set 2FA
              </Switch>
            </div>
          </div>
          {/* <Table columns={columns} dataSource={data} pagination={false} /> */}
          <br />
          <br />
          <SecurityTables />
        </div>
      </div>
    </>
  );
};

export default Security;
