import { Button, Form, Input, Typography, message } from "antd";
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import * as yup from "yup";
import { resetPassword } from "../../actions/auth";
import PasswordCheck from "../Common/PasswordCheck";

const { Text, Title } = Typography;

message.config({
  maxCount: 3,
});

const ValidationSchema = yup.object().shape({
  password: yup.string().required("Password is required"),
  password_confirmation: yup
    .string()
    .trim()
    .required("Password Confirmation is required")
    .min(8)
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [token, setToken] = React.useState("");
  const [password, setPassword] = React.useState("");
  const resetPasswordResponse = useSelector(
    (state) => state.auth.resetPassword || {}
  );
  // const forgotPasswordResponse = useSelector(state => state.auth.forgotPassword || {});
  const { t } = useTranslation();
  const [passwordCheck, setPasswordCheck] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: (vals) => {
      vals.reset_password_token = token;
      dispatch(resetPassword({ ...vals }));
    },
  });

  React.useEffect(() => {
    const url = window.location.search;
    const searchParams = new URLSearchParams(url);
    setToken(searchParams.get("token"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const { data: { message: res_message = "", error = "" } = {} } =
      resetPasswordResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      setPassword("");
      history.push("/");
    } else {
      res_message &&
        message.error(<span className="messageText">{res_message}</span>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPasswordResponse]);

  return (
    <div className="reset-form">
      <div className="head-sec">
        <Title level={3} className="mt2">
          {t("set_pwd")}
        </Title>
        <Text>{t("create_new")}</Text>
      </div>
      <Form onFinish={formik.handleSubmit} layout="vertical">
        <Form.Item label="New Password" placeholder="Enter New Password">
          <Input.Password
            placeholder="Enter password"
            name="password"
            onChange={formik.handleChange}
          />
          <PasswordCheck
            password={formik.values.password}
            setPasswordCheck={setPasswordCheck}
          />
          <div className="errorMsg">
            {formik.touched.password && formik.errors.password}
          </div>
        </Form.Item>
        <Form.Item
          label="Confirm New Password"
          placeholder="Enter Confirm New Password"
        >
          <Input.Password
            placeholder="Enter password"
            name="password_confirmation"
            onChange={formik.handleChange}
          />
          <div className="errorMsg">
            {formik.touched.password_confirmation &&
              formik.errors.password_confirmation}
          </div>
        </Form.Item>

        <Button
          htmlType="submit"
          className="primaryButton mt2"
          disabled={
            passwordCheck ||
            resetPasswordResponse?.loading ||
            formik.values.password?.length < 8
          }
        >
          {resetPasswordResponse?.loading ? (
            <ClipLoader
              loading={resetPasswordResponse?.loading}
              size={20}
              color="#ffffff"
            />
          ) : (
            t("set_pwd")
          )}
        </Button>
      </Form>
      <div className="mt4 flex-center">
        <span className="text-dim footer-note">
          {t("unable_to_change")} <b>{t("support")}</b>
        </span>
      </div>
    </div>
  );
};

export default ResetPassword;
