export const gridApis = {
	authentication: "/v1/grid/0/token/authentication",
	getAllAccounts: "/api/admin/accounts/fetchAllAccounts",
	getPersonLinkedAccounts: "/api/admin/accounts/getPersonLinkedAccounts",
	getByID: "/api/admin/accounts/fetchAccountDetailsByID",
	masterAccountTransactions: "/api/admin/accounts/fetchAccountTransactions",
	fetchStatements: "/api/admin/accounts/fetchTransactions",
	initialisePayment: "/api/admin/payments/initialisePayment",
	createPaymentRequest:
		"/api/corporate/payments/createPaymentRequest?module_type=COMPANY_PAYMENTS",
	deletePaymentRequest: "/api/corporate/payments/deletePaymentRequest",
	getAllPayments:
		"/api/corporate/payments/getAllPayments?module_type=COMPANY_PAYMENTS",
	confirmPayment: "/api/admin/payments/confirmPayment",
	cancelPayment: "/api/admin/payments/cancelPayment",
	getPaymentPurposes: "/api/admin/payments/getPaymentPurposes",
	fetchAccountTransactions: "/api/admin/accounts/fetchAccountTransactions",
	onBoardUser: "/api/admin/onBoard/onBoardLegalClient",
	fetchAccountTypes: "/api/admin/accounts/fetchAccountTypes",
	createAccount: "/api/admin/accounts/createAccount",
	userDetails: "/api/admin/onBoard/userDetails",
	createPaymentLink: "/api/admin/links/createPaymentLink",
	fetchAllPaymentLinks: "/api/admin/links/fetchAllPaymentLinks",
	fetchStatementsPDF: "/api/admin/accounts/fetchStatement",
	fetchExcelTransactions: "/api/admin/accounts/fetchExcelTransactions",
	logoutDeviceById: "/api/admin/users/logoutDeviceById",
	getLoginHistory: "/api/admin/users/getLoginHistory",
	getAllUserDevices: "/api/admin/users/getAllUserDevices",
	createDispute: "/api/corporate/disputes/createDispute",
	updateDispute: "/api/corporate/disputes/updateDispute",
	getAllDisputes: "/api/corporate/disputes/getAllDisputes",
	getDisputeReasonList: "/api/corporate/disputes/getDisputeReasonList",
	getBalanceAlerts: "/api/admin/alerts/getBalanceAlerts",
	saveBalanceAlerts: "/api/admin/alerts/saveBalanceAlerts",
	getUserAlerts: "/api/admin/alerts/getUserAlerts",
	updateUserAlert: "/api/admin/alerts/updateUserAlert",
	paymentReceipt: "/api/notifications/receipts/paymentReceipt",
	getbeneficiaryByID: "/api/corporate/beneficiaries/getbeneficiaryByID",
	fetchTransactionAttachments:
		"/api/admin/accounts/fetchTransactionAttachments",
	fetchPaymentLink: "/api/admin/links/fetchPaymentLink",
	generateReceiptPdf: "/api/corporate/pdf/generateReceiptPdf",
	shareTransactionReceipt:
		"/api/notifications/receipts/shareTransactionReceipt",
	getRecipientDocTypes: "/api/corporate/beneficiaries/getRecipientDocTypes",
	accountIDByPrice: "/api/admin/zenusAccounts/accountIDByPrice",
	accountBalanceByID: "/api/admin/zenusAccounts/accountBalanceByID",
	deleteBeneficiary: "/api/corporate/beneficiaries/deleteBeneficiary",
	fetchSubAccountTransactions:
		"/api/admin/accounts/fetchSubAccountTransactions",
	fetchAllSubAccounts: "/api/admin/accounts/fetchAllSubAccounts",
};
