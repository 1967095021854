import React, { useContext } from "react";
import ham from "./assets/ham.png";
import { LOGO } from "../config/Images";
import { LeftNavContext } from "../LeftNavProvider";

const MobHeader = () => {
  const { setIsMobMenuOpen } = useContext(LeftNavContext);
  return (
    <div className="mobile-header">
      <img
        src={ham}
        className="ham"
        onClick={() => {
          setIsMobMenuOpen(true);
        }}
      />
      <img src={LOGO} className="mob-logo" />
    </div>
  );
};

export default MobHeader;
