import { message, Modal } from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import PaymentReview from "../../../mobile_components/PaymentReview";
import { amountFormat } from "../../../utils";
import pay_success from "./assets/pay_success.png";
import EmailInput from "./EmailInput";
import { LeftNavContext } from "../../../LeftNavProvider";

export const downloadFileDirectly = async (baseurl, cb) => {
  try {
    const response = await fetch(baseurl, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"), // Add necessary headers
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch file: ${response.statusText}`);
    }

    // Convert response to a blob
    const blob = await response.blob();

    // Extract filename from Content-Disposition header
    const contentDisposition = response.headers.get("Content-Disposition");
    const filename = contentDisposition
      ? contentDisposition.split("filename=")[1].replace(/"/g, "")
      : "download.pdf";

    // Create a downloadable link
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    cb?.();
  } catch (error) {
    cb?.();
    message.error(<span className="messageText">Error downloading file</span>);
  }
};

const PaymentConfirmation = ({ crAcc, dbAcc, mainData, toStart }) => {
  const history = useHistory();
  const [emailModal, setEmailModal] = useState(false);
  const { width } = useContext(LeftNavContext);

  return (
    <div className="payment-review-container">
      <Modal
        visible={emailModal}
        title="Send Payment Receipt"
        footer={[]}
        onCancel={() => {
          setEmailModal(false);
        }}
      >
        {emailModal && (
          <EmailInput
            mainData={mainData}
            name={crAcc?.beneficiary_name}
            address={`${crAcc?.billing_addresses?.[0]?.address}
              ${crAcc?.billing_addresses?.[0]?.city}
              ${crAcc?.billing_addresses?.[0]?.state},
              ${crAcc?.billing_addresses?.[0]?.country},
              ${crAcc?.billing_addresses?.[0]?.zipcode}`}
            bank_location={crAcc?.billing_addresses?.[0]?.country}
            bank_name={crAcc?.bank_accounts?.[0]?.bank_name}
            routing_code={
              crAcc?.bank_accounts?.[0]?.routing_code ||
              crAcc?.bank_accounts?.[0]?.ifsc_code ||
              crAcc?.bank_accounts?.[0]?.swift_code ||
              ""
            }
            account_number={crAcc?.bank_accounts?.[0]?.account_number}
            cb={() => {
              setEmailModal(false);
            }}
          />
        )}
      </Modal>
      <div className="payment-review-header">
        <img src={pay_success} alt="Success Icon" className="success-icon" />
        <h1>Payment Initiated Successfully</h1>
        <p className="payment-info">Amount</p>
        <p className="payment-amount">
          ${" "}
          {amountFormat(
            Number(mainData?.amount) + Number(mainData?.transaction_account_fee)
          )}
        </p>
        <p className="payment-info">
          Date of Payment: {moment().format("HH:mm DD MMM, YYYY")} |<br /> Payer
          Name: {dbAcc?.account_name} | Account Number: {dbAcc?.account_number}
        </p>
      </div>
      <h2>Payment Details</h2>
      {width < 900 && (
        <PaymentReview mainData={mainData} dbAcc={dbAcc} crAcc={crAcc} />
      )}
      <div className="payment-review-details mob">
        <div className="details-grid">
          <div>
            <p>Payment Identifier</p>
            <p>{mainData?.order_id ?? mainData?.endToEndId}</p>
          </div>
          <div>
            <p>Payer Name</p>
            <p>{dbAcc?.account_name}</p>
          </div>
          <div>
            <p>Payer Account Number</p>
            <p>{dbAcc?.account_number}</p>
          </div>
          <div>
            <p>Financial Institution ID</p>
            <p>{dbAcc?.routing_code ?? dbAcc?.swift_code}</p>
          </div>
          <div>
            <p>Account Number Type</p>
            <p>{dbAcc?.account_number_type}</p>
          </div>{" "}
          <div>
            <p>BIC Code</p>
            <p>{dbAcc?.bic_code}</p>
          </div>
        </div>
        <div className="details-grid">
          <div>
            <p>Date of Payment</p>
            <p>{moment().format("HH:mm Do MMM, YYYY")}</p>
          </div>
          <div>
            <p>Counterparty Name</p>
            <p>{crAcc?.beneficiary_name}</p>
          </div>

          <div>
            <p>Counterparty Account Number</p>
            <p>{crAcc?.bank_accounts?.[0]?.account_number}</p>
          </div>
          <div>
            <p>Financial Institution ID</p>
            <p>
              {crAcc?.bank_accounts?.[0]?.routing_code ??
                crAcc?.bank_accounts?.[0]?.swift_code}
            </p>
          </div>

          <div>
            <p>Address</p>
            <p>
              {crAcc?.billing_addresses?.[0]?.address}
              <br />
              {crAcc?.billing_addresses?.[0]?.city}
              <br />
              {crAcc?.billing_addresses?.[0]?.state},{" "}
              {crAcc?.billing_addresses?.[0]?.country},{" "}
              {crAcc?.billing_addresses?.[0]?.zipcode}
            </p>
          </div>
        </div>
      </div>

      <div className="actions mt3">
        <button
          className="back-btn mr2"
          onClick={() => {
            toStart();
          }}
        >
          Make Another Payment
        </button>
        <button
          className="back-btn mr2"
          onClick={() => {
            history.push("/transactions");
          }}
        >
          View Transactions
        </button>
      </div>
    </div>
  );
};

export default PaymentConfirmation;
