export const apis = {
  // login: `/api/auth/login`,
  // logout: `/api/auth/logout`,
  corporateLogin: `/api/admin/employee/corporateLogin?device_type=web`,
  login: `/api/admin/employee/corporateLogin?device_type=web`,
  logout: `/api/admin/employee/logout?device_type=web`,
  gen_otp: "/api/notifications/generateOtp",
  resend_otp: "/api/notifications/resendOtp",
  payment_verify_otp: "/api/notifications/verifyOtp?device_type=web",
  forgotPassword: `/api/admin/employee/forgotPassword`,
  resetPassword: `/api/admin/employee/resetPassword`,
  changePassword: `/api/admin/users/changePassword`,
  notifications: "/api/notifications/getAllNotifications",
  update_notification: "/api/notifications/updateNotifications",
  dashboard: `/api/corporate/dashboard/getAllAdminDashboardDetails`,
  spendAnz: `/api/admin/dashboard/spendAnalyzer`,
  company_currencies: "/api/company_currencies",
  inquiry: "/v1/admin/0/demo/requestDemo",
  enableCardLimit: "/api/admin/cards/enable_card_limit",
  enableTxnType: "/api/admin/cards/enable_transaction_type",
  cardPolicies: "/api/corporate/custom/getPolicyMetaDetails",
  cardLimitSet: "/api/corporate/cardPolicy/createCardPolicy",
  updatecardLimitSet: "/api/corporate/cardPolicy/updateCardPolicies",
  cardPoliciesNLimits: "/api/corporate/cardPolicy/getAllCardPolicies", // get
  cardLimit: "/api/admin/cards/list_card_limits", // get   // /list_card_limits { card_id as param }
  qucikLoad: "/api/admin/cards/quick_transfer_topup",
  show_card_policy: "/api/corporate/cardPolicy/getCardPolicyByID",
  advanceShow: "/v1/expense/0/expense/getAdvancesDetailsById",
  reports: `/v1/expense/0/expense/getAllReports`,
  reportsReject: `/v1/expense/0/expense/rejectReport`,
  reportsApprove: `/v1/expense/0/expense/approveReport`,
  reportsApproveBulk: `/api/admin/reports/bulk_approve`,
  reportsRejectBulk: `/api/admin/reports/bulk_reject`,
  reportsReimburse: `/v1/expense/0/expense/reimburse?module_type=REPORT`,
  reportsComments: `/api/admin/reports/comments`,
  reportsHistory: `/api/admin/reports/history`,
  reportsAdvance: `/api/admin/advances/list`,
  reportsExportExcel: `/api/admin/reports/export?format=csv`,
  reportDetailsPDF: "/api/admin/reports/export_report_details",
  showReport: "/v1/expense/0/expense/getReportDetailsByID",
  shareReport: "/api/reports/share_report",
  userReports: `/v1/expense/0/expense/createReport?module_type=REPORT`,
  userReportSubmit: `/v1/expense/0/expense/submitReport?module_type=REPORT`,
  userReportRecall: `/v1/expense/0/expense/recallReport`,
  userReportFields: `/api/settings/customization/getAllCustomFields`,
  userReportLinkExp: `/v1/expense/0/expense/linkTransaction?module_type=REPORT`,
  userReportLinkAdv: `/v1/expense/0/expense/linkAdvance?module_type=REPORT`,
  userReportsComments: `/api/corporate/company/createComment`,
  userReportsHistory: `/api/reports/history`,
  userReportsAdvance: `/api/advances`,
  userShowReport: "/v1/expense/0/expense/getReportDetailsByID",
  userReportsExportExcel: `/api/reports/export?format=csv`,

  trips: `/v1/expense/0/expense/getAllTrips`,
  userTrips: `/v1/expense/0/expense/createTrip?module_type=TRIP`,
  userTripRecall: `/v1/expense/0/expense/recallTrip`,
  userTripCancel: `/v1/expense/0/expense/cancleTrip`,
  userTripsSubmit: `/v1/expense/0/expense/submitTrip?module_type=TRIP`,
  userTripFields: `/api/settings/customization/getAllCustomFields`,

  tripsReject: `/v1/expense/0/expense/rejectTrip?module_type=TRIP`,
  tripsApprove: `/v1/expense/0/expense/approveTrip?module_type=TRIP`,
  userTripLinkReport: `/v1/expense/0/expense/linkTrip?module_type=REPORT`,
  tripsComments: `/api/corporate/company/createComment`,
  tripsHistory: `/api/admin/trips/history`,
  tripsShow: `/v1/expense/0/expense/getTripDetailsByID`,
  tripClose: `/v1/expense/0/expense/markAsClose`,
  download_trip_attachments: "/api/admin/trips/download_trip_attachments",
  attach_trip_file: "/api/admin/trips/attach_trip_file",
  remind_card_activation: "/api/admin/cards/remind_card_activation",
  expenseComments: "/api/corporate/company/createComment",
  userTripsComments: `/api/corporate/company/createComment`,
  userTripsHistory: `/api/trips/history`,

  advances: `/v1/expense/0/expense/getAllAdvances`,
  advancesReject: `/v1/expense/0/expense/rejectAdvance?module_type=ADVANCE`,
  advancesApprove: `/v1/expense/0/expense/approveAdvance?module_type=ADVANCE`,
  advancesComments: `/api/corporate/company/createComment`,
  advancesHistory: `/api/admin/advances/history`,
  advancesExportExcel: `/api/admin/advances/export?format=csv`,

  userAdvancesCreate: `/v1/expense/0/expense/createAdvance?module_type=ADVANCE`,
  userAdvances: `/v1/expense/0/expense/getAllAdvances?module_type=ADVANCE`,
  updateUserAdvances: `/v1/expense/0/expense/updateAdvancesDetails`,
  userAdvancesSubmit: `/v1/expense/0/expense/submitAdvance?module_type=ADVANCE`,
  userAdvancesRecall: `/v1/expense/0/expense/recallAdvance?module_type=ADVANCE`,
  userAdvancesComments: `/api/corporate/company/createComment`,
  userAdvancesHistory: `/api/advances/history`,
  unlinkAdvance: `/v1/expense/0/expense/unlinkAdvance`,

  userCards: `/api/cards`,
  blockReasons: `/api/cards/block_reason`,
  cards: `/api/admin/cards/list`,
  cardsDetails: `/api/admin/cards/details`,
  cardTransactions: `/api/admin/transactions/list`,
  topup: `/api/admin/cards/topup`,
  block: `/api/admin/cards/block`,

  categories: `/api/settings/categories/getAllCategories`,
  createCategory: `/api/settings/categories/createCategories`,
  categoriesStatus: `/api/settings/categories/updateStatus`,
  deleteCategories: `/api/settings/categories/deleteCategory`,
  updateCategory: `/api/settings/categories/updateCategory`,

  employees: `/api/settings/users/getAllEmployees`,
  virtual_employees: `/api/settings/users/getAllVirtualUsers`,
  createVirtualUser: "/api/settings/users/createVirtualUser",
  createEmployees: `/api/settings/users/createEmployee`,
  updateEmployees: `/api/settings/users/updateEmployee`,
  employeebyID: `/api/settings/users/getEmployeeByID`,
  getCustomFields: `/api/settings/customization/getUserCustomFields`,
  deleteEmployee: `/api/settings/users/deleteUser`,

  companySetting: `/api/settings/customization/getCompanySettings`,
  addBranch: `/api/admin/companies`,
  companyCountries: `/api/settings/country/getAllCountries`,
  getAllTimeZones: "/api/settings/timeZones/getAllTimeZones",
  companyStates: `/api/settings/country/getStateDetailsByID`,
  companyCities: `/api/settings/country/getCityDetailsByID`,

  reportApprovals: `/api/settings/customization/hierarchy/getHierarchy`,
  updateReportApprovals: `/api/settings/customization/hierarchy/saveHierarchy`,

  advanceApprovals: `/api/settings/customization/hierarchy/getHierarchy`,
  updateAdvanceApprovals: `/api/settings/customization/hierarchy/saveHierarchy?`,

  tripApprovals: `/api/settings/customization/hierarchy/getHierarchy`,
  updateTripApprovals: `/api/settings/customization/hierarchy/saveHierarchy`,
  invoiceApprovals: `/api/settings/customization/hierarchy/getHierarchy`,
  updateInvoiceApprovals: `/api/settings/customization/hierarchy/saveHierarchy`,
  filterInvoice: "/api/corporate/customer/getAllInvoices",

  billApprovals: `/api/settings/customization/hierarchy/getHierarchy`,
  updatebillApprovals: `/api/settings/customization/hierarchy/saveHierarchy`,
  // billApprovals: `/api/settings/customization/hierarchy/getHierarchy?module_type=BILLING_APPROVAL`,
  // updatebillApprovals: `/api/settings/customization/hierarchy/saveHierarchy?module_type=BILLING_APPROVAL`,

  updateTransaction: `/api/settings/customization/updateCompanySettings`,
  transactionField: `/api/settings/customization/getAllCustomFields?module_type=EXPENSE`,
  singleTransactionField: `/api/corporate/customization/getCustomFieldByID?module_type=EXPENSE`,
  createTransaction: `/api/settings/customization/createCustomField?module_type=EXPENSE`,
  deleteTransactionField: `/api/settings/customization/deleteCustomField?module_type=EXPENSE`,
  updateTransactionField: `/api/settings/customization/updateCustomField?module_type=EXPENSE`,

  updateReport: `/api/settings/customization/updateCompanySettings`,
  reportField: `/api/settings/customization/getAllCustomFields?module_type=REPORT`,
  createReportField: `/api/settings/customization/createCustomField?module_type=REPORT`,
  deleteReportField: `/api/settings/customization/deleteCustomField?module_type=REPORT`,
  updateReportField: `/api/settings/customization/updateCustomField?module_type=REPORT`,
  getReportTransactionField: `api/admin/transactions/list/`,
  getReportTransactionComments: `/api/corporate/company/createComment`,

  updateCompAdvances: `/api/admin/company_settings/advances`,
  advancesField: `/api/settings/customization/getAllCustomFields`,
  createAdvances: `/api/settings/customization/createCustomField`,
  deleteAdvancesField: `/api/settings/customization/deleteCustomField`,
  updateAdvancesField: `/api/settings/customization/updateCustomField`,

  updateTrips: `/api/settings/customization/updateCompanySettings`,
  tripsField: `/api/settings/customization/getAllCustomFields`,
  createTripsField: `/api/settings/customization/createCustomField`,
  deleteTripsField: `/api/settings/customization/deleteCustomField`,
  updateTripsField: `/api/settings/customization/updateCustomField`,
  unlinkTrips: `/v1/expense/0/expense/unlinkTrip`,
  createNewCostCenter: `/api/settings/customization/createCostCenter`,
  costCenterField: `/api/settings/customization/getAllCostCenters`,
  deleteCostCenterField: `/api/settings/customization/deleteCostCenter`,
  updateCostCenterField: `/api/settings/customization/updateCostCenters`,
  enableDisableCostCenter: `/api/settings/customization/costCenters/updateStatus`,
  getMerchantsField: `/api/settings/customization/getAllMerchants`,
  merchantsField: `/api/settings/customization/createMerchant`,
  deleteMerchantsField: `/api/settings/customization/deleteMerchant`,
  updateMerchantsField: `/api/settings/customization/updateMerchant`,
  enabledisableMarchent: `/api/settings/customization/merchants/updateStatus`,
  updateTaxesField: "/api/settings/customization/updateTax",
  updateTdsField: "/api/settings/customization/updateTds",
  updateItemsField: "/api/settings/customization/updateItems",
  deleteItemsField: "/api/settings/customization/deleteItem",
  deleteTaxesField: "/api/settings/customization/deleteTax",
  deleteTdsField: "/api/settings/customization/deleteTds",

  projectsField: `/api/settings/customization/getAllProjects`,
  addNewProjectField: `/api/settings/customization/createProject`,
  deleteProjectsField: `/api/settings/customization/deleteProject`,
  updateProjectsField: `/api/settings/customization/updateProject`,
  updateStatusProjectField: `/api/settings/customization/projects/updateStatus`,

  companyProfile: `/api/settings/users/userProfileDetails`,
  companyProfileImages: `/api/documents/attachments`,
  updateCompanyProfile: `/api/settings/users/updateUserProfile`,
  updateCompanyProfileImages: `/api/documents/upload`,

  rolesPermissions: `/api/settings/roles/getRolesPermissions`,
  roles: `/api/settings/roles/createRolePermissions`,
  getRoles: `/api/settings/roles/getAllRoles`,
  getSingleRoles: `/api/settings/roles/getRolesPermissions`,
  updateRoles: `/api/settings/roles/updateRolesPermissions`,

  getUserFields: `/api/settings/customization/getAllCustomFields?module_type=USER`,
  // getUserFieldById:`/api/corporate/customization/getCustomFieldByID?module_type=USER`,
  addUserFields: `/api/settings/customization/createCustomField?module_type=USER`,
  updateUserFields: `/api/settings/customization/updateCustomField?module_type=USER`,
  getSingleUserFields: `/api/admin/employee_fields/show`,
  deleteUserFields: `/api/settings/customization/deleteCustomField?module_type=USER`,
  userFieldsColumns: `/api/admin/employee_fields/column_types`,

  transactions: `/api/admin/transactions/list`,
  transactionsLinkReport: `/api/admin/transactions/link_report`,
  updateTransactions: `/api/admin/transactions/update`,
  deleteTransactions: `/v1/expense/0/expense/deleteExpense?module_type=EXPENSE`,
  getTransactionsFields: `/api/admin/transactions/transaction_fields`,
  transactionsReject: `/v1/expense/0/expense/rejectExpense`,
  transactionsUnlink: `/v1/expense/0/expense/unlinkTransaction`,
  transactionsHistory: `/api/admin/transactions/history`,
  transactionsPartialApprove: `/api/admin/transactions/partial_approve`,
  forwardApproval: `/v1/expense/0/expense/reportForwardApproval`,
  changeReport: `/v1/expense/0/expense/changeReport`,
  // advances: `/api/admin/advances/list`,
  getSingleAdvances: `/api/admin/advances/show`,
  updateAdvances: `/api/admin/advances/update`,
  deleteAdvances: `/api/admin/advances/delete`,
  getAdvancesFields: `/api/admin/advances/advance_fields`,

  userAdvanceFields: `/api/settings/customization/getAllCustomFields`,
  userAdvanceDelete: `/v1/expense/0/expense/deleteAdvance?module_type=ADVANCE`,

  designations: `/api/settings/users/getAllDesignations`,
  getSingleDesignations: `/api/settings/users/getDesignationByID`,
  createDesignations: `/api/settings/users/createDesignation`,
  updateDesignations: `/api/settings/users/updateDesignation`,
  deleteDesignations: `/api/settings/users/deleteDesignation`,
  deleteRole: "/api/settings/roles/deleteRolePermissions",
  userTransactions: `/v1/expense/0/expense/getAllExpenses`,
  addTransactions: `/v1/expense/0/expense/createExpense?module_type=EXPENSE`,
  expenseDetails: `/v1/expense/0/expense/getExpenseDetailsById`,
  customFieldDetails: `/api/settings/customization/getAllFieldValues`,
  userUpdateTransactions: `/v1/expense/0/expense/updateExpenseDetails`,
  userTransactionsFields: "/api/settings/customization/getAllCustomFields",
  userPrefixTransactionField: `/v1/expense/0/utility/createPrefix`,
  getUserTransactionComments: `/api/corporate/company/createComment`,
  userTransactionsHistory: `/api/transactions/history`,
  report_counts: "/v1/expense/0/dashboard/getAllEmployeeDashboardDetails",
  downloadBulk: "/api/admin/cards/bulk_topup_fields_template",
  uploadBulk: "/api/admin/cards/import_bulk_topup",
  replacement: "/api/admin/cards/replacement",
  // corporate_budget: '/api/admin/cards/corporate_budget',
  corporate_budget: "/api/corporate/trueLayer/pg/checkBalance",
  tranferlog: "/api/admin/cards/import_topup_file_status",
  userFields: "/api/settings/users/getUserUploadFields",
  importUserTemplate: "/api/settings/users/generateExcel",
  uploadUsers: "/api/settings/users/uploadUser",
  analytics_cards: "/api/admin/transactions/transaction_report",
  analytics_reports: "/v1/analytics/0/reportsAnalytics/getReportsAnalytics",
  txn_card_export: "/api/admin/transactions/export_pdf",
  txn_report_export: "/api/admin/reports/export_pdf_csv",
  analytics_advances_export: "/api/admin/advances/advance_export_csv_pdf",
  analytics_advances: "/v1/analytics/0/advancesAnalytics/getAdvancesAnalytics",
  analytics_expenses: "/v1/analytics/0/expensesAnalytics/getExpensesAnalytics",
  analytics_trips: "/v1/analytics/0/tripsAnalytics/getTripsAnalytics",
  analytics_trips_export: "/api/admin/trips/export_analytics_trip",
  analytics_expenses_export:
    "/api/admin/transactions/export_analytics_expenses",
  export_advance_details: "/api/admin/advances/export_advance_details",
  export_reports: "/api/admin/reports/export_all_reports",
  export_trip_details: "/api/admin/trips/export_trip_details",
  update_trip: "/v1/expense/0/expense/updateTripDetails",
  create_expense_policy: "/api/corporate/policyAndLimits/createExpensePolicies",
  list_expense_policies: "/api/corporate/policyAndLimits/getAllExpensePolicies",
  edit_expense_policy: "/api/corporate/policyAndLimits/updateExpensePolicies",
  delete_expense_policy: "/api/corporate/policyAndLimits/deleteExpensePolicies",
  show_expense_policy: "/api/corporate/policyAndLimits/getExpensePolicyByID",
  fetch_expense_policy_data: "/api/corporate/custom/getPolicyMetaDetails",
  accountTxns: "/api/admin/cards/budget_credit_transaction",

  updateUserReport: "/v1/expense/0/expense/updateReportDetails",
  cardPolicyDelete: "/api/corporate/cardPolicy/deleteCardPolicy",
  setCardLimit: "/api/admin/cards/set_card_limit",
  tripsDelete: `/v1/expense/0/expense/deleteTrip?module_type=TRIP`,
  set_pin: "/api/cards/set_pin",
  send_otp: "/api/cards/send_otp",
  verify_otp: "/api/cards/verify_otp",
  activate: "api/cards/activate",
  update_mobile_number: "/api/cards/update_mobile_number",
  currencies: `/api/admin/base_currencies`,
  //  Pay APIs
  vendorsAll: `/api/corporate/beneficiaries/getAllBeneficiaries`,
  placeOfSypplies: `/api/corporate/beneficiaries/getSeedValuesByType`,
  connectToBank: `/api/corporate/pg/connectBankAccont`,
  getAccounts: `/api/corporate/pg/getAccounts`,
  getAllCompanyBankDetailsById: `/api/corporate/company/getAllCompanyBankDetails`,
  getSeedRecords: `/api/corporate/beneficiaries/getSeedRecords`,
  confirm_email: "/api/user/confirm_email",
  validate_expense: "/v1/expense/0/expense/validateExpensePolicies",
  bulk_expenses: "/api/transactions/create_bulk_transactions",

  tdsType: "/api/corporate/vendor/getVendorTdsTypes",
  paymentTerms: "/api/corporate/vendor/getVendorPaymentTerms",
  createBeneficiary: "/api/corporate/beneficiaries/createBeneficiary",
  getAllBeneficiaries: "/api/corporate/beneficiaries/getAllBeneficiaries",
  vendorsByName: "/api/corporate/beneficiaries/getBeneficiaryByName",
  createInvoice: "/api/corporate/customer/createCustomerInvoice",
  updateInvoice: "/api/corporate/customer/updateCustomerInvoice",
  getBeneficiary: "/api/corporate/beneficiaries/getbeneficiaryByID",
  submitInvoice: "/api/corporate/customer/submitCustomerInvoice",
  customerInvoices: "/api/corporate/customer/getAllCustomerInvoices",
  vendorsById: "/api/corporate/beneficiaries/getbeneficiaryByID",

  // Vendor TDS
  fetchVendorTDS: "/api/corporate/vendor/getVendorTdsTypes",

  // Payment Terms
  fetchPaymentTerms: "/api/corporate/vendor/getVendorPaymentTerms",

  // Save Beneficiary
  saveBeneficiary: "/api/corporate/beneficiaries/createBeneficiary",
  updateBeneficiary: "/api/corporate/beneficiaries/updateBeneficiary",

  // Save Place Supply
  savePlaceSupplies: "/api/corporate/beneficiaries/createSeedRecord",

  // Bills
  createBillingDetail: "/api/corporate/vendor/createBillingDetails",
  fetchBillingDetailById: "/api/corporate/vendor/getBillingDetailsById",
  submitBillingDetail: "/api/corporate/vendor/submitBillingDetails",
  approveBillingDetails: "/api/corporate/vendor/approveBillingDetails",
  rejectBillingDetails: "/api/corporate/vendor/rejectBillingDetails",
  createBillingComments: "/api/corporate/vendor/createBillingComment",
  createPaymentReceivedComments: "/api/corporate/company/createComment",
  customerPortalCommentes: "/api/corporate/portal/customer/createComment",
  vendorPortalCommentes: "/api/corporate/portal/vendor/createComment",
  updateBillingDetail: "/api/corporate/vendor/updateBillingDetails",
  recallBillingDetail: "/api/corporate/vendor/recallBill",
  filtersBillingDetails: "/api/corporate/vendor/getAllBills",

  // Bills Attachment
  updateBillAttachment: "/api/corporate/vendor/updateBillingDetails",
  createReceipt: "/api/corporate/receipts/createReceipt",
  createInvoiceReceipt: "/api/corporate/receipts/createInvoiceReceipt",
  getReceipts:
    "/api/corporate/receipts/getReceiptsByCompanyID?module_type=BILL",
  getCustomerInvoiceByID: "/api/corporate/customer/getCustomerInvoiceByID",
  approveInvoice: "/api/corporate/customer/approveCustomerInvoice",
  rejectInvoice: "/api/corporate/customer/rejectCustomerInvoice",
  recallInvoice: "/api/corporate/customer/recallInvoice",
  AddPayTerms: "/api/corporate/vendor/createVendorPaymentTerms",
  AddCategory: "/api/corporate/beneficiaries/createSeedRecord",
  AddTax: "/api/corporate/vendor/createVendorTdsTypes",
  CreatePayment: "/api/corporate/receipts/createManualReceipt",
  downloadPDF: "/api/corporate/vendor/generatePDF",
  getReceiptByID: "/api/corporate/receipts/getReceiptByID",

  // payment made api
  paymentMade:
    "/api/corporate/receipts/getReceiptsByCompanyID?module_type=BILL",
  filterPayment: "/api/corporate/receipts/getAllReceiptsByCompany",
  dashboardBills: "/api/corporate/vendor/getTotalBillStats",
  dashboardInvoices:
    "/api/corporate/customer/getTotalInvoiceStats?module_type=INVOICE",
  getItems: "/api/corporate/items/getItems",
  createItem: "/api/corporate/items/createItem",
  createTaxItem: "/api/corporate/tax/createCompanyTaxType",
  companyTaxTypes: "/api/settings/customization/getAllTaxes",

  // CreateInvoiceReceipt
  CreateInvoiceReceipt: "/api/corporate/receipts/createManuaInvoiceReceipt",

  getPaymentsReceived:
    "/api/corporate/receipts/getInvoiceReceiptsByCompanyID?module_type=INVOICE",
  getInvoiceReceiptByID: "/api/corporate/receipts/getInvoiceReceiptByID",
  generatePDFBill: "/api/admin/bills/bill_pdf_export",
  generatePDFInv: "/api/admin/invoices/invoice_pdf_export",
  generatePDFCN: "/api/admin/credit_notes/credit_note_pdf_export",
  getPaymentsReceivedFilters:
    "/api/corporate/receipts/getAllInvoiceReceiptsByCompany",

  // setting approval apis
  salesOrderApproval: "/api/settings/customization/hierarchy/getHierarchy",
  estimationApproval: "/api/settings/customization/hierarchy/getHierarchy",
  purchaseApproval: "/api/settings/customization/hierarchy/getHierarchy",
  bulkPayApproval: "/api/settings/customization/hierarchy/getHierarchy",
  paymentApproval: "/api/settings/customization/hierarchy/getHierarchy",

  salesOrderApprovalSave:
    "/api/settings/customization/hierarchy/saveHierarchy?module_type=SALES_ORDER_APPROVAL",
  estimationApprovalSave:
    "/api/settings/customization/hierarchy/saveHierarchy?module_type=ESTIMATE_APPROVAL",
  purchaseApprovalSave:
    "/api/settings/customization/hierarchy/saveHierarchy?module_type=PURCHASE_ORDER_APPROVAL",
  paymentApprovalSave:
    "/api/settings/customization/hierarchy/saveHierarchy?module_type=PAYMENT_APPROVAL",
  bulkPayApprovalSave:
    "/api/settings/customization/hierarchy/saveHierarchy?module_type=BULK_PAYMENT_APPROVAL",

  // Profile upload apis
  uploadProfile: "/api/upload/attachment",
  showProfile: "/api/attachment/show",
  sendEmail: "/api/corporate/sendEmail",
  deleteProfile: "https://documentapi.forgocards.com/api/documents/attachments",

  profileUpload: "/api/documents/upload",
  profileUpdate: "/api/documents/attachments",
  profileDetele: "/api/documents/attachments",

  // payment link
  paymentLink: "/api/corporate/airpay/pg/generatePaymentLink",
  initiatePaymentRequest: "/api/corporate/pg/initiatePaymentRequest",

  // tax, tds, items links
  getPaymentItems: "/api/settings/customization/getItems",
  createTDSTypes: "/api/settings/customization/createTds",
  createTaxTypes: "/api/settings/customization/createTax",
  createPaymentItems: "/api/settings/customization/createItem",

  getTDSTypes: "/api/settings/customization/getAllTds",
  leadConvert: "/api/corporate/leadsConverting/convert",

  //doumentApi

  // Analytics apis
  getAnalyticsEstimates: "/v1/analytics/0/purchases/getPurchaseData",
  getAnalyticsInvoices: "/v1/analytics/0/sales/getSalesData",
  hierarchyOptions: "/api/corporate/hierarchy/getOptions",
  hierarchyUpdateOptions: "/api/corporate/hierarchy/updateOptions",
  getAnalyticsPaymentReceived: "/v1/analytics/0/sales/getPaymentData",
  getAnalyticsPaymentMade: "/v1/analytics/0/purchases/getPaymentData",
  createPrefixCustomer: "/api/corporate/customer/createPrefix",
  createPrefixVendor: "/api/corporate/vendor/createPrefix",
  // fetchAnalyticsInvoices : "/api/admin/invoices/analytics_invoices"

  // Analytics apis pdf and csv
  pdfAnalyticsEstimates:
    "/api/admin/vendor_billing_details/export_analytics_pdf_csv",
  pdfAnalyticsInvoices: "/api/admin/invoices/export_analytics_pdf_csv",
  pdfAnalyticsPaymentReceived:
    "/api/admin/invoice_receipts/export_analytics_pdf_csv",
  pdfAnalyticsPaymentMade: "/api/admin/bill_receipts/export_analytics_pdf_csv",

  // BeneficiaryBankDetails
  getAllBeneficiariesBankDetails:
    "/api/corporate/beneficiaries/getAllBeneficiaryBankDetails",

  // get Payment link api
  getPaymentLink: "/api/admin/links/fetchAllPaymentLinks",
  fetchPaymentLink: "/api/admin/links/fetchPaymentLink",
  deletePaymentLink: "/api/corporate/airpay/pg/deletePaymentLink",
  saveBulkUploads:
    "/api/corporate/bulk/saveBulkUpload?module_type=COMPANY_BULK_PAYMENTS",
  fetchBulkUploads: "/api/corporate/bulk/getCompanyPaymentInfo",
  downloadBulkUploads: "/api/corporate/bulk/getPaymentInfo",
  saveBulkUploadPaymentLink: "/api/corporate/bulk/saveBulkUploadPaymentLink",
  fetchBulkUploadPaymentLink: "/api/corporate/bulk/getCompanyPaymentInfoLinks",
  bulkApprovePayment:
    "/api/corporate/bulk/bulkApprovePayment?module_type=COMPANY_BULK_PAYMENTS",
  bulkRejectPayment:
    "/api/corporate/bulk/bulkRejectPayment?module_type=COMPANY_BULK_PAYMENTS",
  // OCR Apis
  ocrSave: "/v1/ocr/0/ocrApplications/save",
  ocrDocuments: "/v1/ocr/0/ocrApplications/documents",
  ocrDocumentsDelete: "/v1/ocr/0/ocrApplications",

  // delete api integration
  deleteInvoiceAPI: "/api/corporate/customer/deleteInvoice",
  deleteBillsAPI: "/api/corporate/vendor/deleteBill",
  getIfscDetails:
    "https://payapi.forgocards.com/api/corporate/bank/getIfscDetails",
  // fields APIS
  createFieldsAPI: "/api/settings/customization/createCustomField",
  getFieldsAPI: "/api/settings/customization/getAllCustomFields",
  customFieldValues: "/api/settings/customization/getAllFieldValues",
  deleteFieldsAPI: "/api/settings/customization/deleteCustomField",
  updateFieldsAPI: "/api/settings/customization/updateCustomField",
  updateReminderAPI: "/api/settings/customization/updateCompanySettings",
  getReminderAPI: "/api/admin/reminders",
  createReminderAPI: "/api/admin/reminders",
  editReminderAPI: "/api/admin/reminders/update",
  deleteReminderAPI: "/api/admin/reminders/delete",

  // credit notes
  creditDebitNotes: "/api/corporate/transactions/getAllCreditDebitNotes",
  createCreditDebitNotes: "/api/corporate/transactions/createCreditDebitNote",
  applyCreditDebitNote: "/api/corporate/transactions/applyCreditDebitNote",
  submitCreditDebitNote: "/api/documents/upload",
  updateCreditDebitNote: "/api/corporate/transactions/updateCreditDebitNote",
  getCreditDebitNoteByID: "/api/corporate/transactions/getCreditDebitNoteByID",
  getUserCredits: "/api/corporate/transactions/getUserCredits",

  // Bulk Approval Apis for Invoice and Bills
  bulkInvoiceAPI: "/api/corporate/customer/approveCustomerInvoiceBulk",
  bulkBillAPI: "/api/corporate/vendor/approveBillingDetailsBulk",

  // bulk reject apis for invoice and bills
  bulkRejectInvoiceAPI: "/api/corporate/customer/rejectCustomerInvoiceBulk",
  bulkRejectBillAPI: "/api/corporate/vendor/rejectBillingDetailsBulk",

  // batch apis
  report_pdf_from_batch: "/api/admin/reports/report_pdf_from_batch",
  trip_pdf_from_batch: "/api/admin/trips/trip_pdf_from_batch",
  expenses_pdf_from_batch: "/api/admin/transactions/expenses_pdf_from_batch",
  invSeriesCheck: "/api/corporate/customer/duplicateSeriesCheck",
  billSeriesCheck: "/api/corporate/vendor/duplicateSeriesCheck",

  // multiful currency apis
  getCompanyCurrencyAPIs: "/api/settings/curriencies/getAllBaseCurriencies",
  getAllCurrencyAPIs: "/api/settings/curriencies/getAllCurrencies",
  getAllCountriesAPIs: "/api/settings/kyc/getKycCountries",
  createCompanyCurrencyAPIs: "/api/settings/curriencies/createBaseCurrency",
  deleteCompanyCurrencyAPIs: "/api/settings/curriencies/deleteBaseCurrency",
  updateCompanyCurrencyAPIs: "/api/settings/curriencies/updateBaseCurrency",
  toggleExchangeRateAPIs: "/api/settings/curriencies/updateAutoExchange",
  getAllExchangeRatesAPIs: "/api/settings/curriencies/getAllExchangeRates",
  createExchangeRateAPIs: "/api/settings/curriencies/createExchangeRate",
  updateExchangeRateAPIs: "/api/settings/curriencies/updateExchangeRate",
  deleteExchangeRateAPIs: "/api/settings/curriencies/deleteExchangeRate",
  companyDetailsAPIs: "/api/settings/customization/getCompanyDetails",
  emailActionAPI: "/api/corporate/action",
  getCompanyBaseCurrency: "/api/settings/curriencies/getCompanyBaseCurrency",

  // policies apis
  createPolicies: "/api/corporate/custom/createPolicies",
  updatePolicies: "/api/corporate/custom/updatePolicies",
  getPolicyByID: "/api/corporate/custom/getPolicyByID",
  getPolicies: "/api/corporate/custom/getPolicies",
  enablePolicy: "/api/corporate/custom/enablePolicy",
  changePolicyPriority: "/api/corporate/custom/changePolicyPriority",
  deletePolicy: "/api/corporate/custom/deletePolicy",
  getPolicyMetaDetails: "/api/corporate/custom/getPolicyMetaDetails",

  // account apis
  masterPoolAccount: "/api/corporate/budget/getMasterAccountDetails",
  getAllBudgets: "/api/corporate/budget/getAllBudgets",
  deleteBudget: "/api/corporate/budget/deleteBudget",
  createBudgets: "/api/corporate/budget/createBudget",
  transferBudgetBack: "/api/corporate/budget/transferBudget",
  getMasterAccountHistory: "/api/corporate/budget/getMasterAccountHistory",
  // Payouts
  initiatePayout: "/api/corporate/trueLayer/pg/initiatePayout",
  payoutTxns: "/api/corporate/trueLayer/pg/getTransactions",
  // budget policies
  createPolicyBudget: "/api/corporate/budgetPolicy/createPolicyBudget",
  updatePolicyBudget: "/api/corporate/budgetPolicy/updatePolicyBudget",
  getPolicyBudgetByID: "/api/corporate/budgetPolicy/getPolicyBudgetByID",
  deletePolicyBudget: "/api/corporate/budgetPolicy/deletePolicyBudget",
  enablePolicyBudget: "/api/corporate/budgetPolicy/enablePolicyBudget",
  getAllPolicyBudgets: "/api/corporate/budgetPolicy/getAllPolicyBudgets",
  getPolicyMetaBudgetDetails:
    "/api/corporate/budgetPolicy/getPolicyMetaBudgetDetails",
  budgetCriteriaFlowCheck: "/api/corporate/budgetPolicy/criteriaFlowCheck",

  // templates apis
  getAllTemplates: "/api/corporate/template/fetch",
  getUpdateTemplates: "/api/corporate/template/update",
  getDefaultTemplate: "/api/corporate/template/default",
  getOldTemplate: "/api/corporate/template/fetch",

  // onboard apis
  onboardPostAPI: "/v1/admin/0/onBoard/onBoardClient",

  // Documentation
  createDocument: "/api/documents/upload",
  getDocument: "/api/documents/attachments",
  deleteDocument: "/api/documents/attachments",
  updateDocument: "/api/documents/attachments",

  // multi branch
  createMultiBranch: "/api/settings/branches/createBranch",
  updateMultiBranch: "/api/settings/branches/updateBranch",
  deleteMultiBranch: "/api/settings/branches/deleteBranch",
  getAllBrach: "/api/settings/branches/getOrganisationList",
  getAllBrachList: "/api/settings/branches/getAllBranchDetails",
  getBrachID: "/api/settings/branches/getAllBranchDetailsByID",
  switchBrach: "/api/settings/branches/switchBranch",
  switchBrachRuby: "/api/admin/generate_token",
  getCardVenders: "/api/settings/cardVendor/getAllCardVendors",

  // Payments Approvals
  getAllPayments: "/api/corporate/payments/getAllPayments",
  getPaymentDetailsByID: "/api/corporate/payments/getPaymentDetailsByID",
  createPaymentRequest: "/api/corporate/payments/createPaymentRequest",
  submitPayment: "/api/corporate/payments/submitPayment",
  rejectPayment: "/api/corporate/payments/rejectPayment",
  approvePayment: "/api/corporate/payments/approvePayment",
  recallPayment: "/api/corporate/payments/recallPayment",
  editPayment: "/api/corporate/payments/editPayment",
  updatePayment: "/api/corporate/payments/updatePayment",
  deletePayment: "/api/corporate/payments/deletePayment",
  getBulkPaymentListByID: "/api/corporate/bulk/getBulkPaymentListByID",
  submitBulkPayment: "/api/corporate/bulk/submitPayment",
  rejectBulkPayment: "/api/corporate/bulk/rejectPayment",
  approveBulkPayment: "/api/corporate/bulk/approvePayment",
  recallBulkPayment: "/api/corporate/bulk/recallPayment",
  // bank details fetch
  getIfscDetails1:
    "https://payapi.forgocards.com/api/corporate/bank/getIfscDetails",

  //// Rootif API's /////
  createInviteLink: "/v1/integrations/0/inviteLinks/createInviteLink",
  getOneCompany: "/v1/integrations/0/companies/getOneCompany/",
  disconnectCompany: "/v1/integrations/0/companies/disconnectCompany",
  getAllOrganisations: "/v1/integrations/0/companies/getAllOrganizations",
  syncData: "/v1/integrations/0/companies/syncData",
  syncHistory: "/v1/integrations/0/bills/historiesData",

  // import apis
  getPageDetails: "/api/corporate/imports/getPageDetails",
  getSummary: "/api/corporate/imports/getImportSummary",
  importApi: "/api/corporate/imports/save",

  // onboard apis
  getOnboardFields: "/api/settings/kyc/getPageDetails",
  onboardCreate: "/api/admin/onBoard/userInfoDetails?application_type=LEGAL",
  onboardStep: "/api/settings/kyc/getCompanyKycDetails",
  onboardStepPreview: "/api/settings/kyc/getCompanyKycDetails",
  onboardFinalSubmit: "/api/settings/kyc/submitCompanyKycDetails",

  // customer protal activate
  customerPortalActivatApi: "/api/corporate/beneficiaries/updatePortalAccess",
  //  order management apis
  customer_login: "/api/customer/login",
  customer_logout: "/api/customer/logout",
  customer_products: "/api/customer/products",
  customer_product: "/api/customer/product",
  customer_profile: "/api/customer/profile",
  customer_add_to_cart: "/api/customer/add_to_cart",
  customer_cart: "/api/customer/cart",
  customer_delete_cart: "/api/customer/delete_cart",
  customer_remove_cart_item: "/api/customer/remove_cart_item",
  customer_update_item_quantity: "/api/customer/update_item_quantity",
  customer_orders: "/api/customer/orders",
  customer_orders_show: "/api/customer/orders/show",
  order_comments: "/api/customer/orders/comments",
  customer_my_orders: "/api/customer/my_orders",
  all_orders: "/api/orders",
  customer_orders_update: "/api/customer/orders/update",
  customer_orders_submit: "/api/customer/orders/submit",
  customer_orders_cancel: "/api/customer/orders/cancel",
  customer_orders_update_quantity: "/api/customer/orders/update_quantity",
  customer_orders_destroy: "/api/customer/orders/destroy",
  customer_shipping_addresses: "/api/customer/shipping_addresses",
  customer_billing_addresses: "/api/customer/billing_addresses",
  reject_order: "/api/orders/reject_order",
  approve_order: "/api/orders/approve_order",
  customerAddAddressAPI: "/api/customer/addresses/create",

  // customer Portal APIS
  portalTableDataAPI: "/api/corporate/portal/customer/getCustomerInvoices",
  portalTableDataPaymentAPI:
    "/api/corporate/portal/customer/getAllCustomerReceipts",
  portalRecordDetailsAPI:
    "/api/corporate/portal/customer/getCustomerInvoiceByID",
  portalRecordDetailsPaymentAPI:
    "/api/corporate/portal/customer/getCustomerReceiptByID",
  porttalDashboardAPI: "/api/corporate/portal/customer/getDashboardStats",
  VendorportalDashboardAPI: "/api/corporate/portal/vendor/getDashboardStats",
  portalApprove: "/api/corporate/portal/customer/approve",
  portalDecline: "/api/corporate/portal/customer/decline",
  portalActivateAPI: "/api/corporate/beneficiaries/updatePortalAccess",
  portalInvoicePaymentAPI: "/api/corporate/portal/customer/paynow",
  CustomerPortalProfileUpdateAPI:
    "/api/corporate/portal/customer/updateCustomerDetails",
  porttalDashboardCumpanyListAPI:
    "/api/corporate/beneficiaries/getBeneficiaryCompanies",

  // vendor Portal APIs
  vendorPortalTableAPI: "/api/corporate/portal/vendor/getVendorBills",
  vendorPortalTableRecordAPI: "/api/corporate/portal/vendor/getVendorBillByID",
  vendorPortalTablePaymentAPI:
    "/api/corporate/portal/vendor/getAllVendorReceipts",
  vendorPortalRecordPaymentAPI:
    "/api/corporate/portal/vendor/getVendorReceiptByID",
  vendorPortalProfileUpdateAPI:
    "/api/corporate/portal/vendor/updateVendorDetails",
  // permissions portal
  permissionAPIs: "/api/settings/customer/getCustomerSettings",
  permissionAPIsUpdate: "/api/settings/customer/updateSettings",
  permissionAPIVendor: "/api/settings/vendor/updateSettings",
  permissionGetAPIVendor: "/api/settings/vendor/getVendorSettings",

  report_pdf: "/api/reports/export_report_details",
  adv_pdf: "/api/admin/advances/export_advance_details",
  trip_pdf: "/api/admin/trips/export_trip_details",
  report_share: "/api/reports/share_report",
  zip: "/api/transactions/download_receipts_as_zip",
  // Quick books
  createQuickBooks: "/v1/integrations/0/inviteLinks/createQuickbooks",
  disconnectQuickbooks: "/v1/integrations/0/inviteLinks/disconnectQuickbooks",

  // Receipts apis
  receiptsGetAPI: "/api/corporate/materialReceipt/getAllMaterialReceipts",
  receiptsCreateAPI: "/api/corporate/materialReceipt/createMaterialReceipt",
  receiptsBYIDAPI:
    "/api/corporate/materialReceipt/getMaterialReceiptDetailsById",
  receiptsSubmitAPI: "/api/corporate/materialReceipt/submitMaterialReceipt",
  receiptsApproveAPI: "/api/corporate/materialReceipt/approveMaterialReceipt",
  receiptsRejectAPI: "/api/corporate/materialReceipt/rejectMaterialReceipt",
  receiptsRecallAPI: "",
  receiptsUpdateAPI: "/api/corporate/materialReceipt/updateMaterialReceipt",
  receiptsPrefixAPI: "/api/corporate/materialReceipt/createPrefix",

  challanGetAPI: "/api/corporate/deliveryChallan/getAllDeliveryChallans",
  challanCreateAPI: "/api/corporate/deliveryChallan/createDeliveryChallan",
  challanUpdateAPI: "/api/corporate/deliveryChallan/updateDeliveryChallan",
  challaApproveAPI: "/api/corporate/deliveryChallan/approveDeliveryChallan",
  challaRejectAPI: "/api/corporate/deliveryChallan/rejectDeliveryChallan",
  challanSubmitAPI: "/api/corporate/deliveryChallan/submitDeliveryChallan",
  challaBYIDAPI: "/api/corporate/deliveryChallan/getDeliveryChallanByID",
  challanPrefixAPI: "/api/corporate/deliveryChallan/createPrefix",

  // inventory apis
  inventoryItemsAPIS: "/api/corporate/items/createItem",
  inventoryItemsUpdateAPIS: "/api/corporate/items/updateItem",
  inventoryItemsGetAPIS: "/api/corporate/items/getItems",
  inventoryReportsGetAPIS: "/api/corporate/inventory/getInventoryReport",
  inventoryLogGetAPIS: "/api/corporate/inventory/getInventoryLog",

  // QC Check apis
  qcCheckCreateAPI: "/api/corporate/qcCheck/createQualityControlCheck",
  qcCheckBYIDAPI: "/api/corporate/qcCheck/getQualityControlCheckByID",
  qcCheckGETAPI: "/api/corporate/qcCheck/getAllQualityCheck",
  qcCheckUpdateAPI: "/api/corporate/qcCheck/updateQualityCheckItems",
  qcCheckPrefixAPI: "/api/corporate/qcCheck/createPrefix",

  // Returns apis
  returnsCreateAPI: "/api/corporate/return/createReturnCheck",
  returnsPrefixAPI: "/api/corporate/return/createPrefix",
  returnsGETAPI: "/api/corporate/return/getAllReturnCheck",
  returnsBYIDAPI: "/api/corporate/return/getReturnCheckByID",
  returnsUpdateAPI: "/api/corporate/return/updateReturnCheckItems",

  // Units Type apis
  unitTypeGETAPI: "/api/settings/customization/getAllUnitTypes",
  unitTypeCreateAPI: "/api/settings/customization/createUnitType",
  unitTypeUpdateAPI: "/api/settings/customization/updateUnitType",
  unitTypeDeleteAPI: "/api/settings/customization/deleteUnitType",
  unitTypeEnableAPI: "/api/settings/customization/enableUnitType",
  unitTypeDefaultAPI: "/api/settings/customization/createDefaultValues",
  // warehouse apis
  warehouseGETAPI: "/api/settings/customization/getAllWareHouses",
  warehouseCreateAPI: "/api/settings/customization/createWareHouse",
  warehouseUpdateAPI: "/api/settings/customization/updateWareHouse",
  warehouseDeleteAPI: "/api/settings/customization/deleteWareHouse",
  getAPARAccounts: "/api/corporate/account_ap_ar/getAPARAccounts",
  converturltobase64: "/api/documents/adhoc/converturltobase64",
  userNameFind: "/api/admin/onBoard/userNameFind",
  enabletwoFactorAuth: "/api/admin/users/enabletwoFactorAuth",
  transferAmount: "/api/admin/payments/transferAmount",
  accountBalanceByMasterID: "/api/admin/zenusAccounts/accountBalanceByMasterID",
  getAccountSummary: "/api/admin/dashboard/getAccountSummary",
  getAccountYearSummary: "/api/admin/dashboard/getAccountYearSummary",
  refreshToken: "/api/admin/employee/refreshToken",
};
