import React, { useLayoutEffect, useState } from "react";
import {
  Card,
  DatePicker,
  Select,
  Space,
  Typography,
  Row,
  Col,
  Tag,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  get_all_payments,
  get_person_accounts,
} from "../../actions/z_accounts";
import { useLocation, useHistory } from "react-router-dom";
import { SideMenu } from "../../actions/masterdata/masterdata";

const { Option } = Select;
const { Title, Text } = Typography;

const CustomStatement = ({
  setSelectedAccount,
  account,
  setAccount,
  setAccountObj,
  setFilterDetails,
}) => {
  const [dates, setDates] = useState([null, null]);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [selectedTag, setSelectedTag] = useState("");
  const bAcc = useSelector((state) => state?.z_accs?.accounts?.data?.data);
  const bankAcc = useSelector(
    (state) => state?.z_accs?.accounts?.data?.data || []
  );
  const handleDateChange = (dates) => {
    setDates(dates);
  };

  const handleQuickSelect = (range) => {
    setSelectedTag(range);
    const today = moment();
    switch (range) {
      case "today":
        setDates([today, today]);
        break;
      case "thisWeek":
        setDates([today.startOf("week"), today.clone().endOf("week")]);
        break;
      case "lastWeek":
        setDates([
          today.subtract(1, "week").startOf("week"),
          today.clone().endOf("week"),
        ]);
        break;
      case "thisMonth":
        setDates([today.startOf("month"), today.clone().endOf("month")]);
        break;
      case "lastMonth":
        setDates([
          today.subtract(1, "month").startOf("month"),
          today.clone().endOf("month"),
        ]);
        break;
      case "thisYear":
        setDates([today.startOf("year"), today.clone().endOf("year")]);
        break;
      case "lastYear":
        setDates([
          today.subtract(1, "year").startOf("year"),
          today.clone().endOf("year"),
        ]);
        break;
      default:
        break;
    }
  };

  const handleAccountChange = (value) => {
    setAccount(value);
    setAccountObj(bankAcc?.find((ele) => ele.id === value));
  };

  useLayoutEffect(() => {
    dispatch(
      get_person_accounts({
        params: {
          page_number: 1,
          per_page: 1000,
        },
        person_id: "ID-1146",
      })
    );
    if (location?.pathname) {
      let locationSplit = location?.pathname?.split("/");
      dispatch({
        type: SideMenu,
        payload: {
          firstNav: "/" + locationSplit?.[1],
          secondNav: "/" + locationSplit?.[2],
          showChildren: true,
          childrenMenu: "/" + locationSplit?.[3],
        },
      });
    }
  }, []);

  useLayoutEffect(() => {
    if (bankAcc?.length) {
      const acc = bankAcc?.find((ele) => ele.id === location?.state?.accountId);
      if (acc) {
        setAccount(acc?.id);
        setAccountObj(acc);
        // history.replace(location.pathname);
      } else {
        setAccount(bankAcc?.[0]?.id);
        setAccountObj(bankAcc?.[0]);
      }
    }
  }, [bAcc]);

  return (
    <Card className="select-statement mt2">
      <div className="w60">
        <Title level={4}>Generate a custom statement</Title>
        <Text>
          Select dates to generate a statement. Dates must fall within the
          current month.
        </Text>
        <br />
        <br />
        <Row gutter={16}>
          <Col span={12}>
            <Text className="label">From</Text>
            <DatePicker
              picker="month"
              value={dates[0]}
              onChange={(date) => handleDateChange([date, dates[1]])}
              format="YYYY-MM"
              style={{ width: "100%" }}
              placeholder="From"
            />
          </Col>
          <Col span={12}>
            <Text className="label">To</Text>
            <DatePicker
              picker="month"
              value={dates[1]}
              disabledDate={(current) => {
                // Disable all dates after maxDate
                return current && current < dates?.[0]?.startOf("month");
              }}
              onChange={(date) => handleDateChange([dates[0], date])}
              format="YYYY-MM"
              style={{ width: "100%" }}
              placeholder="To"
            />
          </Col>
        </Row>
        <br />
        <Text className="label">Account</Text>
        <Select
          value={account}
          onChange={handleAccountChange}
          style={{ width: "100%" }}
        >
          {bankAcc?.map((itm) => (
            <Option value={itm.id}>
              <span className="ellipsis">{itm?.account_name}</span>- ****
              {itm?.account_number?.slice(-4)}
            </Option>
          ))}
        </Select>
        <br />
        <br />
        <div className="hr mb3" />
        <Space className="mt1">
          {/* <Tag
            onClick={() => handleQuickSelect("today")}
            className={selectedTag === "today" ? "active" : ""}
          >
            Today
          </Tag>
          <Tag
            onClick={() => handleQuickSelect("thisWeek")}
            className={selectedTag === "thisWeek" ? "active" : ""}
          >
            This week
          </Tag>
          <Tag
            onClick={() => handleQuickSelect("lastWeek")}
            className={selectedTag === "lastWeek" ? "active" : ""}
          >
            Last week
          </Tag> */}
          <Tag
            onClick={() => handleQuickSelect("thisMonth")}
            className={selectedTag === "thisMonth" ? "active" : ""}
          >
            This month
          </Tag>
          <Tag
            onClick={() => handleQuickSelect("lastMonth")}
            className={selectedTag === "lastMonth" ? "active" : ""}
          >
            Last month
          </Tag>
          <Tag
            onClick={() => handleQuickSelect("thisYear")}
            className={selectedTag === "thisYear" ? "active" : ""}
          >
            This year
          </Tag>
          <Tag
            onClick={() => handleQuickSelect("lastYear")}
            className={selectedTag === "lastYear" ? "active" : ""}
          >
            Last year
          </Tag>
          <br />
        </Space>
        <br />
        {/* <div className="info mt2">
          <InfoCircleOutlined />
          <p>
            Downloaded statements are password protected for security. Please
            use your bank account number as the password to access the file.
          </p>
        </div> */}
        <Space className="mt2">
          <button
            className="send-btn"
            onClick={() => {
              setSelectedAccount(true);
              setFilterDetails({
                account_id: account,
                // account_id: "ID-1165",
                date_from: dates[0]?.startOf("month").format("YYYY-MM-DD"),
                date_to: dates[1]?.endOf("month").format("YYYY-MM-DD"),
                date_type: "VALUE_DATE",
                page_number: 1,
                page_size: 50,
              });
              dispatch(
                get_all_payments({
                  params: { account_id: account },
                  statements: true,
                  filters: {
                    created_d_time: {
                      start_date: dates[0]
                        ?.startOf("month")
                        ?.format("YYYY-MM-DD"),
                      end_date: dates[1]?.endOf("month").format("YYYY-MM-DD"),
                    },
                  },
                })
              );
            }}
            disabled={!dates?.[0] || !dates?.[1]}
          >
            View
          </button>
          {/* <button className="secondary-btn">Cancel</button> */}
        </Space>
      </div>
    </Card>
  );
};

export default CustomStatement;
