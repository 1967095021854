import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Steps,
  Tabs,
  Upload,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import { getCustomFields } from "../../../../actions/companySettings";
import { apis } from "../../../../config/APIs";
import useDocumentAxios from "../../../../config/useDocument";
import { findLodash, getFileExtension } from "../../../../utils";
import close from "../../../../assets/icons/close.png";
import {
  addEmployees,
  deleteEmployeeDocument,
  EmployeeById,
  updateEmployees,
} from "./../../../../actions/employees";
import { APP_BASE_URL } from "./../../../../config/Axios";
import AddUserFirstStep from "./AddUserFirstStep";
import { get_person_accounts } from "../../../../actions/z_accounts";

const { TabPane } = Tabs;
const { Option } = Select;
const { Dragger } = Upload;

// const columns = [
//   {
//     title: "Category",
//     dataIndex: "nasme",
//     key: "nasme",
//   },
//   {
//     title: "Limit Duration",
//     dataIndex: "accodunt_code",
//     key: "account_codsxde",
//   },
//   {
//     title: "Amount",
//     dataIndex: "is_bifdll_mandatory",
//     key: "is_bill_mfdandatory",
//   },
//   {
//     title: "Eligible Amount",
//     dataIndex: "account_code",
//     key: "account_code",
//   },
// ];

const ValidationSchema = yup.object().shape({
  // name: yup
  //     .string()
  //     .required('Please provide Name'),
  // email: yup
  //     .string()
  //     .email()
  //     .required('Please provide valid email'),
  // primary_phone: yup
  //     .string()
  //     .matches(phoneRegExp, 'Phone number is not valid'),
});

/* eslint-disable no-template-curly-in-string */
const validateMessages1 = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
};
/* eslint-enable no-template-curly-in-string */

const AddUsers = (props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  //   const employeesResponse = useSelector(state => state.employees.employees || {});
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeTab, setActiveTab] = React.useState("profile");
  const [checkedValue, setCheckedValue] = React.useState(true);
  const [payloadPage1, setPayloadPage1] = React.useState({});
  const [payloadPage2, setPayloadPage2] = React.useState({});
  const [payloadPage3, setPayloadPage3] = React.useState({});
  const [mobile, setMobile] = React.useState("");
  const [gradesData, setGradesData] = React.useState([]);
  const [uploadData, setUploadData] = React.useState({});
  const [uploadDetails, setUploadDetails] = React.useState({});
  const [currentStep, setCurrentStep] = React.useState(0);

  const projectsResponse = useSelector(
    (state) => state.companySetting.getProjectsField?.data?.data || []
  );

  const addEmployeesResponse = useSelector(
    (state) => state.employees.addEmployees || {}
  );

  const employeebyIDResponse = useSelector(
    (state) => state.employees.employeebyId || {}
  );

  const updateEmployeesResponse = useSelector(
    (state) => state.employees.updateEmployees || {}
  );
  const costCentersResponse = useSelector(
    (state) => state.companySetting.getCostCenterField?.data?.data || []
  );

  const rolesResponse = useSelector(
    (state) => state.companySetting.getRoles || {}
  );
  const userFieldsResponse = useSelector(
    (state) => state.companySetting.getCustomFields || {}
  );
  const getDesignationsResponse = useSelector(
    (state) => state.companySetting.getDesignations || {}
  );

  const deleteEmployeeResponse = useSelector(
    (state) => state?.employees?.deleteEmployeeDocument
  );

  const { onCall, loading } = useDocumentAxios({
    api: apis.createDocument,
    method: "post",
  });
  const { data: { data: rolesData = [] } = {} } = rolesResponse;

  const {
    data: {
      data: {
        custom_fields: userfieldsData = [],
        approvers: approversData = [],
        // advance_approvers: advanceApproversData = [],
      } = {},
    } = {},
  } = userFieldsResponse;

  const { data: { data: designationsData = [] } = {} } =
    getDesignationsResponse;

  const editValues = employeebyIDResponse?.data?.data;

  const { data: { data: data = [] } = {} } = employeebyIDResponse;
  React.useEffect(() => {
    if (employeebyIDResponse?.data?.error === false) {
      // if (employeesResponse.success && props.selectedId)
      form.resetFields();
      let obj = {};
      approversData?.forEach((ele) => {
        obj[ele?.role_name] = ele?.selected?.id ?? "";
      });

      userfieldsData.forEach((ele) => {
        if (ele.column_type === "integer") {
          obj[ele.field_id] = ele?.selected?.value
            ? Number(ele?.selected?.value)
            : "";
        } else if (ele.column_type === "datetime") {
          obj[ele.field_id] = ele?.selected?.value ?? "";
        } else if (ele.column_type === "document") {
          obj[ele.field_id] =
            ele?.selected?.attachments?.map((item) => item?.id) ?? [];
        } else {
          obj[ele.field_id] = ele?.selected?.value ?? "";
        }
      });

      userfieldsData.forEach((ele) => {
        if (ele.column_type === "document") {
          setUploadDetails((prevUploadDetails) => {
            return {
              ...prevUploadDetails,
              [ele.field_id]: {
                attachmentId: ele.selected.attachments.map((ele) => ele.id),
                recordId: ele.selected.id,
              },
            };
          });
        }
      });

      userfieldsData.forEach((ele) => {
        if (ele.column_type === "document") {
          setUploadData((b) => {
            return {
              ...b,
              [ele.field_id]: {
                attachmentId: ele.selected.attachments.map((ele) => ele.id),
                recordId: ele.selected.id,
              },
            };
          });
        }
      });

      if (data?.designation_id) {
        handleDesignation(data?.designation_id);
      }

      form.setFieldsValue({
        title: editValues?.title,
        name: editValues?.first_name,
        username: editValues?.username,
        email: editValues?.email,
        card_number: location.state?.user?.card_reference_no,
        phone_country_code: editValues?.phone_country_code,
        primary_phone: editValues?.primary_phone,
        account_ids: editValues?.account_info?.map((ele) => ele.id),
        emp_id: editValues?.emp_id,
        roles: editValues?.roles?.filter((ele) => ele !== "Employee"),
        department: editValues?.department,
        approver: editValues?.user_id,
        project_id: editValues?.project?.id,
        cost_center_id: editValues?.cost_center?.id,
        designation: editValues?.designation?.id,
        ...obj,
      });
      setMobile(data?.primary_phone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approversData, userfieldsData, employeebyIDResponse]);

  React.useEffect(() => {
    if (!props.selectedId) dispatch(getCustomFields({ is_enable: true }));
    dispatch(
      get_person_accounts({
        params: {
          page_number: 1,
          per_page: 1000,
        },
        person_id: "ID-1146",
      })
    );
    return () => {
      dispatch({
        type: EmployeeById.RESET,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const handleBackButton = (goto) => {
    switch (goto) {
      case "screen1":
        setActiveTab("profile");
        setCurrentStep(0);
        break;
      case "screen2":
        setActiveTab("limits");
        setCurrentStep(1);
        break;
      default:
        break;
    }
  };

  const changeTab = (activeKey) => {
    const page1 = {};
    const page2 = {};
    //   const page3 = {};
    if (activeKey === "limits") {
      [
        "title",
        "name",
        "email",
        "primary_phone",
        "phone_country_code",
        "emp_id",
        "roles",
        "username",
        "custom_fields",
        "cost_center_id",
        "project_id",
        "account_ids",
        "approver",
      ].forEach((item) => {
        page1[item] = form.getFieldValue(item) ?? "";
      });

      const fieldValues = userfieldsData.map(
        ({ field_id, column_name, column_type }) => {
          let id = field_id;
          let value1 = form.getFieldValue(field_id) ?? "";
          let value =
            column_type === "datetime"
              ? value1
                ? moment(value1).format("DD-MM-YYYY HH:mm:ss")
                : ""
              : value1;
          let field_name = column_name;
          // return { id, value, field_name };
          if (column_type === "document") {
            let attachment_id = uploadDetails[field_id]?.attachmentId ?? [];
            return { id, field_name, field_type: column_type, attachment_id };
          } else {
            return { id, value, field_name, field_type: column_type };
          }
        }
      );

      const approversDataSet = approversData
        .map(({ role_name, role_id }) => {
          return form.getFieldValue(role_name)
            ? { role_id: role_id, approver_id: form.getFieldValue(role_name) }
            : false;
        })
        .filter((ele) => ele);

      page1["field_values"] = fieldValues.filter(Boolean) || [];
      page1["report_approvers"] = approversDataSet.filter(Boolean) || [];

      ValidationSchema.validate(page1)
        .then(function () {
          setPayloadPage1(page1);
          // setActiveTab(activeKey);
          const subObj = { ...page1, ...payloadPage2, ...payloadPage3 };
          if (subObj) {
            Object.keys(subObj).forEach((key) => {
              if (!subObj[key]) {
                delete subObj[key];
              }
            });
            props.selectedId
              ? dispatch(updateEmployees({ ...subObj, id: props.selectedId }))
              : dispatch(
                  addEmployees({
                    ...subObj,
                    redirect_url: APP_BASE_URL,
                    isVirtual: props.isVirtual,
                  })
                );
          }
        })
        .catch(function (err) {
          // err && message.error(<span className="messageText">{err}</span>);
        });
    } else if (activeKey === "card") {
      ["designation_id", "grade_id"].forEach((item) => {
        page2[item] = form.getFieldValue(item) ?? "";
      });

      setPayloadPage2(page2);
      setActiveTab(activeKey);
    } else if (activeKey === "submit") {
      // [
      //     'card_number',
      //   ].forEach(item => {
      //     payloadPage3[item] = form.getFieldValue(item);
      //     page3[item] = form.getFieldValue(item);
      //   });
      //   setPayloadPage3(page3);
      // setAllpagesForm({ ...payloadPage1, ...payloadPage2, ...payloadPage3})
      // setSubmitForm(true);
    }
  };

  // React.useEffect(()=>{dispatch(getProjectsField({pagination:false}))},[])

  const tabTitle = {
    profile: () => {
      return (
        <>
          <div className="tabCircles">1</div>
          <div className="tabTitles">
            {t("profile")}
            <p>
              {t("user")}&apos;{t("s")} {t("personal_information")}
            </p>
          </div>
        </>
      );
    },
    limits: () => {
      return (
        <>
          <div className="tabCircles">2</div>
          <div className="tabTitles">
            {t("Department & Grade")}
            <p>{t("Define Department and Grade for the user")}</p>
          </div>
        </>
      );
    },
    card: () => {
      return (
        <>
          <div className="tabCircles">3</div>
          <div className="tabTitles">
            {t("Card")}
            <p>{t("assign_prepaid_card")}</p>
          </div>
        </>
      );
    },
  };

  // const data = [];
  useEffect(() => {
    if (props.editTab == 1) {
      setActiveTab("profile");
    } else if (props.editTab == 2) {
      setActiveTab("limits");
    } else if (props.editTab == 3) {
      setActiveTab("card");
    }
  }, [props.editTab]);

  const handleSubmit1 = () => {
    changeTab("limits");
    setCurrentStep(1);
  };

  const handleDesignation = (value) => {
    const gradesValue = findLodash(designationsData, { id: value })?.grades;
    setGradesData(gradesValue);
    form.setFields([
      {
        name: "grade_id",
        value: "",
      },
    ]);
  };

  const handleCheck = (e) => {
    setCheckedValue(e.target.checked);
  };

  const onFileChange = async (info, name) => {
    if (info.file.status === "removed") {
      data.id && info.file.id
        ? dispatch(
            deleteEmployeeDocument({
              record_id: uploadDetails[name].recordId,
              ids: [info.file.id],
            })
          )
        : dispatch(
            deleteEmployeeDocument({
              record_id: uploadData[info?.file?.uid].recordId,
              ids: [uploadData[info?.file?.uid].attachment_id],
            })
          );
      let recordfile = "";
      {
        data.id && info.file.id
          ? (recordfile = info.file.id)
          : (recordfile = uploadData[info?.file?.uid].attachment_id);
      }

      setUploadDetails((b) => ({
        ...b,
        [name]: {
          attachmentId: [
            ...b[name].attachmentId.filter((val) => val.includes(recordfile)),
          ],
        },
      }));
    } else {
      const nextState = {};
      nextState.selectedFile = info.file;
      nextState.selectedFileList = [info.file];
      let uniqueNo = "";
      {
        data?.id ? (uniqueNo = data?.id) : (uniqueNo = uuidv4());
      }
      const formData = new FormData();
      formData.append("files", info.file);
      formData.append("name", info.file.name);
      formData.append("record_type", "Document");
      formData.append("record_id", uniqueNo);

      const attachId = await onCall({
        data: formData,
        headers: {
          "Content-Type": "multipart/formdata",
        },
      });

      setUploadDetails((b) => {
        if (Object.keys(b).includes(name)) {
          return {
            ...b,
            [name]: {
              ...b[name],
              attachmentId: [...b[name].attachmentId, attachId?.data?.[0]],
            },
          };
        } else {
          return {
            ...b,
            [name]: {
              attachmentId: [attachId?.data?.[0]],
            },
          };
        }
      });

      setUploadData((b) => {
        return {
          ...b,
          [info?.file?.uid]: {
            attachment_id: attachId?.data?.[0],
            recordId: uniqueNo,
          },
        };
      });
    }
  };

  const renderDataTypes = (
    value,
    default_values,
    name,
    attachmentDetails,
    val,
    attachment
  ) => {
    switch (value) {
      case "string":
        return (
          <span className="ant-input-group-wrapper">
            <span className="ant-input-wrapper ant-input-group">
              <input
                placeholder="Enter Value"
                type="text"
                className="ant-input pp-form-input"
                name={name}
                defaultValue={val}
                onChange={(e) => {
                  form.setFields([
                    {
                      name,
                      value: e.target.value,
                    },
                  ]);
                }}
              />
            </span>
          </span>
        );
      case "integer":
        return (
          <span className="ant-input-group-wrapper">
            <span className="ant-input-wrapper ant-input-group">
              <input
                placeholder={t("enter_num")}
                type="text"
                className="ant-input pp-form-input"
                name={name}
                defaultValue={val}
                onChange={(e) => {
                  form.setFields([
                    {
                      name,
                      value: e.target.value,
                    },
                  ]);
                }}
              />
            </span>
          </span>
        );
      case "dropdown":
        return (
          <Select
            name={name}
            value={val}
            placeholder="Select Role"
            className="pp-dropdown-inline title-select"
            onChange={(e) => {
              form.setFields([
                {
                  name,
                  value: e,
                },
              ]);
            }}
          >
            {default_values &&
              default_values.map(({ value, id }) => (
                <Option key={id} value={value}>
                  {value}
                </Option>
              ))}
          </Select>
        );
        break;

        // case "checkbox":
        return (
          <Input
            placeholder={t("enter_val")}
            className="pp-form-input"
            onChange={(e) => {
              form.setFields([
                {
                  name,
                  value: e.target.checked,
                },
              ]);
            }}
          />
        );

      case "multicheckbox":
        return (
          <Input
            placeholder={t("enter_val")}
            style={{ minWidth: "216px" }}
            onChange={(e) => {
              form.setFields([
                {
                  name,
                  value: e.target.checked,
                },
              ]);
            }}
          />
        );

      case "document":
        return (
          <div>
            <img src="" alt="" />
            <Dragger
              beforeUpload={(file) => {
                const fileName = getFileExtension(file?.name);
                const maxSize = attachmentDetails.max_size;
                const allowedImages = attachmentDetails.doc_formats.split(",");
                const uppercaseImages = allowedImages.map((image) =>
                  image.trim().toUpperCase()
                );
                const imagetype = uppercaseImages.join(",  ");
                if (!allowedImages?.includes(fileName)) {
                  message.error(
                    <span className="messageText">
                      You can only upload {imagetype}.
                    </span>
                  );
                  return Upload.LIST_IGNORE;
                } else if (file.size > maxSize * 1024) {
                  message.error(
                    <span className="messageText">
                      File size must be smaller than{" "}
                      {attachmentDetails.max_size} KB
                    </span>
                  );
                  return Upload.LIST_IGNORE;
                }
                return false;
              }}
              listType="picture"
              name="logo"
              disabled={loading || deleteEmployeeResponse.loading}
              multiple={false}
              onChange={(evt) => onFileChange(evt, name)}
              defaultFileList={attachment}
              accept="image/*"
              style={{ position: "relative", top: "-2rem" }}
              // showUploadList={false}
            >
              <p
                style={{ fontSize: "0.8rem !important" }}
                className="ant-upload-text"
              >
                {t("drag_attachment")}
                <br />
                {t("click_here_to")}
              </p>
            </Dragger>
          </div>
        );
        break;
      case "datetime":
        return (
          <>
            <DatePicker
              name={name}
              type={"date"}
              className="pp-form-input"
              defaultValue={val ? moment(val, "YYYY-MM-DD HH:mm:ss") : null}
              onChange={(e) => {
                form.setFields([
                  {
                    name,
                    value: e,
                  },
                ]);
              }}
              showTime
            />
          </>
        );
      default:
        // code block
        break;
    }
  };

  // form first step Here
  return (
    <>
      <div className="upload_payment_parent send-payment add-user">
        <div className="flex-between sec-head">
          <div className="tabsSection">
            <span className="active">New User</span>
          </div>
          <img
            src={close}
            width={20}
            onClick={() => {
              props?.setShowAddPage(false);
            }}
          />
        </div>
        <div className="p2">
          <div className="steps-content mt4">
            <AddUserFirstStep
              form={form}
              renderDataTypes={renderDataTypes}
              validateMessages1={validateMessages1}
              handleSubmit1={handleSubmit1}
              loading={loading || addEmployeesResponse.loading}
              editValues={editValues}
              projectsResponse={projectsResponse}
              handleDesignation={handleDesignation}
              approversData={approversData}
              // advanceApproversData={advanceApproversData}
              rolesData={rolesData}
              userfieldsData={userfieldsData}
              costCentersResponse={costCentersResponse}
              setMobile={setMobile}
              mobile={mobile}
              handleAdd={props.handleAdd}
              props={props}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUsers;
