import { PlusCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Col, Menu, Row } from "antd";
import { saveAs } from "file-saver";
import json2csv from "json2csv";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { customers } from "../../actions/customers";
import {
  get_currencies,
  headerTitles,
} from "../../actions/masterdata/masterdata";
import { gridApis } from "../../config/GridApis";
import usePayAxios from "../../config/useAxios";
import { AccountDisplay } from "../Account/AccountCard";
import CreateManageBeneficiary from "../Common/CreateManageBeneficiary";
import TableComponent from "../Common/TableComp/TableComp";
import { p_types } from "../Payments/SendMoney/Recipients";
import TableHeader from "../Sales/TableHeader";
import ViewBen from "./ViewBen";

const ManageBeneficiary = ({ hasWriteAccess }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [openAddNew, setOpenAddNew] = useState(false);
  const [page, setPage] = useState(1);
  const [benDetail, setBenDetail] = useState({});
  const [benOpen, setBenOpen] = useState(false);

  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filters, setFilters] = useState({});
  const { onCall } = usePayAxios({
    api: gridApis.getbeneficiaryByID,
    method: "get",
  });
  const columns = [
    {
      title: t("beneficiary_name"),
      dataIndex: "beneficiary_name",
      render: (text, rec) => {
        return `${text} ${rec?.surname ? rec?.surname : ""}`;
      },
    },
    {
      title: t("account_num"),
      dataIndex: "bank_accounts",
      render: (text) => {
        return <AccountDisplay accountNumber={text?.[0]?.account_number} />;
      },
    },
    {
      title: t("Routing/SWIFT Code"),
      dataIndex: "bank_accounts",
      render: (text) => {
        return text?.[0]?.routing_code ?? text?.[0]?.swift_code;
      },
    },
    {
      title: t("bank_name"),
      dataIndex: "bank_accounts",
      render: (text) => {
        return text?.[0]?.bank_name;
      },
    },
    {
      title: t("Associated Account"),
      dataIndex: "account_details",
      render: (text) => {
        return <AccountDisplay accountNumber={text?.account_number} />;
      },
    },
    {
      title: t("Currency"),
      dataIndex: "currency_code",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Transfer Mode",
      dataIndex: "account_transfer_mode_type",
      render: (text) => (
        <div>{p_types.find((ele) => ele.value === text).label}</div>
      ),
    },
    {
      title: t("email"),
      dataIndex: "email",
    },
    {
      title: t("phone_no"),
      dataIndex: "primary_contact",
    },
  ];

  useEffect(() => {
    if (location?.state?.open) {
      handleAdd();
    }
    dispatch(
      customers({
        type: "individual",
      })
    );
    dispatch(
      get_currencies({
        all: true,
      })
    );
    dispatch(
      headerTitles({
        title: "Recipients",
        description: "Add Recipients to transfer and receive funds.",
      })
    );
    // eslint-disable-next-line
  }, []);

  const handleAdd = () => {
    setOpenAddNew(!openAddNew);
  };

  const handleSearch = (value) => {
    dispatch(
      customers({
        type: "individual",
        search_key: value,
        sort: filters?.sort,
      })
    );
    setFilters({
      ...filters,
      search_key: value,
    });
    setPage(1);
  };

  function handleMenuClick(e) {
    if (e?.key === "1") handleAdd();
    else history.push("/upload-bulk-beneficiaries");
  }

  const menu = (
    <Menu
      onClick={(e) => {
        hasWriteAccess && handleMenuClick(e);
      }}
    >
      <Menu.Item key="1" icon={<PlusCircleOutlined />}>
        {t("Add Recipient")}
      </Menu.Item>
      <Menu.Item key="2" icon={<UploadOutlined />}>
        {t("Upload recipients")}
      </Menu.Item>
    </Menu>
  );

  const manageResponse = useSelector(
    (state) => state.customers?.all?.data?.data || 0
  );
  const totalRecords = useSelector(
    (state) => state.customers?.all?.data?.total_count || 0
  );

  return (
    <>
      {openAddNew && (
        <CreateManageBeneficiary
          isVisible={openAddNew && hasWriteAccess}
          onClose={(page) => {
            setOpenAddNew(false);
            setBenDetail({});
            if (page) {
              setPage(1);
            }
          }}
          benData={benDetail}
        />
      )}
      <ViewBen
        open={benOpen}
        benDetail={benDetail}
        onClose={() => {
          setBenDetail({});
          setBenOpen(false);
        }}
        onEdit={() => {
          setBenOpen(false);
          setOpenAddNew(true);
        }}
        cb={() => {
          dispatch(
            customers({
              type: "individual",
            })
          );
          setBenOpen(false);
          setBenDetail({});
        }}
      />
      <Row wrap={false} className="table-wrapper">
        <Col flex="auto" className="table-wrapper-col">
          <div className="flex-grow">
            <TableHeader
              //   filterMenu={filterOptions}
              // handleFilter={handleFilter}
              noFilter={true}
              handleAddButton={handleAdd}
              menu={menu}
              noAddNewBtn={false}
              hasExport={selectedRows?.length > 0}
              hasWriteAccess={hasWriteAccess}
              handleExport={() => {
                const prods1 = selectedRows?.map((ele) => ({
                  "Recipient Name": ele.beneficiary_name,
                  "Recipient Email": ele.email,
                  "Bank Name": ele.bank_accounts?.[0]?.bank_name,
                  "Bank Account No": ele.bank_accounts?.[0]?.account_number,
                  "Routing/SWIFT Code":
                    ele.bank_accounts?.[0]?.swift_code ||
                    ele.bank_accounts?.[0]?.routing_code ||
                    ele.bank_accounts?.[0]?.ifsc_code,
                  Currency: ele.currency_code,
                  "Phone No": `${
                    ele?.phone_country_code ? ele?.phone_country_code : ""
                  } ${ele?.primary_contact ? ele?.primary_contact : ""}`,
                }));
                const filename = "Recipients";
                let csv = json2csv.parse(prods1);

                var blob = new Blob([csv], {
                  type: "text/csv;charset=utf-8",
                });
                saveAs(blob, `${filename}.csv`);
                setSelectedRowKeys([]);
                setSelectedRows([]);
              }}
              onSearch={handleSearch}
              dotMenu={["beneficiary_name", "account_transfer_mode_type"]}
              isMore={true}
              handleSort={(val, sort) => {
                setFilters((fltDetails) => ({
                  ...fltDetails,
                  sort: {
                    sort_key: val,
                    sort_order: sort ? -1 : 1,
                  },
                }));
                setPage(1);
                dispatch(
                  customers({
                    type: "individual",
                    page_number: 1,
                    search_key: filters.search_key,
                    sort: {
                      sort_key: val,
                      sort_order: sort ? -1 : 1,
                    },
                  })
                );
              }}
            />
            <div className="" style={{ paddingTop: "22px" }}>
              <TableComponent
                columns={columns}
                loading=""
                data={Array.isArray(manageResponse) ? manageResponse : []}
                scroll={{
                  x: "max-content",
                }}
                pagination={{
                  total: totalRecords || 0,
                  current: page,
                  onChange: (pagee) => {
                    dispatch(
                      customers({
                        page_number: pagee,
                        type: "individual",
                        sort: filters?.sort,
                      })
                    );
                    setPage(pagee);
                  },
                }}
                rowSelection={{
                  type: "checkbox",
                  preserveSelectedRowKeys: true,
                  // ...rowSelection,
                  // onChange: onChangeCheckBox,
                  getCheckboxProps: (record) => ({
                    checked: selectedRows?.find((ele) => ele.id === record.id)
                      ? true
                      : false,
                    // disabled: loading,
                  }),
                  selectedRowKeys: selectedRowKeys,
                  onSelect: (record, selected) => {
                    if (selected) {
                      setSelectedRows((rows) => [...rows, record]);
                      setSelectedRowKeys((rows) => [...rows, record.id]);
                    } else {
                      let rows = selectedRows;
                      let idx = rows.findIndex((ele) => ele.id === record.id);
                      if (idx > -1) {
                        rows.splice(idx, 1);
                      }
                      setSelectedRows(rows);
                      setSelectedRowKeys(rows?.map((ele) => ele.id));
                    }
                  },
                  onSelectAll: (checked, records) => {
                    if (checked) {
                      setSelectedRows((rows) => [...rows, ...records]);
                      setSelectedRowKeys((rows) => [
                        ...rows,
                        ...records?.map((ele) => ele?.id),
                      ]);
                    } else {
                      let rows = selectedRows;
                      manageResponse.forEach((itm) => {
                        let idx = rows.findIndex((ele) => ele.id === itm.id);
                        if (idx > -1) {
                          rows.splice(idx, 1);
                        }
                      });
                      setSelectedRows(rows);
                      setSelectedRowKeys(rows?.map((ele) => ele.id));
                    }
                  },
                }}
                // handleRowClick={handleSelectRow}
                rowKey="id"
                handleRowClick={(_e, rec) => {
                  // setBenDetail(rec);
                  // setBenOpen(true);
                  onCall({
                    params: {
                      beneficiary_id: rec.id,
                    },
                  })
                    .then((res) => {
                      if (res.error === false) {
                        setBenDetail(res.data);
                        setBenOpen(true);
                      }
                    })
                    .catch(() => {});
                }}
                renderMobItem={(item) => {
                  return (
                    <div class="recent-transaction">
                      <div className="tx-det">
                        <div class="avatar">
                          {item?.beneficiary_name?.substr(0, 2)}
                        </div>
                        <div class="detail">
                          <div class="date-time">
                            {item?.bank_accounts?.[0]?.routing_code}
                          </div>
                          <div class="title">
                            <span className="ellipsis">
                              {item?.beneficiary_name}{" "}
                              {item?.surname ? item?.surname : ""}
                            </span>
                          </div>
                          <div>
                            <span>
                              <AccountDisplay
                                accountNumber={
                                  item?.bank_accounts?.[0]?.account_number
                                }
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="amount"
                        style={{
                          maxWidth: "9rem",
                        }}
                      >
                        {`${item?.currency_code} / ${
                          p_types?.find(
                            (ele) =>
                              ele.value === item?.account_transfer_mode_type
                          ).label
                        }`}
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ManageBeneficiary;
