export const en = {
  spendTitleText:
    "Spend management platform designed to help your business spend better",
  mordernMind: "Designed with modern business in mind",
  welcomeBack: "Welcome to Zenus Digital Corporate Banking",
  signIntoAcc: "Please sign in to your account.",
  emailAddr: "Username or Email",
  additional_info: "Additional Information",
  empId: "Emp ID",
  enter_company_name: "Enter Company Name",
  enter_first_name: "Enter First Name",
  company_register_no: "Company Registration No",
  company_required: "Company name is required",
  other_information: "Other Information",
  forgotPassword: "Forgot Password",
  input_email: "Please input your E-mail!",
  input_pwd: "Please input your password!",
  ok: "Ok",
  or: "Or",
  loginWithEmpId: "Login with employee ID",
  loginWithEmail: "Login with email",
  haveAcc: "Don't have an account yet",
  haveAcc1: "Have an account ",
  clickHere: "Click here to",
  freeDemo: "Register for Free Demo",
  SignUp: "SignUp",
  account: "Account",
  Amount: "Amount",
  "Date and Time": "Date and Time",
  Company: "Company",
  accountBalance: "Account Balance",
  all_cards: "All Cards",
  all: "All",
  no_records_found: "No records found",
  my_cards: "My Cards",
  my_advances: "My Advances",
  accountBalanceHint: "Overall company wallet available balance",
  totalPayables: "Total Payables",
  totalReceivablesHint:
    "Current and overdue amount that you have to receive from customers",
  totalReceivables: "Total Receivables",
  totalPayablesHint:
    "Current and overdue amount that you’re yet to pay your Vendors",
  balance: "Balance",
  totalUnpaidInvoices: "Total Unpaid Invoices",
  totalUnpaidBills: "Total Unpaid Bills",
  buyer_sell:
    "Buyer issues document to a seller information about the items they want to buy",
  bill_document:
    "A Bill is a document that indicates the amount you owe your vendors.",
  current: "Current",
  overDue: "Overdue",
  pendingApprovals: "Pending Approvals",
  overallSummary: "Overall Summary",
  reports: "Reports",
  bills: "Bills",
  primary_account_balance: "Primary A/c Balance",
  corporate_balance: "Corporate Balance",
  invoices: "Invoices",
  material_desc: "(Material/Delivery Receipt/Purchase Order)",
  sale_order_document:
    "A Sales Order is a document sent to your customers confirming the items and prices of a sale",
  sale_order_approvals: "Sales Order Approvals",
  estimate_approvals: "Estimate Approvals",
  purchase_order_approvals: "Purchase Order Approvals",

  trips: "Trips",
  advances: "Advances",
  save_and_submit: "Save and Submit",
  id: "Id",
  an_estimate: "An Estimate is a proposal that you send to your customers",
  a_invoice:
    "An Invoice is a document that indicates the amount your customers owe.",
  reportName: "Report Name",
  submittedOn: "Submitted On",
  date_range: "Date Range",
  is_before: "Is Before",
  is_after: "Is After",
  totalExpenses: "Total Expenses",
  awaiting: "Awaiting",
  total: "Total",
  totalReimbursed: "Total Reimbursed",
  totalTrips: "Total Trips",
  cashFlow: "Cash Flow",
  topExpense: "Top Expense",
  cash: "Cash",
  mealsEntertainment: "meals & entertainment",
  travel: "Travel",
  wallets_balances: "Wallets and Balances",
  trip: "Trip",
  due_amount: "Due Amount",
  contactUs: "Contact Us",
  text_box: "Text Box",
  drop_down: "Drop Down",
  drop_off: "Drop Off",
  contactUsContant: "Need help? Drop us email and we’ll get back to you.",
  expenseOnTheGo: "Banking on the go",
  expenseOnTheGoContent: "Download the mobile app for Android or iOS",
  reviewUs: "Review us",
  review: "Review",
  visa: "VISA",
  reviewUsTitle: "Like Zenus Product?",
  reviewUsDecription:
    "If yes, why not tell the world about it? Review us and make our day!",
  reviewUsButton: "Write a Review",
  spendHistory: "Spend History",
  searchEngine: "Search Engine",
  direct: "Direct",
  search_text: "Search Text",
  email: "Email",
  unionAds: "Union ads",
  videoAds: "Video ads",
  overviewOfAccounts: "Overview of Accounts",
  administrator: "Administrator",
  addFilters: "Add Filters",
  addNew: "Add New",
  delete_grade: "Delete Department & Grades",
  add_new_customer: "Add New Customer",
  cust: "Customer",
  expensesId: "Expense ID",
  expense: "Expense",
  dateAndTime: "Date and Time",
  merchant: "Merchant",
  category: "Category",
  category_name: "Category Name",
  categories: "Categories",
  general_ledger: "GENERAL LEDGER",
  general_taxes: "General Taxes",
  general: "General",
  isReimbursable: "Is Reimbursable",
  cardRefNumber: "Card ref Number",
  refresh: "Refresh",
  status: "Status",
  create_company_payments: "Create recipient accounts payments",
  update_company_payments: "Update recipient accounts payments",

  add_new_role: "Add New Role",
  // Side Nav
  Dashboard: "Overview",
  Sales: "Sales",
  Expenses: "Expenses",
  Purchases: "Purchases",
  vendor: "Vendor",
  Cards: "Cards",
  Card: "Card",
  Transfers: "Payments",
  collect_payment_link: "Collect via payment link",
  Accounts: "Accounts",
  transfer: "Transfers",
  Analytics: "Analytics",
  Settings: "Settings",
  merchant_mcc: "Merchant MCC",
  company_accounts: "Company Accounts",
  debit_account: "Debit Account",
  accounts_desc: "Payments transferred between company accounts.",
  fund_transfer_type: "Fund Transfer Type",
  receipt_created: "Receipt Created Successfully.",
  created: "created",
  failed_receipt: "Failed to create receipt.",
  manual_payment: "Manual Payment",
  //Expenses
  duplicate_account: "Looks like a duplicate expense",
  resolve: "Resolve Now",
  search: "Search By Expense ID, Name",
  add_merchant: "Add Merchant",
  no_data: "No Data",
  attachment: "Only 5 attachments allowed.",
  attachments: "Attachments",
  delete: "Delete",
  cancel: "Cancel",
  are_u_sure: "Are you sure you want to delete?",
  convert_sure: "Are you sure, you want to convert to a",
  alert: "Alert",
  ocr_documents: "Show OCR Documents",
  uploads: "Upload From Computer",
  new_report: "New Report",
  new: "New",
  new_user: "New User",
  contact_persons: "Contact Persons",

  enter_details: "Enter details and submit",
  enter_phone: "Enter phone number",
  new_merchant: "New Merchant",
  save_close: "Save and Close",
  add_report: "Add Report",
  close: "Close",
  closed: "Closed",
  another_line: "Add another line",

  other_documents: "Other Documents",
  gst_tan: "GST Certificate,TAN",
  send: "Send",
  submit: "Submit",
  confirm: "Confirm",
  specify_amount: "Please specify amount for Partial Approval.",
  unlink: "Unlink",
  unlink_expense: "Are you sure you want to unlink expense from this report.",
  rejection_reason: "Please specify an appropriate reason for rejection.",
  delete_reason: "Please specify an appropriate reason for deletion.",
  please_specify_reason: "Please specify an appropriate reason for",
  rejection: "rejection",
  deletion: "deletion",
  expense_unlink: "Unlink Expense",
  reject_expense: "Reject Expense",
  view_expense: "View Expense",
  no_comments: "No Comments",
  ex_id: "Expense ID",
  date_time: "Date - Time",
  payment_mode: "Payment Mode",
  payment_through: "Payment Through",
  expense_errors: "Expense Errors",
  enter_reason: "Enter Reason",
  invoice_po: "Invoice for PO-312455",
  created_date: "Created Date - 12 Feb, 2022 12.34 PM",
  creation_date: "Created Date",
  create_date_time: "Create Date & Time",
  created_by: "Created by Lokesh.ankam@abslogistic.com",
  created_history: "Created By",
  created_byy: "Created by :",
  by_submitted: "Submitted by:",
  //Reports
  role_name: "Role Name",
  role_desc: "Role description",
  select_all_write: "Select All Write",
  write: "Write",
  read: "Read",
  select_all_read: " Select All Read",
  define_permissions: "Define Permissions For this Role",
  select_employee: "Select Employee with same role",
  employee_role: "Employee with same role",
  same_bank: "Same Bank",
  batch_name: "Batch Name",
  enter_batch: "Enter Batch Name",
  enter_benificiary: "Enter Beneficiary Name",
  advance_id: "Advance ID",
  title: "Title",
  title_required: "Title is required",
  mr: "Mr",
  mrs: "Mrs",
  date: "Date",
  clear_selection: "Clear Selection",
  add: "Add",
  policy_violation: "Policy Violation Information",
  policy_name: "Policy Name",
  report_id: "Report ID",
  add_to: "Add To",
  report: "Report",

  trip_id: "Trip ID",
  my_pdf: "My PDF",
  download_text: "Download",
  trip_name: "Trip name",
  comments_required: "Comments are required",
  download_pdf: "Download PDF",
  download_file: "Download file",
  report_details: "Report Details",
  report_desc: "See your report details here.",
  account_Details: "See your account details here.",
  adavnce_see: "See your advance details here.",
  share_report: "Share Report",
  forward_approval: "Forward Approval",
  warning: "Warning",
  select_actions:
    "Define the actions that you want to occur when the rule is applied.",
  download_receipts: "Download Receipts",
  define_the_limit: "Define the limit",
  reference: "Reference",
  reference_hash: "Reference#",
  reimbursed_on: "Reimbursed on",
  billing_to: "Billing To",
  notes: "Notes",
  total_expense_amount: "Total Expense Amount ",
  non_reimburse_amount: "Non-reimbursable Amount",
  advance_amount: "Advance Amount",
  total_advance_amount: "Total Advance Amount",
  reimburse_amount: "Advance Refunds",
  advance_details: "Advance Details",
  amount_to_reimburse: "Amount to be Reimbursed",
  reimbursed_amount: "Reimbursed Amount",
  apply_advance: "Apply Advance",
  record_reimburse: "Record Reimbursement",
  records_reimburse: "Record  Reimbursements",
  record_payment: "Record Payment",
  edit_report: "Edit Report",
  recorded_by: "Recorded By",
  purpose_title: "Purpose/Title",
  all_advances: "All Advances",
  approve: "Approve",
  destination_country: "Destination",
  is_visa_required: "Is Visa required",
  otpExpirein: "OTP expires in",
  paySec: "seconds",
  reimburse_title: "Reimbursements",
  msg: "You don’t have any details",
  enter_comment: "Enter Comment",
  view_approval: "View Approval Flow",
  submitted_to: " Submitted to :",
  max_7: "You can upload a maximum of 5 files, 7MB each.",
  submitted_by: "Submitted By:",
  link_trips: "+ Link Trips",
  msg_trip: "You don’t have any trips associated with this report",
  link_advance: "+ Link Advance",
  msg_advance: "You don’t have any advances associated with this report.",
  link_unreported_expense: "+ Link unreported expenses",
  msg_expense: "You do not have any expenses associated with this report.",
  add_unreported_expense: "Add unreported Expense",
  unreported_expense: "UnReported Expenses",
  add_new_expense: "Add new expenses",
  view_summary: "View Summary",
  summary: "Summary",
  top_expenses: "Top Expenses",
  report_total: "Report Total",
  report_title: "Report Title",
  add_bulk_expense: "Add Bulk Expenses",
  select_report: "Select Report",
  on: "on",
  to: "to",
  too: "To",
  duration: "Duration",
  more: "More",
  recall: "Recall",
  edit: "Edit",
  pending_recovery: "Pending Recovery",
  reject: "Reject",
  reimburse: "Reimburse",
  reported: "Reported",
  saved: "Saved",
  pending_reimburse: "Pending Reimbursements",
  reimbursement: "Reimbursement",
  transit: "Transit",
  manage_beneficiary: "Manage Beneficiary",
  reimbursed: "Reimbursed",
  mode_reimbursement: "Mode of Reimbursement",
  wallet_type: "Wallet Type",
  adv: "Advance",
  advance_refunds: "Advance Refunds",
  add_another_field: "Add Another Field",
  receiver_name: "Receiver Name",
  setup_payroll: "Setup Payroll",
  receiver_required: "Receiver Name is required.",
  pan_number_required: "PAN number is required.",
  payee_name: "Payee Name",
  payee_name_required: "Payee Name is required",
  ifsc_code_required: "BIC Code is required",
  transaction_date_required: "Transaction Date is required",
  manual_reimbursement: "Manual Reimbursement",
  receive_advance: "Have you received the remaining advance balance",
  carry_balance: "No, the user can carry forward the provided balance",
  received_on: "Received On",
  total_advance_refunds: "Total refunds in advance",
  bulk_msg: "Are you sure you want to bulk",
  export_csv: "Export CSV",
  export_to_Excel: "Export To Excel",
  excel_export: "Excel Export",
  export_pdf: "Export PDF",
  meal: "Meal",
  print: "Print",
  fuel: "Fuel",
  bulk_action: "Bulk Action",
  unlink_advance: "Are you sure you want to unlink advance from this report",
  comments: "Comments",
  unlink_advances: "Unlink Advance",
  trips_link: "Link Trips",
  add_expenses: "Add Unreported Expenses",
  expense_add: "Add Expenses",

  pending_approval: "Pending approval",
  approval_pending: "Pending Approval",
  convert_to_inv: "Convert To Invoice",
  pending_reimbursement: "Pending Reimbursement",
  po_32: " Invoice for PO-312455",
  decline: "Declined",
  all_reports: "All Reports",
  un_submitted: "Unsubmitted",
  new_advance: "New Advance",
  partillay_paid: "Partially Paid",
  approved: "Approved",
  recovered: "Recovered",
  new_expense: "New Expense",
  delete_Report: "Are you sure you want to delete report ?",
  unlink_trip: "Are you sure you want to unlink trip from this report.",
  send_report_email: "Sent report to email",
  flight_type: "Flight Type",
  unlink_trips: "Unlink Trip",

  meal_preference: "Meal Preference :",
  seat_preference: "Seat Preference :",
  preference_seat: "Seat Preference",
  time_preference: "Time Preferences :",
  departure_time: "Departure Time :",
  time_departure: "Departure Time",
  departure: "Departure",
  return_time: "Return Time :",
  return_date: "Return Date",
  one_way: "One Way",
  round_trip: "Round Trip",
  multi_city: "Multi-City",
  visiting_city: "Visiting City",
  window: "Window",
  middle: "Middle",
  middle_name: "Middle Name",
  first_name: "First Name",
  ship_address: "Shipping Address - Copy same Address",
  state: "State",
  select_state: "Select State",
  bank_account_Details: "Bank Details not found",
  save: "Save",
  veg: "Veg",

  aisle: "Aisle",
  non_veg: "Non Veg",
  yes: "Yes",
  city: "City",
  no: "No",
  medium: "Medium",
  small: "Small",
  enter_desc: "Enter Description",
  schedule_payment: "Schedule Payment",
  debit_error_msg: "Debit account number is required",
  morning: "Morning",
  before_11: "Before 11AM",
  BEFORE: "BEFORE",
  AFTER: "AFTER",
  afternoon: "Afternoon",
  evening: "Evening",
  night: "Night",
  ola: "Ola",
  uber: "Uber",
  rapido: "Rapido",
  delete_role: "Delete Role",
  delete_organization: "Delete Organisation",
  public_transport: "Public Transport",
  private_transport: "Private Transport",
  others: "Others",
  add_flight_details: "Add Flight Details",
  flight_mode: "Flight Mode:",
  mode: "Mode",
  reject_report: "Reject Report",
  add_expense_report: "Add Expenses to Report",
  add_trip_report: "Add Trip to Report",
  trip_title: "Trip Title",
  add_adavnce_report: "Add Advance to report",
  reject_advance: "Reject Advance",
  delete_advance: "Delete Advance",

  add_bus_details: "Add Bus Details",
  add_trina_details: "Add Train Details",
  flight_reservation: "Flight Reservations",
  hotel_reservation: "Hotel Reservations",
  add_hotel_details: "Add Hotel Details",
  car_rental: "Car Rentals",
  new_trip_request: "New Trip Request",
  edit_trip: "Edit Trip",
  business_purpose: "Business Purpose",
  purpose: "Purpose",
  destination: "Destination",
  mark_as_close: "Mark As Close",
  mark_as_accept: "Mark as Accepted",
  mark_as_declined: "Mark as Declined",
  edit_other_booking: "Edit Other Bookings",
  add_other_booking: "Add Other Bookings",
  add_other_details: "Add Other Details",
  other_bookings: "Other Bookings",
  trip_iteniry: "Trip Itinerary",
  unsubmitted_estimates: "unsubmitted estimates",
  add_car_details: "Add Car Details",
  car_rental_details: "Add Car Rental Details",
  other_bank: "Other Banks",
  add_option: "ADD OPTION",
  inter_swift: "International Swift",
  block_msg: "Blocking or Warning any one should be selected",
  blocking: "Blocking",
  optional: "Optional",
  bus: "Bus",
  train: "Train",
  employee_name: "Employee Name",
  employee_mobile: "Employee Mobile",
  departure_date: "Departure Date",
  To: "To",
  depart_from: "Depart From",
  From: "From",
  travel_mode: "Travel Mode",
  pick_up: "Pick Up",
  arrival_date: "Arrival Date",
  arrived_at: "Arrived At",
  hotel_name: "Hotel Name",
  desc_required: "Description from is required",
  location: "Location",
  cab_type: "Cab Type",
  search_by_report: "Search By Report ID,Name",
  search_by_advance: "Search By Advance ID, Title",
  search_by_trip: "Search By Trip ID, Name",
  add_to_report: "Add To Report",
  paid_through: "Paid Through",
  associated_reports: "Associated Reports",
  trip_type: "Trip Type",
  from_date: "From Date",
  to_date: "To Date",
  advance_total: "Advance Amount",
  edit_fields: "Edit Advance",
  assign_cards: "Assign Cards",
  receipt_creation: "Receipt created Successfully",
  receipt_failed: "Receipt Creation failed",
  incorrect_due:
    "Note:  Forgo is not responsible for any payments made due to incorrect Bank details.",
  benificiery_details: "Recipient Details",
  benificiery_account: "Recipient Accounts",
  payments_transferred:
    "Payments transferred between company recipient accounts.",
  manage_beneficial: "Manage Recipient",
  manage_branches: "Manage Branches",
  manage_organisation: "Manage Organisation",
  beneficiary_name_required: "Recipient Name is required",
  see_branches: "See your branches",
  see_organisation: "See your organisation",
  account_number_required: "Account Number Required",
  name: "Name",
  ac_num: "A/c Number",
  run_payroll: "Run payroll in minutes",
  run_payroll_many:
    "Run payroll as many times as you need each month for employees, consultants, contractors, and the self-employed.Your team gets paid with just a few clicks.",
  access_payroll: "Access Payroll",
  payroll: "Payroll",
  manage_pay: "Manage & pay all your utility bills on single dashboard.",
  single_line: "Single Line",
  default_value: "Default Value",
  manage_payroll: "Manage & process payroll.",
  payroll_preference: "Payroll preferences & Settings",
  view_shared_bills: "View bills shared as other employees",
  add_forgo:
    "Add Forgo as a new beneficiary in your current account using the account information given below.",
  account_code: "Account Code",
  account_num: "Account Number",
  gift: "Gift",
  books_periodicals: "Books & Periodicals",
  create_manage_benificiary: "Create Recipient",
  shared_bill: "View bills shared as other employees",
  how_it: "How it works",
  account_type: "Account Type",
  account_transactions: "Account Transactions",
  primary_accounts: "Primary Accounts",
  bank_name: "Bank Name",
  branch: "Branch",
  add_more_details: "Add More Details",
  branch_address: "Branch Address",
  Organisation_address: "Organisation Address",
  organisation_name: "Organisation Name",
  transfer_funds:
    "  Transfer funds from your current account to Forgo account via NEFT/RTGS /IMPS information given below.",
  view_account_details: "View Account Details",
  see_ur_approved: "See your approved transactions here",
  see_ur_txns: "See your transactions here",
  load_money: "Load Money in to Funding Account",
  master_pool: "Master Pool Account",
  transaction_history: "Transaction History",
  company_master_account: "Company Master Account",
  history: "History",
  bulk_transfer: "Bulk transfers",
  success_bulk_payments: "Successfully initiated  Bulk Payments",
  bulk_payment_links: "Bulk Payment Links",
  execute_payments: "Execute multiple payments through single process.",
  access_transfer: "Access status and info of all the bulk transfers.",
  get_paid: "GET PAID",
  all_trans: "All Transactions",
  my_trans: "My Transactions",
  advance_search: "Advance Search",
  searchh: "Search",
  all_trips: "All Trips",
  actions: "Actions",
  delete_user: "Delete User",
  uplaod_users: "Upload Users",
  map_files: "Map Files",
  select_file: "Select File",
  my_trips: "My Trips",
  payment_upload: "Upload payments",
  upload_payment_links: "Upload Payment Links",
  add_fund: "Add Funds",
  trans_id: "Transaction ID",
  upi_id: "UPI Id",
  upi_id_required: "UPI ID is required.",
  transaction_required: "Transaction Id is required",
  select_grade_level: "Select Grade/Level",
  select_designation: "Select Designation",
  term_id: "Terminal ID",
  connect_bank: " Connecting to your bank",
  export_reports: "Export Reports",
  ac_balance: "A/c Balance",
  sort_by: "SORT BY",
  by_sort: "Sort By",
  no_filters: "No Filters Applied",
  no_pdf_found: "No PDF Found",
  action: "Action",
  search_status: "Search by ID, name and status",
  cost_center: "Cost Center",
  project: "Project",
  projects: "Projects",
  enter_bank_name: "Enter Bank Name",
  snap: "Snapshots",
  search_by_transaction_id: "Search By Transaction ID, Name",
  expense_reports: "Expense Reports",
  expense_should_Empty: "Expense Limit should not be empty",
  payment_should_Empty: "Amount fields should not be empty",
  card_limit_msg: "Card Limit should not be empty",
  card_transactions: "Card Transactions",
  estimate: "Estimate",
  est: "Estimate",
  sale_order: "Sales Orders",
  Est_mate: "estimate",
  ESTIMATE: "ESTIMATE",
  order_sale: "Sales Order",
  sales_orders: "sales order",
  purchase_ord: "Purchase order",
  received_payment: "Payment Received",
  updated_success: "Updated succesfully",
  inventory: "Inventory",
  sales_no: "Sales No",
  sales_hash: "Sales#",

  payment_receive: "Payments Received",
  aging_invoice: "Invoice Aging",
  purchases_orders: "Purchases Orders",
  mode_payments: "Payments Mode",

  bills_aging: "Bills Aging",
  customer_name: "Customer Name",
  vendor_name: "Vendor Name",
  customer_notes: "Customer Notes",
  enter_customer_name: "Enter Customer Name",
  enter_current_pwd: "Enter Current Password",
  customer_name_required: "Customer Name is Required",
  bill_no: "Bill No",
  no_bill: "Bill Number",
  bill_date: "Bill Date",
  reference_no: "Reference No",
  add_more_contact: "Add More Contact",
  payment_details: "Payment Details",
  payment_term: "Payment Terms",
  payment_add_terms: "Add Payment Terms",
  term_list: "Term Name is required",
  enter_term_name: "Enter Term Name",

  number_of_days: "Number of days*",
  term_name: "Term Name*",
  payment_date: "Payment Date",
  select_payment_term: "Please select payment terms",
  payment_due_date: "Payment Due Date",
  please_select_value: "Please select value",
  select_vendor: "Select a vendor",
  tax_amount: "Tax Amount",

  discount: "Discount",
  tds_amount: "TDS Amount",
  total_amount: "Total Amount",
  payment_pending: "Payment pending",
  aging: "Aging",
  currency_code: "Currency Code",
  currency: "Currency",
  merchants: "Merchants",
  code: "Code",
  // Analytics
  employee_id: "Employee ID",
  card_ref_no: "Card Ref No",
  transaction_date: "Transaction Date",
  merchant_name: "Merchant Name",
  select_city: "Select City",
  less_amount: "Please enter amount less than or equal to due amount.",
  select_credit_account: "Select Credit Account",
  select_debit_account: "Select Debit Account",
  enter_merchant_name: "Enter Merchant Name",
  file_size: "File size must be less than 1 MB.",
  five_files: "Only 5 files allowed",
  enter_merchant_code: "Enter Merchant Code",
  merchant_code: "Merchant Code",
  transaction_amount: "Transaction Amount",
  wallet_name: "Wallet Name",
  remarks: "Remarks",
  added_date: "Added Date",
  no_of_cards: "No...of Cards",
  number_of_cards: "No of cards",
  available_cards: "Available cards in inventory - 10,430",
  loaded_amount: "Loaded Amount",
  transaction_type: "Transaction Type",
  approval_status: "Approval Status",
  estimate_no: "Estimate No",
  estimate_date: "Estimate Date",
  expiry_date: "Expiry Date",
  invoice_date: "Invoice Date",
  march_24: "24 March 2022",
  invoice_type: "Invoice Type",
  invoice_balance: "Invoice Balance",
  invoice_balance_due: "Invoice Balance Due",
  due_date: "Due Date",
  gl_code: "GL Code",
  invoice: "Invoice",
  reject_msg: "Do you want to reject this",
  invoice_no: "Invoice No",
  due_balance_amount: "Due Balance Amount",
  number_invoice: "Invoice Number",
  invoice_hash: "Invoice Number#",
  enter_invoice_no: "Enter Invoice Number",
  expected_delivery_date: "Expected Delivery Date",
  import_purchase_orders: "Import Purchase Orders",
  delivery_date: "Delivery Date",
  purchase_order_no: "Purchases Order No",
  export_purchase_orders: "Export Purchase Orders",
  purchase: "PURCHASE_ORDER",
  purchase_order_date: "Purchase Order Date",
  purchase_order: "Purchase Order",
  invoice_module: "Inovice Module",
  order_purchase: "Purchase Order#",
  purchase_date: "Purchase Date",
  order_salee: "Sales Order#",
  sales_order_no: "Sales Order No",
  sales_order_date: "Sales Order Date",
  SALES_ORDER: "SALES_ORDER",
  sales_ord: "sales order",
  expected_shipment_date: "Expected Shipment Date",
  shipment_date: "Shipment Date",
  top_spending_users: "Top Spendign Users",
  view_details: "View Details",
  virtual_Accounts: "Virtual Accounts",
  primary_Accounts: "Primary Accounts",
  corporate_id: "Corporate ID",
  swift_bic: "Swift/BIC",

  top_vendors: "Top Vendors",
  vendors: "Vendors",
  utility_bill: "Utility Bills",
  bill_payments: "Bill Payments. Simplified.",
  add_vendor: "Add vendors that you deal with in your business.",
  customers: "Customers",
  bill_no_hash: "Bill No#",
  last_name: "Last Name",
  advance_approver: "Advance Approvals",
  can_perform: "Can perform action on all",
  report_approvals: "Report Approvals",
  trip_approval: "Trip Approvals",
  modules: "Modules",
  last_name_required: "Last name is required",
  top_violations: "Top Violations",
  top_categories: "Top Categories",
  confirm_email: "Confirm Email",
  dont_have: "Don't have an account yet? Click here to",
  register: "Register",
  new_pwd: "New Password",
  set_pwd: "Set Password",
  enter_new_pwd: "Enter New Password",
  create_new:
    " Please create a new password below. Your new password needs to have a minimum of 8 characters.",
  unable_to_change: "Unable to change password? Contact",
  support: "Support",
  connect_ur_bank: "Connecting your bank...",
  change_pwd: "Change Password",
  change: "Change",
  po_number: "PO Number",
  so_number: "SO Number",
  to_city: "To City",
  bill_hash: "Bill#",
  purchase_hash: "Purchase#",
  purchase_no: "Purchase No",
  pay_bill:
    "Manage & Pay All Bills across your Business Locations using One Single Dashboard.Bill details like due dates and amounts will be automatically fetched for simple tracking and making payments.",
  enter_email: "Enter Email",
  email_add: " Enter Email Address",
  enter_email_address: "Enter email addresses",
  no_attachments_found: "No attachments found",

  enter_valid_Email: "Enter Valid Email",
  valid_email: "The input is not valid E-mail!",
  enter_vendor_name: "Enter Vendor Name",
  enter_pwd: "Enter Password",
  confirm_new_pwd: "Confirm New Password",
  by_click:
    "By clicking on “Book your demo” you agree to be contacted by Paycraft to receive information about the Demo.",
  have_an_account: "Have an account ? Click here to",
  login: "Login",
  reset_ur_password: "Reset your password",
  current_pwd: "Current Password",
  set_ur_pwd: "Set Your Password",
  enter_confirm_new_pwd:"Enter Confirm new password",
  please_create_new_pwd:
    " Please create a new password below. Your new password needs to have a minimum of 8 characters",
  check_out: "Check Out",
  check_in: "Check In",
  check_inbox: "Check your inbox, we just sent you an email at",
  check_spam:
    "Please check your junk or spam folder. The link is valid for 24 hours you can use it to reset your password.",
  email_link: "We will send you an email with a link to reset your password",
  back_to_login: "Back To Login",
  search_by_ref: "Search By Card Ref No, Name",
  search_by_name: "Search By Name",
  send_email: "Send Email",
  clone: "Clone",
  card_number: "Card Number",
  sight_demand_draft: "Sight Demand Draft",
  time_demand_draft: "Time Demand Draft",
  kyc_status: "KYC Status",
  reason: "Reason",
  reason_reject: "Reject Reason",
  select_reason: "Please select reason",
  set_modify_pin: "Set / Modify PIN",
  select_role: "Select Role",
  lock_status: "Lock Status",
  activated_on: "Activated On",
  card_policy: "Card Policy",
  block_card: "Are you sure you want to block the card ?",
  card_block: "Block Card",
  card_balalnce: "Card Balances",
  card_type: "Card Type",
  mobile_number: "Mobile Number",
  please_input: "Please input valid phone number!",
  mobile_no: "Mobile No",
  create_date: "Create Date & Time",
  link_id: "Link ID",
  card_holder_name: "Card Holder Name",
  update_mobile: "Update Mobile Number",
  activate_card: "Activate Card",
  activate: "Activate",
  deactivate: "DeActivate",
  replace_card: "Replace Card",
  request_replacement:
    " Request for a replacement card is placed successfully.",
  update: "Update",
  browse: "Browse",
  browser_popup:
    "Note:- Browser popup permission should be enabled for the redirection.",
  dont_continue: "If you don't want to continue, Press Cancel.",
  otp_verification:
    "You are leaving the Paycraft application and being redirected to the Activate Card webpage after OTP is verification.",
  otp_verify: "Verfiy OTP",
  update_mobile_number: "Are you sure you want to update mobile number ?",
  replace: "Replace",
  request_new_card: "Are you sure you want to request for a new card ? ",
  continue: "Continue",
  card_set_pin:
    "You are leaving the Forgo application and being redirected to the Card Set PIN webpage.Please click on proceed to confirm.",
  set_pin: "Set Pin",
  block: "Block",
  company_name: "Company Name",
  select_user_assign: "Select user and assign",
  transactions: "Transactions",
  available_balance: "Available Balance",
  card_limit: "Card Limits",
  full_kyc: "Full KYC",
  complete: "Complete",
  card_kit_number: "Card Kit Number",
  have_document_kyc: "Have the documents handy now? Click on full KYC.",
  dont_have_kyc:
    "Don’t have your KYC documents with you? Complete Minimum KYC right away!",
  address_proof:
    "Address Proof : Aadhaar card, Driving License, Voter ID,Passport",
  identity_proof:
    "Identity Proof : Aadhaar Card, PAN Card, Driving License, VoterID, Passport",
  photo_assist: "Photo : Self Attested Photo",
  arrange_kyc: "Following are the documents required to be arranged for KYC",
  no_atm:
    "No ATM transactions are possible without Full KYC and your transactions can’t exceed 10,000 INR.",
  documents_verified: "Documents verified",
  kyc_verify: "KYC Verification",
  range: "Range",
  min_amount: "Min Amount",
  max_amount: "Max Amount",
  is_lessthan: "Is Lessthan",
  is_greaterthan: "Is Greaterthan",
  phone: "Phone",
  created_at: "Created At",
  create: "Create",
  card_holder_email: "Card Holder Email",
  initiate_transfer_request:
    "Please make sure you have enough available balance before initiating the Transfer request.",
  use_funds: "Note: This transfer will use funds from the corporate budget.",
  csv_edit: "You can edit information in the file (.csv) and upload  the same.",
  upload_ur_file: "Upload your file",
  upload_ur_company_logo: "Upload Your Company Logo",
  upload_ur_organisation_logo: "Upload Your Organisation Logo",
  company_address: "Company Address",
  connect_to_bank: "Connect To Your Bank",
  sample_file:
    "Sample file will download the active cards information along with wallet TopUp fields",
  beneficiary_name: "Recipient Name",
  active: "Active",
  in_active: "Inactive",
  phone_no: "Phone No",
  message: "Message",
  status_file:
    "is initiated. Status file will be shared on email once the process is completed.",
  add_new_vendor: "Add New Vendor",
  add_new_branch: "Add New Branch",
  add_new_organisation: "Add New Organisation",
  ifsc: "IFSC",
  pay_via_corporate: "Pay Via Corporate Budget",
  rate: "Rate",
  ifsc_code: "BIC Code",
  corporate: "Corporate",
  failed_add_accounts: "Failed to Add Bank Accounts",
  last_updated_at: "Last Updated At",
  bank_account_added: "Bank Accounts Added.",
  bank_required: "Bank Name Required",
  new_purchase: "New Purchase Order",
  edit_purchase: "Edit Purchase Order",
  enter_ifsc: "Enter BIC Code",
  enter_employee_id: "Enter Employee ID",
  any_queries:
    "For any further queries on your account, reach out to care@ippopay.in",
  working_hours:
    " Load requests raised from your current accounts between 9 AM to 5:30PM on bank working days will be processed on the same day. Allrequests raised after the stipulated time will reflect only on the next bank working day as per the bank working hours",
  no_account: "Account Number",
  number_account: "Account No",
  add_bill: "Add Bill",
  start_with: "Start with",
  input_value: "Please input value!",
  prefix: "Prefix",
  BIL: "BILL",
  bill_preference: "Bill payment preferences and settings",
  preferences: "Preferences",
  set_approvals: "Setup Approvals",
  apprvolas: "Approvals",
  approve_invoice: "An action of approving/rejecting the Invoices",
  approve_estimates: "An action of approving/rejecting the Estimates",
  approve_sales_orders: "An action of approving/rejecting the Sales Orders",
  approve_bills: "An action of approving/rejecting the Bills",
  approve_purchase: "An action of approving/rejecting the Purchase Orders",
  my_invoices: "My Invoices",
  my_reports: "My Reports",
  my_bills: "My Bills",
  my_purchases: "My Purchases",
  credit_notes: "Credit Notes",
  note_credit: "Credit Note",
  credit_Card: "Credit Card",
  debit_Card: "Debit Card",
  credit_note_date: "Credit Note Date",
  my_sales: "My Sales",
  my_estimates: "My Estimates",
  bank_country: "Bank country",
  from_account: "From Account",
  file_name: "File Name",
  profile_updated_success: "Profile updated succesfully",
  country: "Country",
  add_customer: "Add customers that you deal with in your business.",
  count: "Count",

  country_region: "Country /Region",
  schedule_date: "Schedule date and time",
  bank_account_num: "Bank Account No",
  bank_num: "Bank A/C No",
  account_typo: "A/c Type",
  payment_amount: "Payment Amount",
  amount_paid: "Amount Paid",
  upload_excel: " Click to Upload Excel File",
  transfer_transaction:
    " Note: On the scheduled transfer date the transaction will get initiated.",
  download_samples: "Download Samples",
  new_transaction: "New Transaction",
  new_bill: "New Bill",
  edit_bill: "Edit Bill",
  download_beneficiaries: "Download Beneficiaries",
  plain_template: "Note: Sample file are the plain template",
  csv_template: `Note: Download the CSV template by selecting the checkbox and clicking 'Export to CSV'.`,
  without_employee: "with out the employee data",
  download_sample_form: "Download Sample Template",
  beneficiary_id: "Beneficiary's name and ID",
  to_account: "To  Account",
  add_one_more: "+ Add one more",
  back: "Back",
  no_approval: "No Approval",
  make_payment: "Make Payment",
  payment_links: "Payment Links",
  create_sale_order: "Create sales order and convert to invoice directly.",
  convert_to_invoice: "Convert to Invoice",
  create_estimate_order: "Create estimate and convert to sales order directly.",
  create_purchase_order: "Create purchase order and convert to bills directly.",
  purchase_orders: "Purchase Orders",
  convert: "Convert",
  convert_to: "Convert to",
  mark_as_confirmed: " Mark as Confirmed",
  convert_to_Sale_order: "Convert to Sales Order",
  create_payments: "Create bills and make payments directly",
  hierarchy_approval: "Hierarchy Approval",
  create_payment: "Create Payments",
  link_Sent_phone: "Payment link Will be sent to the phone number and email ID",
  payment_create: "Create Payment",
  create_payment_links: "Create Payment Links",
  link_create: "Payment Link Created",
  created_estimates: "Estimate Created Successfully",
  updated_estimates: "Estimate Updated Successfully",
  deleted_estimates: "Estimate Deleted Successfully",
  created_sale_order: "Sales Order Created Successfully",
  updated_sale_order: "Sales Order Updated Successfully",
  deleted_sale_order: "Sales Order Deleted Successfully",
  created_invoice: "Invoice Created Successfully",
  updated_invoice: "Invoice Updated Successfully",
  deleted_invoice: "Invoice Deleted Successfully",
  approval_flow:
    "Approval flow will be based on the approvers configured in the Submits To, Approves and Forwards To fields of the users",
  approval_types: "Approval Types",
  customer_Details: "Customer Details",
  customer_space: "Customer name can only contain letters, dots or spaces.",
  customer_create: "Customer Created Successfully",
  customer_update: "Customer Updated Successfully",
  details: "Details",
  created_success: "Created Successfully",
  save_submit: "Created and Submitted Successfully",
  copy_link: "Copy Link",
  customer_info: "Customer Info",
  customer_info_required: "Customer Info Required",
  new_sales: "New Sales Order",
  new_estimates: "New Estimate",
  new_invoice: " New Invoice",
  edit_sales: "Edit Sales Order",
  edit_estimates: "Edit Estimate",
  edit_invoice: " Edit Invoice",
  create_submitted_estimates: "Estimate Created and Submitted Successfully",
  create_invoice: "Created Invoice",
  create_submit_sale_order: "Sales Order Created and Submitted Successfully ",
  create_submit_invoice: "Invoice Created and Submitted Successfully",
  new_credit_note: "New Credit Note",
  edit_credit_note: "Edit Credit Note",
  payment_for: "Payment For",
  main_category: "Main Category",
  category_hash: "Category#",
  sub_category: "Sub-Category",
  approvers: "Approvers",
  apply_credits: "Apply Credits",
  amount_to_credit: "Amount to Credit",
  polocies_limits: "Policies & Limits",
  roles_permissions: "Roles & Permissions",

  parent_category:
    "Note : The policies of parent category will be applicable to subcategory,if you wish to change you can always edit in the subcategories under categories section",
  enter_category_code: "Enter Category Code",
  category_code: "Enter Category Code",
  enter_pd: "Enter Password",
  select_category: "Select Parent Category",
  category_parent: "Parent Category",
  delete_payment: "Are you sure you want to delete this category?",
  delete_field: "Delete Field",
  number: "Number",
  estimates_field_name: "Estimates Field Name",
  field_name: "Field Name",
  show_in_pdf: "Show in all PDF",
  is_mandatory: "Is Mandatory",
  options: "Options",
  new_field: "New Field",
  remove: "Remove",
  reminder_alert:
    "Reminder alert to employees if the advance amount is pending for more than",
  days_submission: "Days For Submission",
  reminder_trip: "Reminder alert to ",
  more_than: "is pending for more than",

  notifications: "Notifications",
  label_name: "Label Name",
  enter: "Enter",
  me: "CREATED BY",
  customer_and_me: "CUSTOMER & CREATED BY",
  CUSTOMER: "CUSTOMER",
  auto_generate: "Auto-generate Advance ID",
  enter_cost_center: "Enter Cost Center Name",
  cost_center_name: "Cost Center Name",
  enter_cost_center_code: "Enter Cost Center Code",
  cost_center_code: "Cost Center Code",
  select_cost_head: "Select Cost Center Head",
  cost_head: "Cost Center Head",
  edit_cost_center: "Edit Cost Center",
  select_cost_center: "Select Cost center",
  delete_cost_center:
    "If you delete this Cost Center, the users will not no longer be associated to this Cost Center. Are you sure you want to delete it?",
  delete_cost: "Delete Cost Center",
  apply_expense: "Apply for specific expense categories",
  for: "For",
  days: "days",
  reminder_expense: " Send reminders for unreported expenses for more than",
  auto_generate_expense: "Auto-generate Expense ID",
  delete_sure_field: "Are you sure you want to delete this field?",
  organization_profile: "Organization Profile",
  see_organization: "See your organization details here",
  see_company: "See your company details here",
  org: "Organisation",
  company_profile: "Company Profile",
  mandatory: "Mandatory",
  reminder_deleted: "Reminder Deleted Successfully",
  deleted_success: "Deleted successfully",
  pdf_show: "Show in PDF",
  enable: "Enable",
  edit_project: "Edit Project",
  new_project: "New Project",
  add_fields: "Add Fields",
  fields: "Fields",
  enter_tax_name: "Enter Tax Name",
  rate_hash: "Rate#",
  tax_name: "Tax Name",
  enter_rate: "Enter Rate",
  tax_hash: "Tax Name#",
  tax: "Tax",
  enter_val: "Enter Value",
  value: "Value",
  enter_valid_number: "Enter Valid Number",
  vendor_details: "Vendor Details",
  inv_inf: "INVOICE INFO",
  enter_valid_company: "Please enter valid company name",

  phone_Required: "Phone Number is required.",
  wallet_service: "Wallet Service Provider",
  wallet_service_required: "Wallet Service Provider is required",
  please_enter_category_name: "Please enter category name",
  email_required: "Email  is Required",
  please_enter_name: "Please enter name",
  advances_field_name: "Advances Field Name",
  edited: "Edited",
  edit_category: "Edit Category",
  new_category: "New Category",
  edit_grade: "Edit Grade",
  new_grade: "New Grade",
  val: "Value",
  unit_price: "Unit Price",
  tax_name_required: "Tax name is required",

  enter_unit_price: "Enter Unit Price",
  enter_hsn_code: "Enter HSN Code",
  hsn_code: "HSN Code",
  enter_sku: "Enter SKU",
  stock_sku: "SKU (Stock Keeping Unit)",
  sku_required: "SKU is required",
  hsn_code_required: "HSN Code is required",
  hsn_codes: "HSN Code (Harmonized System of Nomenclature)",
  unit_price_required: "Unit Price Required",
  enter_item_name: "Enter Item Name",
  item_name_required: "Item Name is required",
  item_name: "Item Name",
  item_type: "Item Type",
  goods: "Goods",
  services: "Services",
  items: "Items",
  taxes: "Taxes",
  module_name: "Module Name",

  enter_project_name: "Enter Projects Name",
  projects_name: "Projects Name",
  enter_project_code: "Enter Projects Code",
  projects_code: "Projects Code",
  users: "Users",
  users_and_controls: "Users & Controls",
  roles: "Roles",
  permissions: "Permissions",
  grades: "Grades",
  grade_options: "Grade/Level Options should not be empty.",
  destinations: "Designations",
  select_project_head: "Select Projects Head",
  project_head: "Projects Head",
  no_longer_users:
    "If you delete this Projects, the users will not no longer be associated to this Projects. Are you sure you want to delete it?",
  delete_project: "Delete Project",
  select_project: "Select Project",
  delete_trip: "Delete Trip",
  reject_trip: "Reject Trip",
  edit_marchant: "Edit Merchant",
  select_column_type: "Select Column Type",
  column_type: "Column Type",
  edit_custom_field: "Edit Custom Field",
  field: "Field Name",
  preference: "Preferences",
  new_custom_field: "New Custom Field",
  new_cost_center: "New Cost Center",
  delete_custom_field: "Delete Custom Field",
  auto_generate_report: "Auto-generate Report ID",
  expense_report_submission: "On expense report submission",
  notify_approver: " Notify approver via email when reports are submitted",
  approval_notifications: "Approval Notifications",
  reminder_expense_reports:
    "Reminder alert to approvers if the expense reports are pending for more than",
  auto_generate_msg: "Auto-generate",
  id_msg: "ID",
  reminder_alert_reports: "Reminder alert to",
  reminder: "Reminder",
  branch_register_no: "Branch Registration No",
  Organisation_register_no: "Organisation Registration No",
  primary_email: "Primary Company Email",
  branch_name: "Branch Name",
  organisation_ids: "Organisation Ids",
  card_endor: "Card Vendor",
  mo_id: "MO Id",
  virtual_id: "Virtual ID",
  outlet_id: "Outlet ID",
  fiscal_year: "Fiscal year",
  regional_ettings: "Regional Settings",
  base_currency: "Base Currency",
  bank_details: "Bank Details",
  account_ifsc: "Account IFSC",
  account_name: "Account name",
  account_number: "Account Number",
  bank_branch: "Bank branch",
  bank_address: "Bank address",
  password: "Password",
  confirm_password: "Confirm Password",
  auto_password: "Automatically Generate Password",
  create_password: "Create Password",
  auto_password_sub: "Email will be sent to the client to set the password.",
  enter_address: "Enter Address",
  enter_number: "Enter Account Number",
  enter_num: "Enter Number",
  enter_number_days: "Enter Number of days",
  address: "Address",
  street: "Street",
  all_expenses: "All Expenses",
  drag_png: "Drag here to upload JPG/PNG",
  drag_change: "Drag here to change attachment",
  drag_upload: "Drag here to upload JPG/PNG/PDF",
  click_here_to: "Or click here to attach",
  click_here_to_change: "Or click here to change attachment",
  preferred_image:
    " Preferred Image Size: 240px x 240px @ 72 DPI Maximum size of 1MB.",
  select_country: "Select Country",
  pincode: "Pincode",
  office_number: "Office Number",
  gstin_no: "GSTIN No",
  gstin: "GSTIN",
  select: "Select",
  gst_treatment: "GST Treatment",
  tan_no: "TAN No",
  image: "Please upload image. Accepted formats jpeg, png and jpg",
  upload_company_documents: "Upload Your Company Documents",
  upload_organisation_documents: "Upload Your Organisation Documents",
  provide_grade: "Please provide grade name",
  accept: "Accepted",
  certificate_incorporation: "Certificate of Incorporation",
  primary_contact: "Primary Contact",
  primary_email: "Primary email",
  org_primary_email: "Organisation Primary email",
  org_contact_email: "Primary Contact email",
  contact_name: "Contact Name",
  primary_contact_number: "Primary Contact Number",
  primary_contact_email: "Primary Contact Email",
  primary_contact_email_Address: "Primary Contact Email Address",
  primary_contact_name: "Primary Contact Name",
  contact_number_required: "Contact Number Required",
  middle_name_required: "Middle name is required",
  first_name_required: "First Name is Required",
  salution: "Salutation",
  primary: "Primary",

  upload_company_log: "Upload Company Logo",
  default_policy: "Default Policy",
  atm: "ATM",
  pan_no: "PAN number",
  cheque_date_required: "Cheque Date is Required",
  dd_number: "DD Number",
  dd_type_required: "DD Type Required",
  cheque_date: "Cheque Date",
  cheque_no: "Cheque Number",
  dd_type: "DD Type",
  dd_number_required: "DD Number required",
  enter_pan: "Enter PAN",
  gst_uin: "GSTIN / UIN#",
  enter_gst_uin: "Enter GSTIN / UIN",
  select_currency: "Select Currency",
  pos: "POS",
  po: "PO",
  online: "Online",
  contact_less: "Contact Less",
  daily_limit: "Daily Limit",
  monthly_limit: "Monthly Limit",
  yearly_limit: "Yearly Limit",
  when: "When",
  card_type_required: "Card Type is required.",
  product_title: "Product Title:",
  product_price: "Product Price:",
  product_rating: "Product Rating:",
  product_desc: "Product Description:",
  bank_name_required: "Bank Name is required",
  card_number_required: "Card Number is required.",
  online_transactions: "Online Transactions",
  enable_online_transactions: "Enable Online transactions",
  atm_withdraw: "ATM withdrawal",
  pos_trans: "POS Transactions",
  enable_pos_trans: "Enable POS Transactions",
  card_usage_controls: "Card Usage Controls",
  if_any: "If any",
  create_limits: "Create Limits For this rule.",
  specific_grade: "Specific Grade/Departments",
  specific_user: "Specific user",
  rule_will: "Rule will apply to",
  choose_who_will: "Choose who will be affected by this rule.",
  define_users: "Define Users",
  define_conditions: "Define Conditions for this policy",
  desc: "Description",
  custom: "Customisation",
  configure_expense:
    "Configure the fields required in expense creation forms and set up other preferences.",
  configure_reports:
    "Configure the submission and notification preferences for reports and set up report types.",
  configure_advance:
    "Customize the fields in advance requests and create custom status.",
  configure_trips:
    "Customize trip requests, create custom statuses for trips, and set up preferences.",
  customize_expense_fields:
    "Customize the fields you require in creating the estimates and set up preferences.",
  customize_invoice_fields:
    "Customize the fields you require in the invoice creation and set up preferences.",
  customize_sale_fields:
    "Customize the fields that you require in creating the sales order and set up preferences",
  purchase_order_preference:
    "Customize the fields you require in creating the Purchase Order and set up preferences.",
  bills_preference:
    "Customize the fields you require in creating the bills and set up preferences.",
  project_related:
    "Add the business projects for which you incur expenses, so that users can associate the project related expenses with them.",
  add_new_policy: "Add New Policy",
  org_cost:
    "Add the sub organization to which costs may be charged for accounting purposes.",
  attach_payments: "Attach file(s) to payments",
  attach_bill_file: "Attach file(s) to the bill",
  upload: "Upload",
  upload_bulk_payments: "Upload bulk payments",
  upload_bills: "Upload Bills",
  from_computer: "From Computer",
  from_city: "From City",
  tds_slab:
    "Add the different TDS slabs for which sales and purchase are involved in your organization.",
  purchase_tax:
    "Add the different taxes for which sales and purchase are involved in your organization.",
  sale_items:
    "Add the items that are tied for sales and purchase in your organization.",
  merchant_business:
    "Add the merchants to whom you incur business expenses so that users can associate them to their respective expenses.",
  desc_sub: "You can upload a maximum of 5 files, 5MB each",
  inv_desc: "You can upload a maximum of 5 files, 1MB each",
  bill_desc: "You can upload a maximum of 5 files, 1MB in total",
  not_uploaded: "Not Uploaded",
  not_activated: "Not Activated",
  hot_list: "Hotlisted",
  temp_hot_list: "Temporary Hotlisted",
  less_than: "Less Than",
  greater_than: "Greater Than",
  less_than_or: "Less than or equal",
  greater_than_or: "Greater than or equal",
  not_equal: "Not equal",
  expense_amount: "Expense Amount",
  bill_attached: "Bill Mandatory",
  expense_policy: "Expense Policy",
  pay_policy: "Payment Policy",
  invoice_approvals: "Invoice Approvals",
  bill_approvals: "Bill Approvals",
  invoice_create: "Create invoice and collect payments directly",
  collect_payment: "Collect Payment",
  unsubmitted_bills: "unsubmitted bills",
  options_are_required: "Options are Required",
  unsubmitted_sales_order: "unsubmitted sales order",
  unsubmitted_invoices: "unsubmitted invoices",
  unsubmitted_purchase_order: "unsubmitted purchase orders",
  unsubmitted_trip: "unsubmitted trips",
  custom_name: "Field Name",
  custom_fields: "Custom Fields",
  download_sample_file: "Download  Sample File",
  download_csv:
    " You can download the sample template file (.csv) and fill in the information.",
  edit_info: "You can edit information in the file (.csv) and upload the same.",
  proceed: "Proceed",
  instructions: "Instructions",
  user_grade_match:
    "User Grade, Designation, Roles, Title fields entered should match exactly with the master data available in the application.",
  unique_id:
    "Employee ID should be unique, the system will not allow duplicates user id’s.",
  email_format:
    " Email should be of valid format, system will not allow duplicate email id’s for an organization.",
  mobile_number_should_have:
    "Primary Phone should be a valid mobile number of 10 digits.",
  email_id_approvers:
    "Email Id of the approvers should be provided for assigning them to the user. (Ex : Reporting Manager - rm@yopmail.com)",
  success_file_upload:
    "On successful file upload, creation of users functionality will run in an asynchronous job. Post successful completion of job, an email will be triggered to the administrator on the status of users creation.",
  upload_file_format: "Upload file should be in CSV format.",
  not_assigned: "Not Assigned",
  size_file: "File Size",
  data_type: "Data Type",
  want_give: "Want to give a card to this user",
  sync: "Sync",
  files_upload: "Upload Files",
  next: "Next",
  credit_note: "Credit Note#",
  estimate_hash: "Estimate#",
  invoice_hashh: "Invoice#",
  balance_amount: "Balance Amount",
  already_present: "already present.",
  credit_account: "Credit Account",
  select_type: "Select Type",
  select_advance_approver: "Select Advance Approver",
  select_approver: "Select Approver",
  limits_settings: "Limits and settings",
  define_user_limits: "Define User Limits",
  profile: "Profile",
  personal_information: "Personal Information",
  so_no: "SO Number",
  user: "User",
  view_user_edit: "View user details and Edit",
  s: "s",
  role: "Role",
  add_new_user: "Add New User",
  transfer_pending: "Transfer pending approvals with this user to the new user",
  employee_assign:
    " Assign employees assigned to this user to another approver",
  employee_expense:
    "Employee Expenses and Expense Reports will still be in the data base to view but employee will be deleted and no longer will access the system.",
  enter_card: "Enter the card number",
  ur_selected_file: "Your Selected File:",
  assign_prepaid_card: "Assign Prepaid Card",
  previous: "Previous",
  imported_file_headers: "Imported File Headers",
  best_match_selected:
    "The best match to each field on the selected file have been auto-selected.",
  edit_role: "Edit Role",
  new_role: "New Role",
  delete_role_are_u_sure: "Are you sure about deleting this role?",
  delete_role_are_u_sure_organization:
    "Are you sure about deleting this organisation?",
  delete_grade_are_u_sure: "Are you sure about deleting this department?",
  role_updation: "Role Updated successfully",
  user_grade_degree: "User Grade/Level",
  grade_level: "Grade/Level",
  card_no_kit: "Card Number or KIT number",
  user_title: "Department",
  grade_designations: "Departments & Grades",
  desi: "Designation",
  grade_name: "Grade name",
  user_dont_have: "This user do not have permissions for",
  approve_msg: "Do you want to approve this",
  contact_person: "Contact Persons",
  shipping_address: "Shipping Address",
  billing_address: "Billing Address",
  bank_Account_info: "Bank Account Information",
  bank_Accounts: "Bank Accounts",
  bank_transfers: "Bank Transfers",
  beneficiary_Accounts: "Recipient Accounts",
  source_of_supply: "Source of Supply",
  place_of_supply: "Place Of Supply",
  enter_source_apply: "Enter Source of supply",
  bill: "Bill",
  payment_made: "Payment Made",
  payments_made: "Payments Made",
  payment_status: "Payment Status",
  want_bulk: "Are you sure you want to bulk",
  balance_due_amount: "Balance Due Amount",
  balance_due: "Balance Due",
  transfer_log: "Transfer Log",
  rejected: "Rejected",
  confirmed: "Confirmed",
  quantity: "Quantity",

  quick_load: "Quick Load",
  quick_transfers: "Quick Transfers",
  transaction_nature: "Transaction Nature",
  receipt_due: "Due on Receipt",
  import_365: "IMPS 24 * 7 / 365 days",
  neft_sunday:
    "NEFT 08:00 AM to 06:00 PM (Daily except 2nd and 4th  Saturdays, Sunday and RBI holidays)",
  rtgs_sunday:
    "RTGS 08:00 AM to 04:00 PM (Daily except 2nd and 4th Saturdays, Sunday and RBI holidays)",
  number_phone: "Phone Number",

  all_budget_modal: "Allocate Funds",
  allocate_fund: "Allocate Fund to",
  enter_amount: "Enter Amount",
  allocate_desc: "Notes",

  please_enter_amount: "Enter Amount",
  place_allocate_desc: "Description or Reference details",

  allocate_button: "Allocate Funds",
  account_create_form_text:
    "Unallocated funds available for allocating to different departments",
  account_create_form_sub_text: "Available Balance",
  return_funds: "Return Funds",
  transfer_by: "Transfer By",
  description: "Description",
  is_Requrired: "is required",
  amount_field: "Amount",
  amount: "Amount",
  report_name: "report_name",
  amount_validation: "Enter Valid Amount",
  cost_centers: "Cost Centers",
  enterOtp: "Enter OTP",
  ididntReceiveTxtMsg: "I didn't receive email",
  resendOtp: "Resend OTP",
  sendOtp: "Send OTP",
  otpExpired: "OTP Expired",
  otpSuccess: "OTP sent successfully",
  Zenus_footer_text:
    "Zenus Bank is licensed and regulated by the OCIF in Puerto Rico,USA.",
  two_step: "Two-Step Authentication",
  enter_otp:
    "Please enter the OTP received on the registered email address or mobile number.",
  mode_of_payment: "Mode of Payment",
  beneficiary_account: "Recipient account",
  txn_id: "Txn Id",
  current_balance: "Current Balance",
  virtual_balance: "Virtual Balance",
  total: "Total",
  pending_approval: "Pending Approval",
  unReported_expenses: "UnReported Expenses",
  cash_in: "Cash In",
  cash_out: "Cash Out",
  fund_transfers: "Fund Transfers",
  statements: "Statements",
  statement: "Statement",
  loan: "Loans",
  manage_accounts: "Manage Accounts",
  cash_flow: "Cash Flow",
  new_account: "+ New Account",
  total_balance: "Total Balance",
  last_week: "VS. LAST WEEK",
  recent_transactions: "Recent Transactions",
  by_logging: "By logging, in you have read and agreed to the Zenus Bank",
  terms_conditions: "terms & conditions, terms of use ",
  privacy_policy: "and privacy policy.",
  verify_otp: "Verify OTP",
  select_branch: "Select Branch",
  transfer: "Transfer",
  showing: "Showing",
  to: "to",
  of: "of",
  entires: "entires",
  branches: "Branches",
  currencies: "Currencies",
  customize: "Customize",
  payment_method: "Payment Method",
  transaction_id: "Transaction Id",
  bank_showing: "Bank Showing",
  Zenus: "Zenus",
  find_records: "Find records of all transactions both incoming and outgoing",
  last_transactions: "Last 10 transactions",
  today: "today",
  select_period: "SELECT PERIOD",
  keep_your_business:
    "Keep your business operational and secure cashflows with working capital loans.",
  enjoy_credit: "Enjoy credit facility upwards of $100k",
  access_pre: "Access pre-qualified business credit cards to meet expenses",
  apply_loans: "Apply Loan",
  my_loans: "My Loans",
  work_cap_loan: "Working Capital Loan",
  get_loans_against:
    "Get loans against property, securities, book debts, and gold",
  business_loan: "Business Loan",
  fuel_your_busin:
    "Fuel your Business growth and ensure smooth functioning with Bank’s Business Loan.",
  avail_a_loan: "Avail a loan up to $100k  ($200k in some locations)",
  flexible_repay: "Flexible repayment tenure between 12 and 48 months",
  check_your_busine: "Check your business loan eligibility in just 60 seconds",
  invoice_discountind: "Invoice discounting",
  drive_your_business:
    "Drive your business towards success with debt funding against unpaid invoices",
  get_up_loans: "Get up loans against unpaid invoices as collateral",
  access_up: "Access up to $200k against unpaid invoices",
  pay_at_your: "Pay at your own pace, with no EMI",
  loan_against: "Loan Against Property",
  get_instant:
    "Get instant access to business finance against property that you own",
  get_loans_up: "Get loans up to 65% of the market value of the property",
  enjoy_longer:
    "Enjoy longer repayment tenures, so that you can plan at your pace",
  both_residential:
    "Both residential and commercial properties are acceptable.",
  apply_online: "Apply Online",
  know_more: "Know More",
  apply_loan: "Apply Loan",
  requests_pending: "Requests pending for approval",
  select_date: "Select Date",
  fiters: "FITERS",
  transition_id: "Transition ID",
  date_time: "Date & Time",
  credit: "Credit",
  debit: "Debit",
  yesterday: "Yesterday",
  last_week: "Last week",
  last_month: "Last month",
  last_3_months: "Last 3 months",
  last_6_months: "Last 6 months",
  custom_date: "Custom Date",
  bank: "Banks",
  auto_rev: "Automated review",
  pre_que: "Pre-qualified",
  loan_type: "Loan Type",
  working_cap: "Working Capital Loan",
  business_age: "Business Age",
  years_5: "5 years",
  applied_loan_amount: "Applied Loan amount",
  monthly_payment: "Monthly payment",
  duration: "Duration",
  months_60: "60 months",
  monthly_emi: "Monthly EMI Amount",
  Processing: "Processing",
  work_capital: "Working Capital : UNIWC-342",
  applied: "Applied",
  closed: "Closed",
  active: "Active",
  info: "INFO",
  sub_total: "Sub total",
  total_discount: "Total Discount Amount",
  total_tax_amount: "Total Tax Amount",
  item_details: "Item Details",
  select_item: "Select Item",
  select_tax: "Select Tax",
  attach_file: "Attach File(s) to",
  paid: "Paid",
  pending: "Pending",
  reset_filter: "Reset Filters",
  add_more: "+ Add More",
  run_report: "Run Report",
  filters_applied: "Filters applied :",
  filters: "Filters",
  currency_name: "Currency Name",
  symbol: "Symbol",
  currency_symbol: "Currency Symbol",
  exchange_rate: "Exchange Rate",
  as_of_date: "As of Date",
  new_currency: "New Currency",
  add_currency: "Add Currency",
  edit_currency: "Edit Currency",
  delete_currency: "Delete Currency",
  disable_auto_ex_ra: "Disable Automatic Exchange Rates",
  enable_auto_ex_ra: "Enable Automatic Exchange Rates",
  manual_exchange_rate_currency:
    "You will have to manually enter the exchange rates for each currency, if you disable this feature.",
  automatic_exchange_rate_currency:
    "The exchange rates for the currencies will automatically be fetched in real time.",
  enable: "Enable",
  disable: "Disable",
  delete_currency_confirm: "Are you sure you want to delete this currency?",
  decimal_places: "Decimal Places",
  select_decimal: "Select Decimal",
  enter_user_details: "Enter user details and submit",
  define_department: "Define Department and Grade for the user",
  department_grand: "Department & Grade",
  approval_flow_criteria:
    "You can set up an approval flow based on one or more criteria. These criteria can be chosen by you.",
  advanced_approval: "Advanced Approval",
  yes_bank: "YESBANK",
  advance_filter: "Advance Filter",
  purpose: "purpose",
  submitted_on: "submitted_on",
  trip_name: "trip_name",
  payment_made_description:
    "Customize the fields you require in payments made and set up preferences",
  credit_note_description:
    "Customize the fields you require in credit note and set up preferences",
  payment_received_description:
    "Customize the fields you require in payments received and set up preferences",
  export_as: "Export as",
  collect_payments: "Collect Payments",
  company_payments: "Company Payments",
  payments: "Payments",
  company_bulk_payments: "Company Bulk Payments",
  company_payments_approvals: "Payment Approvals",
  company_bulk_payments_approvals: "Bulk Payment Approvals",
  approvals: "Approvals",
  pay: "Pay",
  send_otp: "Send OTP",
  my_payments: "My Payments",
  bulk_payments: "Bulk Payments",
  no_approval_payment: "Process the payments directly",
  no_approval_bulk_payment: "Process the bulk payments directly",
  payment_desc: "See your payment details here.",
  create_pay_success: "Payment request created successfully.",
  pay_submit_success: "Payment submitted successfully.",
  org_del_suc: "Organisation Deleted Successfully",

  /// Integration ///
  integration: "Integrations",
  all_integrations: "All Integrations",
  integration_description: "Connect Forgo with your ERP",
  edit_organisation: "Edit Organisation",
  add_organisation: "Add Organisation",
  disconnect: "Disconnect",
  disconnected: "Disconnected",
  disconnect_failed: "Disconnection Failed",
  owner_name: "Owner Name",
  owner_name_placeholder: "Enter Owner Name",
  owner_name_required: "Owner Name cant be empty",
  owner_email: "Owner Email",
  owner_email_placeholder: "Enter Owner Email",
  owner_email_required: "Owner Email cant be empty",
  organisation_name_placeholder: "Enter Organisation Name",
  organisation_name_required: "Organisation Name cant be empty",
  okay: "Okay",
  pause: "Pause",
  configure: "Configure",
  import_cutomers: "Import Customers",
  you_can_import:
    "You can import contacts into Forgo from a .CSV or .TSV or .XLS file.",
  cust_cont_per: "Customer's Contact Persons",
  customers_select_file: "Customers - Select file",
  vendors_select_file: "Vendors - Select file",
  configure: "Configure",
  map_fields: "Map Fields",
  preview: "Preview",
  drag_and_drop: "Drag and drop file to import",
  max_file_size: "Maximum File Size: 5 MB - File Format: CSV or XLS",
  choose_file: "Choose File",
  replace_file: "Replace File",
  remove: "Remove",
  org_del_suc: "Organisation Deleted Successfully",
  data_formats: "Data Formats",
  none_of_the: "None of the rows can be imported",
  number_of_the: "of the rows can be imported",
  deletePayment: "Are you sure you want to delete the Payment",
  deletePaymentLink: "Are you sure you want to delete the Payment Link",
  customers_select_file: "Customers Select File",
  vendors_select_file: "Vendors Select File",
  individual_select_file: "Upload Recipients",
  estimate_select_file: "Estimate Select File",
  invoice_select_file: "Invoice Select File",
  sales_order_select_file: "Sales Order Select File",
  credit_notes_select_file: "Credit Notes Select File",
  purchase_order_select_file: "Purchase Order Select File",
  bill_select_file: "Bill Select File",
  success_import: "Successfully Import Stated",
  go_back: "Go Back to Upload Page",
  its_take: "it takes 2 minutes to update the data",
};
