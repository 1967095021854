import { ConfigProvider, List, Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import "../Table/table.css";

const TableComponent = ({
  shimmerCount,
  getCheckboxProps,
  rowClassName,
  selectedRowKeys,
  setSelectedRowKeys,
  columns,
  data,
  pagination,
  scroll,
  rowSelection,
  selectedRows,
  setSelectedRows,
  renderEmpty,
  hasCheckbox,
  loading,
  handleRowClick,
  rowKey,
  renderMobItem,
}) => {
  const handleCreatePaginator = useCallback((total, range) => {
    return `Showing ${range[0]} to ${range[1]} of ${total} entires`;
  }, []);

  const [dummyData, setDummyData] = useState([]);

  const dummyColumns = columns?.map((ele) => ({
    ...ele,
    render: () => <span></span>,
  }));

  useEffect(() => {
    let arr = [];
    let nums = shimmerCount
      ? Array.from({ length: shimmerCount }, (_, i) => i)
      : [1, 2, 3, 4, 5, 6, 7, 8];
    nums.forEach((element) => {
      let obj = {};
      columns.forEach((ele) => {
        obj[element.dataIndex] = "";
      });
      arr.push(obj);
    });
    setDummyData(arr);
  }, []);

  return (
    <div className="paycraft-table">
      <ConfigProvider renderEmpty={renderEmpty}>
        <List
          dataSource={data}
          pagination={
            pagination
              ? {
                  ...pagination,
                  showTotal: handleCreatePaginator,
                  showSizeChanger: false,
                }
              : false
          }
          renderItem={(rec) => {
            return (
              <div
                onClick={(event) =>
                  handleRowClick && handleRowClick(event, rec)
                }
              >
                {renderMobItem?.(rec)}
              </div>
            );
          }}
          className="list-table"
          onRow={(record) => {
            return {
              onClick: (event) =>
                handleRowClick && handleRowClick(event, record),
            };
          }}
        />

        <Table
          rowSelection={
            hasCheckbox === false
              ? undefined
              : rowSelection
              ? rowSelection
              : {
                  type: "checkbox",
                  onChange: (selectedRowKeys, selectedRows) => {
                    setSelectedRows(selectedRows);
                    setSelectedRowKeys(selectedRowKeys);
                  },
                  getCheckboxProps: getCheckboxProps
                    ? getCheckboxProps
                    : (record) => ({
                        checked: selectedRows?.find(
                          (ele) => ele.id === record.id
                        )
                          ? true
                          : false,
                        disabled: loading,
                      }),
                  selectedRowKeys: selectedRowKeys,
                }
          }
          columns={loading ? dummyColumns : columns}
          rowClassName={rowClassName}
          dataSource={loading ? dummyData : data}
          pagination={
            pagination
              ? {
                  ...pagination,
                  showTotal: handleCreatePaginator,
                  showSizeChanger: false,
                }
              : false
          }
          scroll={scroll}
          rowKey={rowKey}
          className="tableComponent"
          onRow={(record) => {
            return {
              onClick: (event) =>
                handleRowClick && handleRowClick(event, record),
            };
          }}
        />
      </ConfigProvider>
    </div>
  );
};

export default TableComponent;
