import { QuestionCircleOutlined } from "@ant-design/icons";
import { message, Select, Tooltip, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import copy from "../../assets/icons/copy.png";
import money_in from "../../assets/icons/money_in.png";
import money_out from "../../assets/icons/money_out.png";
import { amountFormat, giveInitials, hasAccess } from "../../utils";
import { AccountDisplay } from "./AccountCard";
import usePayAxios from "../../config/useAxios";
import { apis } from "../../config/APIs";
import moment from "moment";
const { Text } = Typography;
export const AccountDetails = ({ account }) => {
  const history = useHistory();
  const handleCopy = useCallback((text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        message.success(<span className="messageText">Copied</span>);
      },
      (err) => console.error("Failed to copy text: ", err)
    );
  }, []);

  return (
    <div className="account-card acc-details">
      <h3 className="title">Account Details</h3>
      <div className="card w45">
        <div className="details">
          <div className="flex-between">
            <Text className="avl-bal">Account Name</Text>
            <div className="value">
              <p
                style={{
                  display: "inline-block",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: "17rem",
                  color: "#212121",
                  textAlign: "right",
                }}
                title={account?.account_name}
              >
                {account?.account_name}{" "}
              </p>
              <img
                src={copy}
                width={12}
                onClick={() => {
                  handleCopy(account?.account_name);
                }}
              />
            </div>
          </div>
          <div className="flex-between">
            <Text className="avl-bal">Routing Number</Text>
            <div className="value">
              {account?.routing_code}{" "}
              <img
                src={copy}
                width={12}
                onClick={() => {
                  handleCopy(account?.routing_code);
                }}
              />
            </div>
          </div>
          <div className="flex-between">
            <Text className="avl-bal">Account number</Text>
            <div className="value">
              <AccountDisplay
                accountNumber={account?.account_number}
                left={true}
              />
              <img
                src={copy}
                width={12}
                onClick={() => {
                  handleCopy(account?.account_number);
                }}
              />
            </div>
          </div>
          <div className="flex-between">
            <Text className="avl-bal">BIC/SWIFT Code</Text>
            <div className="value">
              <AccountDisplay accountNumber={account?.bic_code} left={true} />
              <img
                src={copy}
                width={12}
                onClick={() => {
                  handleCopy(account?.bic_code);
                }}
              />
            </div>
          </div>
          <div className="flex-between">
            <Text className="avl-bal">Status</Text>
            <div className={`status status_${account?.status?.toLowerCase()}`}>
              {account?.status}
            </div>
          </div>
        </div>
        <div
          className="flex-between"
          style={{
            alignItems: "center",
          }}
        >
          <span
            className="copy-light"
            onClick={() => {
              handleCopy(
                `Account Name:    ${account?.account_name} \nRouting Code:    ${account?.routing_code} \nAccount Number:  ${account?.account_number}`
              );
            }}
          >
            Copy
          </span>
          {hasAccess("statements", "read") && (
            <span
              className="view-statement"
              onClick={() => {
                history.push("/statements", {
                  accountId: account?.id,
                });
              }}
            >
              View Statements
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export const AccountInfo = ({ account }) => {
  return (
    <div className="acc-details mt2">
      <h3 className="title">Account Information</h3>
      <div className="card w45">
        <div className="details grid">
          <div className="item">
            <Text className="label">Account Name</Text>
            <div className="value">
              <p title={account?.account_name}>{account?.account_name} </p>
            </div>
          </div>
          <div className="item">
            <Text className="label">Routing Number</Text>
            <div className="value">{account?.routing_code} </div>
          </div>
          <div className="item">
            <Text className="label">Account number</Text>
            <div className="value">
              <AccountDisplay accountNumber={account?.account_number} />
            </div>
          </div>
          <div className="item">
            <Text className="label">BIC/SWIFT Code</Text>
            <div className="value">{account?.bic_code} </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AccountSummary = ({ account }) => {
  const handleCopy = useCallback((text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        message.success(<span className="messageText">Copied</span>);
      },
      (err) => console.error("Failed to copy text: ", err)
    );
  }, []);

  const [fil, setFil] = useState("this_month");

  const { onCall, data, loading } = usePayAxios({
    api: apis.getAccountSummary,
    method: "get",
  });

  const handleChange = (val) => {
    let from_date = "";
    let to_date = "";
    setFil(val);
    switch (val) {
      case "last_month":
        from_date = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        to_date = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        break;
      case "this_month":
        from_date = moment().startOf("month").format("YYYY-MM-DD");
        to_date = moment().endOf("month").format("YYYY-MM-DD");
        break;
      case "this_year":
        from_date = moment().startOf("year").format("YYYY-MM-DD");
        to_date = moment().endOf("year").format("YYYY-MM-DD");
        break;
      case "last_year":
        from_date = moment()
          .subtract(1, "year")
          .startOf("year")
          .format("YYYY-MM-DD");
        to_date = moment()
          .subtract(1, "year")
          .endOf("year")
          .format("YYYY-MM-DD");
        break;
      default:
        break;
    }

    onCall({
      params: {
        from_date,
        to_date,
        account_id: account?.id,
      },
    });
  };

  useEffect(() => {
    handleChange(fil);
  }, [account]);
  return (
    <>
      <div className="account-summary-card">
        <div>
          <div className="flex-between">
            <div className="account-header">
              <div className="profile">
                <div className="avatar">
                  {giveInitials(account?.given_name)}
                </div>
                <div className="acc-info">
                  <div className="name">{account?.given_name}</div>
                  {/* <div className="email">{account?.given_name}</div> */}
                </div>
              </div>
            </div>
            <div>
              <Select
                value={fil}
                options={[
                  { label: "Last month", value: "last_month" },
                  { label: "This Month", value: "this_month" },
                  { label: "This Year", value: "this_year" },
                  { label: "Last Year", value: "last_year" },
                ]}
                onChange={(val) => {
                  handleChange(val);
                }}
              />
            </div>
          </div>
          <div className="flex-between">
            <div className="sub-acc-details">
              <div className="detail">
                <span className="label">Account number</span>
                <span className="value">
                  {account?.account_number}{" "}
                  <img
                    src={copy}
                    width={12}
                    onClick={() => {
                      handleCopy(account?.account_number);
                    }}
                  />
                </span>
              </div>
              <div className="detail">
                <span className="label">Routing Number</span>
                <span className="value">
                  {account?.routing_code}{" "}
                  <img
                    src={copy}
                    width={12}
                    onClick={() => {
                      handleCopy(account?.routing_code);
                    }}
                  />
                </span>
              </div>
              <div className="detail">
                <span className="label">Account Type</span>
                <span className="value">{account?.account_type}</span>
              </div>
            </div>
            <div className="money-summary">
              <div className="money flex-between">
                <div>
                  <div className="label">Money in</div>
                  <div className="value d-flex">
                    ${" "}
                    {loading ? (
                      <label className="shimmer">
                        <span
                          style={{
                            width: "6rem",
                            height: "1.5rem",
                            marginLeft: 10,
                          }}
                        ></span>
                      </label>
                    ) : (
                      amountFormat(data?.data?.[0]?.credit?.sum)
                    )}
                  </div>
                </div>
                <img src={money_in} />
              </div>
              <div className="money flex-between">
                <div>
                  <div className="label">Money out</div>
                  <div className="value d-flex">
                    ${" "}
                    {loading ? (
                      <label className="shimmer">
                        <span
                          style={{
                            width: "6rem",
                            height: "1.5rem",
                            marginLeft: 10,
                          }}
                        ></span>
                      </label>
                    ) : (
                      amountFormat(data?.data?.[0]?.debit?.sum)
                    )}
                  </div>
                </div>
                <img src={money_out} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-title ">
        <span>
          <span className="card-title">Transactions</span>
          <span className="help-icon">
            <Tooltip placement="top" title={"Transactions."}>
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        </span>
      </div>
    </>
  );
};
