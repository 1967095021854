import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import { Button, Card } from "antd";
import React, { useContext, useState } from "react";
import acc_img from "../../assets/icons/acc_img.png";
import right_arr from "../../assets/images/right_arrow.png";
import NoTableData from "../Common/TableComp/NoTableData";
import { LeftNavContext } from "../../LeftNavProvider";

export const AccountDisplay = ({ accountNumber = "", showEye, left }) => {
  const [showAccount, setShowAccount] = useState(false);

  const toggleAccountVisibility = (e) => {
    e.stopPropagation();
    setShowAccount((prevState) => !prevState);
  };

  if (showEye) {
    return `****${accountNumber?.slice?.(-4)}`;
  }

  return (
    <>
      {!showEye && left && (
        <>
          <Button
            className="eye"
            type="link"
            icon={!showAccount ? <EyeInvisibleFilled /> : <EyeFilled />}
            onClick={toggleAccountVisibility}
          />
          &nbsp;&nbsp;
        </>
      )}
      {showAccount ? accountNumber : `****${accountNumber?.slice?.(-4)}`}
      {!showEye && !left && (
        <>
          <Button
            className="eye mlHalf"
            type="link"
            icon={!showAccount ? <EyeInvisibleFilled /> : <EyeFilled />}
            onClick={toggleAccountVisibility}
          />
        </>
      )}
      &nbsp;
    </>
  );
};

const AccountCard = ({ data, onClick, showAcc }) => {
  return (
    <div className="send-payment" style={{ padding: "1.4rem" }}>
      <div className="acc-wrap">
        {/* Account Info Card */}
        {data?.length === 0 ? (
          <NoTableData title={"No Accounts Found"} />
        ) : (
          data?.map((ele) => (
            <div
              className={`acc-card ${
                ele?.status ? ele?.status?.toLowerCase() : ""
              }`}
            >
              <Card
                bordered
                style={{
                  borderRadius: "8px",
                  cursor:
                    ele?.status?.toLowerCase() === "closed"
                      ? "auto"
                      : "pointer",
                }}
                onClick={() => {
                  if (ele?.status?.toLowerCase() === "closed") {
                    return;
                  }
                  onClick(ele);
                }}
              >
                <div className="item first">
                  <div className="d-flex gap1 align-items-center">
                    <img src={acc_img} width={50} className="acc-img" />
                    <div className="acc-sec">
                      <span className="label">Account</span>
                      <span className="value" title={ele.account_name}>
                        {ele.account_name}
                      </span>
                    </div>
                  </div>
                  <img src={right_arr} className="right" />
                </div>
                <div className="item">
                  <span className="label">Available Balance</span>
                  <span className="value font-big">
                    {showAcc(ele.balance, ele)}
                  </span>
                </div>
                <div className="item">
                  <span className="label">Account Number</span>
                  <span className="value">
                    <AccountDisplay accountNumber={ele.account_number} />
                  </span>
                </div>
                <div className="item flex-between">
                  <div>
                    <span className="label">Status</span>
                    <span
                      className={`value status status_${ele?.status?.toLowerCase()}`}
                    >
                      {ele?.status}
                    </span>
                  </div>
                  <div>
                    <span className="label">Type</span>
                    <span className={`value`}>{ele?.account_type}</span>
                  </div>
                </div>
              </Card>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
export const AccountList = ({ data, onClick, isSub, showAcc }) => {
  const { width } = useContext(LeftNavContext);
  return width < 900 ? (
    <div className="send-payment list">
      {/* Account Info Card */}
      {data?.length === 0 ? (
        <NoTableData title={"No Accounts Found"} />
      ) : (
        data?.map((ele) => (
          <div
            className={`account-row mobile-view ${
              ele?.status ? ele?.status?.toLowerCase() : ""
            }`}
          >
            <Card
              bordered
              style={{
                borderRadius: "8px",
              }}
              onClick={() => {
                if (ele?.status?.toLowerCase() === "closed") {
                  return;
                }
                onClick?.(ele);
              }}
            >
              <div className="item">
                <div className="d-flex gap1 align-items-center">
                  <img src={acc_img} className="acc-img" />
                  <div className="acc-sec">
                    <span className="date-time">{ele.account_number}</span>
                    <span className="value" title={ele.account_name}>
                      {ele.account_name}
                    </span>
                    <span
                      className={`status status_${ele?.status?.toLowerCase()}`}
                      style={{
                        minWidth: "fit-content",
                      }}
                    >
                      {ele?.status}
                    </span>
                  </div>
                </div>
              </div>
              <div className="item">
                <span className="value">{showAcc(ele?.balance, ele)}</span>
              </div>
            </Card>
          </div>
        ))
      )}
    </div>
  ) : (
    <div className="send-payment list">
      {/* Account Info Card */}
      {data?.length === 0 ? (
        <NoTableData title={"No Accounts Found"} />
      ) : (
        data?.map((ele) => (
          <div
            className={`account-row ${
              ele?.status ? ele?.status?.toLowerCase() : ""
            }`}
          >
            <Card
              bordered
              style={{
                borderRadius: "8px",
              }}
              onClick={() => {
                if (ele?.status?.toLowerCase() === "closed") {
                  return;
                }
                onClick?.(ele);
              }}
            >
              <div className="item">
                <div className="d-flex gap1 align-items-center">
                  <img src={acc_img} className="acc-img" />
                  <div className="acc-sec">
                    <span className="label">Account</span>
                    <span className="value" title={ele.account_name}>
                      {ele.account_name}
                    </span>
                  </div>
                </div>
              </div>
              {isSub ? null : (
                <div className="item">
                  <span className="label">Available Balance</span>
                  <span className="value">{showAcc(ele?.balance, ele)}</span>
                </div>
              )}
              <div className="item">
                <span className="label">Account Number</span>
                <span className="value">{ele.account_number}</span>
              </div>
              <div className="item">
                <span className="label">Currency</span>
                <span className="value">{ele.currency_code}</span>
              </div>
              <div className="item">
                <span className="label">Status</span>
                <span className={`value status status_${ele?.status?.toLowerCase()}`}>
                  {ele?.status}
                </span>
              </div>
              <div className="item">
                <span className="label">Type</span>
                <span className={`value`}>{ele?.account_type}</span>
              </div>
            </Card>
          </div>
        ))
      )}
      {/* Add Account Card */}
      {/* <Col span={24}>
          <Card
            bordered
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              minHeight: "5rem",
            }}
          >
            <Button
              type="link"
              icon={<img src={plus_circle} width={17} />}
              size="large"
              onClick={addNew}
            >
              Create account
            </Button>
          </Card>
        </Col> */}
    </div>
  );
};
export default AccountCard;
