import { Breadcrumb } from "antd";
import * as React from "react";
import { Link } from "react-router-dom";
import Close from "../../assets/icons/close.png";
import back_arrow from "../Payments/SendMoney/assets/back_arrow.png";
import { useTranslation } from "react-i18next";

const renderNavItem = (navigation) => {
  const items = [];
  for (const key of Object.keys(navigation)) {
    items.push(
      navigation[key] ? (
        <Breadcrumb.Item key={key}>
          <Link key={key} to={navigation[key]}>
            {key}
          </Link>
        </Breadcrumb.Item>
      ) : (
        <span key={key}>{key}</span>
      )
    );
  }
  return items;
};

const PageHeader = (props) => {
  const { title, navigation, description, hasBack, handleBack } = props;
  const { t } = useTranslation();
  return (
    <div className="pageHeader">
      <div className="d-flex align-center gap1">
        {hasBack && (
          <span
            className="back-link cursor"
            onClick={handleBack}
            style={{ fontSize: "1rem" }}
          >
            <img src={back_arrow} width={10} className="mrHalf" />
            Back
          </span>
        )}
        <p className="main">{t(title)}</p>
        <p className="sub">|</p>
        <p className="sub">{t(description)}</p>
      </div>
      {navigation && (
        <Breadcrumb separator=".">{renderNavItem(navigation)}</Breadcrumb>
      )}

      {props?.isClose && (
        <span className="close cursor" onClick={props.onCloseClick}>
          <img src={Close} width={20} alt={"close"} />
        </span>
      )}
    </div>
  );
};

export default PageHeader;
