import { useContext, useEffect } from "react";
import { LeftNavContext } from "../../LeftNavProvider";

const ResizeSetter = () => {
  const { setWidth } = useContext(LeftNavContext);

  useEffect(() => {
    setWidth(window?.screen?.width);
  }, []);

  return null;
};

export default ResizeSetter;
