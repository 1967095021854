import moment from "moment";
import React, { useState } from "react";

const PaymentReview = ({ mainData, crAcc, dbAcc }) => {
  const [payer, setPayer] = useState(true);
  return (
    <div className="payment-review-details desk">
      <ul>
        <li
          className={`${payer ? "active" : ""}`}
          onClick={() => {
            setPayer(true);
          }}
        >
          Payer Details
        </li>
        <li
          className={`${payer ? "" : "active"}`}
          onClick={() => {
            setPayer(false);
          }}
        >
          Counterparty Details
        </li>
      </ul>
      {payer ? (
        <div className="details-grid">
          <div>
            <p>Payment Identifier</p>
            <p>{mainData?.order_id ?? mainData?.endToEndId}</p>
          </div>
          <div>
            <p>Payer Name</p>
            <p>{dbAcc?.account_name}</p>
          </div>
          <div>
            <p>Payer Account Number</p>
            <p>{dbAcc?.account_number}</p>
          </div>
          <div>
            <p>Financial Institution ID</p>
            <p>{dbAcc?.routing_code ?? dbAcc?.swift_code}</p>
          </div>
          <div>
            <p>Account Number Type</p>
            <p>{dbAcc?.account_number_type}</p>
          </div>{" "}
          <div>
            <p>BIC/SWIFT Code</p>
            <p>{dbAcc?.bic_code}</p>
          </div>
        </div>
      ) : (
        <div className="details-grid">
          <div>
            <p>Date of Payment</p>
            <p>{moment().format("HH:mm Do MMM, YYYY")}</p>
          </div>
          <div>
            <p>Counterparty Name</p>
            <p>{crAcc?.beneficiary_name}</p>
          </div>

          <div>
            <p>Counterparty Account Number</p>
            <p>{crAcc?.bank_accounts?.[0]?.account_number}</p>
          </div>
          <div>
            <p>Financial Institution ID</p>
            <p>
              {crAcc?.bank_accounts?.[0]?.routing_code ??
                crAcc?.bank_accounts?.[0]?.swift_code}
            </p>
          </div>

          <div>
            <p>Address</p>
            <p>
              {crAcc?.billing_addresses?.[0]?.address}
              <br />
              {crAcc?.billing_addresses?.[0]?.city}
              <br />
              {crAcc?.billing_addresses?.[0]?.state},{" "}
              {crAcc?.billing_addresses?.[0]?.country},{" "}
              {crAcc?.billing_addresses?.[0]?.zipcode}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentReview;
