import { Badge, message } from "antd";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { app_loading } from "../../actions/auth";
import { headerTitles, SideMenu } from "../../actions/masterdata/masterdata";
import { get_all_payments } from "../../actions/z_accounts";
import credit from "../../assets/icons/credit.png";
import debit from "../../assets/icons/debit.png";
import { gridApis } from "../../config/GridApis";
import { currencyMapper } from "../../config/helper";
import usePayAxios from "../../config/useAxios";
import { amountFormat, removeEmptyValues } from "../../utils";
import BankShow from "../Common/BankShow";
import NodataComponent from "../Common/NodataComponent";
import TableComponent from "../Common/TableComp/TableComp";
import { p_types } from "../Payments/SendMoney/Recipients";
import SendMoney from "../Payments/SendMoney/SendMoney";
import { AccountDisplay } from "./AccountCard";
import TxnDetailModal from "./TxnDetailModal";
// import Base from "antd/lib/typography/Base";

const AccountTransaction = ({
  sendMoney,
  user,
  txns,
  noAccFil,
  accountId,
  from,
  isSubAcc,
}) => {
  const dispatch = useDispatch();
  const bankAcc = useSelector(
    (state) => state?.z_accs?.accounts?.data?.data || []
  );
  const [openAddNew, setOpenAddNew] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [txnDetail, setTxnDetail] = useState({});
  const [txnOpen, setTxnOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [isMaster, setIsMaster] = useState(false);
  const error = useSelector((state) => state?.z_accs?.all_payments?.data ?? []);

  const [filterDetails, setFilterDetails] = useState({
    date_from: null,
    date_to: null,
  });
  const masterHistoryResponse = useSelector(
    (state) => state?.z_accs?.all_payments?.data?.data ?? []
  );
  const totalRecords = useSelector(
    (state) => state?.z_accs?.all_payments?.data?.total_count ?? []
  );
  const location = useLocation();
  const { onCall, loading: pdfLoading } = usePayAxios({
    type: "grid",
    api: gridApis.fetchExcelTransactions,
    method: "post",
  });
  React.useEffect(() => {
    // dispatch(accountTxns(({ page_number: 1 })));
    if (!from)
      dispatch(
        headerTitles(
          sendMoney
            ? {
                title: "Send Money",
                description: "Payments Transfer to Recipients Accounts",
              }
            : {
                title: "account_transactions",
                description: "View all your global account transactions",
              }
        )
      );
    if (!sendMoney) {
      setOpenAddNew(false);
    }
    if (location?.pathname) {
      let locationSplit = location?.pathname?.split("/");
      dispatch({
        type: SideMenu,
        payload: {
          firstNav: "/" + locationSplit?.[1],
          secondNav: "/" + locationSplit?.[2],
          showChildren: true,
          childrenMenu: "/" + locationSplit?.[3],
        },
      });
    }

    if (!openAddNew)
      dispatch(
        get_all_payments(
          removeEmptyValues({
            params: {
              account_id: accountId ? accountId : undefined,
              view: user ? undefined : "admin",
            },
            txns,
            isSubAcc,
            filters: {
              status: location?.state?.status,
            },
          })
        )
      );
    if (accountId) {
      setFilterDetails((val) => ({
        ...val,
        acc: accountId,
      }));
    }
    if (location?.state?.status) {
      setFilterDetails((val) => ({
        ...val,
        txnStatus: location?.state?.status,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMoney, openAddNew, txns]);
  const { t } = useTranslation();

  const columns = [
    {
      title: t("Created Date and Time"),
      dataIndex: "inserted_date",
      key: "inserted_date",
      render: (text, rec) => (
        <div>{text ? moment(text).format("MMM DD, YYYY, hh:mm A") : "-"}</div>
      ),
    },
    {
      title: t("Payment Id"),
      dataIndex: "payment_id",
      key: "payment_id",
      render: (text, record) => (
        <div title={text}>
          {record?.schedule_payment_date && (
            <div className="base_badge">
              <Badge.Ribbon
                text={`Schedule on ${record?.schedule_payment_date}`}
                placement={"start"}
                color="#3f81f4"
              />
            </div>
          )}
          {text}
        </div>
      ),
    },
    {
      title: t("From"),
      dataIndex: "overparty_account_name",
      key: "overparty_account_name",
      render: (text, rec) =>
        rec.transaction_type === "credit"
          ? rec.counterparty_account_name
          : text,
    },
    {
      title: t("beneficiary_name"),
      dataIndex: "counterparty_account_name",
      key: "counterparty_account_name",
      render: (text, rec) =>
        rec.transaction_type === "credit" ? rec.overparty_account_name : text,
    },
    {
      title: t("beneficiary_account"),
      dataIndex: "counterparty_account_number",
      key: "counterparty_account_number",
      render: (text, rec) => <AccountDisplay showEye accountNumber={text} />,
    },
    {
      title: "Reference Number",
      dataIndex: "reference_number",
      key: "reference_number",
      render: (text) => <div>{text ?? "-"}</div>,
    },
    {
      title: "Purpose",
      dataIndex: "purpose_code",
      key: "purpose_code",
    },
    {
      title: "Description",
      dataIndex: "detail_name",
      key: "detail_name",
      render: (text, rec) => <div>{text ?? rec.description}</div>,
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (text, rec) => (
        <div>
          {currencyMapper[rec.currency]}
          {amountFormat(text)}
        </div>
      ),
    },
    {
      title: "Payment Method",
      dataIndex: "transfer_mode",
      key: "transfer_mode",
      render: (text) =>
        p_types?.find(
          (ele) =>
            ele.value === text ||
            (text === "WITHINBANK" && ele.value === "WITHIN_BANK")
        )?.label,
    },
    {
      title: "Txn Type",
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (text) => (
        <span
          className="i-flex-center"
          style={{
            color: "#333",
            textTransform: "capitalize",
          }}
        >
          <img
            style={{
              transform: "rotate(180deg)",
            }}
            src={text === "credit" ? credit : debit}
            width={10}
          />{" "}
          {text}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <span className={`status status_${text?.toLowerCase()}`}>{text}</span>
      ),
    },
    isSubAcc
      ? {}
      : isMaster
      ? {
          title: "Posted Date",
          dataIndex: "posting_date",
          key: "posting_date",
          render: (text) => (
            <div>{text ? moment(text).format("MMM DD, YYYY") : "-"}</div>
          ),
        }
      : {},
    {
      title: "Settlement Date",
      dataIndex: "settlement_date",
      key: "settlement_date",
      render: (text) => (
        <div>{text ? moment(text).format("MMM DD, YYYY") : "-"}</div>
      ),
    },
  ];

  const ben_columns = [
    {
      title: t("Transaction Id"),
      dataIndex: "order_id",
      key: "order_id",
    },
    {
      title: t("beneficiary_name"),
      dataIndex: "payee_name",
      key: "payee_name",
    },
    {
      title: t("Beneficiary account"),
      dataIndex: "payee_account_no",
      key: "payee_account_no",
      render: (text) => <div>XXXX{text?.substr(4)}</div>,
    },
    {
      title: t("Mode of Payment"),
      dataIndex: "mode_of_payment",
      key: "mode_of_payment",
      render: () => "IMPS / RTGS",
    },

    {
      title: t("dateAndTime"),
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <div>{moment(text).format("MMM DD, YYYY, hh:mm A")}</div>
      ),
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (text, r) => (
        <div>
          {r.currency_code} {text}
        </div>
      ),
    },
    {
      title: t("status"),
      dataIndex: "role_status",
      key: "role_status",
      render: (text, record) => {
        return user ? (
          <div
            className={`status status_${record?.flow_status?.toLowerCase()}`}
          >
            {record?.flow_status?.replace(/_/g, " ")}
          </div>
        ) : (
          <div className={`status status_${text?.toLowerCase()}`}>
            {text?.replace(/_/g, " ")}
          </div>
        );
      },
    },
    {
      title: t("payment_status"),
      dataIndex: "payment_status",
      key: "payment_status",
      render: (text) => (
        <div className={`status status_${text?.toLowerCase()}`}>
          {text?.replace(/_/g, " ")}
        </div>
      ),
    },
    // {
    //   title: "",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space
    //       size="middle"
    //       className="myspace"
    //       onClick={(e) => {
    //         e.stopPropagation();
    //       }}
    //     >
    //       {record.flow_status === "unsubmitted" && (
    //         <Dropdown
    //           overlay={
    //             <Menu>
    //               <Menu.Item onClick={() => onClickEdit(record)}>
    //                 <img src={EditIcon} alt={""} className="action-logo" />
    //                 {t("edit")}
    //               </Menu.Item>
    //               <Menu.Item
    //                 onClick={() => {
    //                   setDeletePaymentModal(true);
    //                   setRecord(record);
    //                 }}
    //               >
    //                 <img
    //                   src={DeleteIcon}
    //                   alt={"logo"}
    //                   className="action-logo"
    //                 />{" "}
    //                 {t("delete")}
    //               </Menu.Item>
    //             </Menu>
    //           }
    //         >
    //           <Button
    //             shape="default"
    //             icon={<MoreOutlined />}
    //             style={{ border: "none", color: "#5B87F9" }}
    //           />
    //         </Dropdown>
    //       )}
    //     </Space>
    //   ),
    // },
  ];

  return openAddNew ? (
    <>
      <SendMoney
        setOpenAddNew={setOpenAddNew}
        cb={() => {
          dispatch(
            get_all_payments({
              params: {
                view: user ? undefined : "admin",
                account_id: accountId ? accountId : undefined,
              },
              txns,
              isSubAcc,
            })
          );
        }}
      />
    </>
  ) : (
    <>
      <BankShow
        noBack={true}
        noAccFil={noAccFil}
        addBtn={sendMoney}
        setOpenAddNew={setOpenAddNew}
        downloadDisable={!selectedAccount && !noAccFil}
        downloadTitle={
          !selectedAccount ? "Please select an account to download" : ""
        }
        onDownload={(val) => {
          if (val === "excel") {
            dispatch(app_loading(true));
            onCall(
              removeEmptyValues({
                params: {
                  account_id: filterDetails?.acc,
                },
                data: {
                  search_key: search,
                  page_number: 1,
                  sort: filterDetails.sort,
                  filters: {
                    inserted_date: {
                      start_date: filterDetails.date_from,
                      end_date: filterDetails.date_to,
                    },
                    transaction_type: filterDetails.txnType,
                    status: filterDetails.txnStatus,
                  },
                },
              })
            )
              .then(async (res) => {
                if (res?.data?.excelLink) {
                  message.success(
                    <span className="messageText">{res?.message}</span>
                  );
                  const blob = await fetch(res?.data?.excelLink).then((res) =>
                    res.blob()
                  );
                  saveAs(blob, res?.data?.excelFileName);
                }
                dispatch(app_loading(false));
              })
              .catch(() => {
                dispatch(app_loading(false));
              });
          }
        }}
        pdfLoading={pdfLoading}
        noPdf={true}
        text={"Send Money"}
        setSelectedAccount={setSelectedAccount}
        callAction={(acc, s_date, e_date, txnType, txnStatus) => {
          setIsMaster(
            bankAcc?.find((ele) => ele.id === acc)?.account_type === "CURRENCY"
          );
          if (!acc && !s_date && !e_date && !txnType && !txnStatus) {
            setSelectedAccount("");
            setFilterDetails({
              date_from: null,
              date_to: null,
            });
            dispatch(
              get_all_payments({
                params: {
                  view: user ? undefined : "admin",
                  account_id: accountId ? accountId : undefined,
                },
                txns,
                isSubAcc,
              })
            );
          } else {
            if (bankAcc?.find((ele) => ele.id === acc)?.account_name)
              setSelectedAccount(
                `${
                  bankAcc?.find((ele) => ele.id === acc)?.account_name
                }-**${bankAcc
                  ?.find((ele) => ele.id === acc)
                  ?.account_number?.slice(-4)}`
              );
            else setSelectedAccount("");

            dispatch(
              get_all_payments(
                removeEmptyValues({
                  params: {
                    account_id: acc,
                    view: user ? undefined : "admin",
                  },
                  sort: filterDetails.sort,
                  filters: {
                    inserted_date: {
                      start_date: s_date,
                      end_date: e_date,
                    },
                    transaction_type: txnType,
                    status: txnStatus,
                  },
                  txns,
                  isSubAcc,
                })
              )
            );

            setFilterDetails((val) => ({
              ...val,
              date_from: s_date ? s_date : null,
              date_to: e_date ? e_date : null,
              acc,
              txnType,
              txnStatus,
            }));
          }
        }}
        filters={[
          {
            name:
              filterDetails?.date_from && filterDetails?.date_to
                ? `${moment(filterDetails?.date_from).format(
                    "MMM/YY"
                  )} - ${moment(filterDetails?.date_to).format("MMM/YY")}`
                : ``,
            onClick: () => {
              dispatch(
                get_all_payments(
                  removeEmptyValues({
                    params: {
                      account_id: filterDetails?.acc,
                      view: user ? undefined : "admin",
                    },
                    sort: filterDetails?.sort,
                    filters: {
                      transaction_type: filterDetails?.txnType,
                      status: filterDetails?.txnStatus,
                    },
                    txns,
                    isSubAcc,
                  })
                )
              );

              setFilterDetails((val) => ({
                ...val,
                date_from: null,
                date_to: null,
              }));
            },
          },
          {
            name: selectedAccount,
            onClick: () => {
              dispatch(
                get_all_payments(
                  removeEmptyValues({
                    params: {
                      view: user ? undefined : "admin",
                      account_id: accountId ? accountId : undefined,
                    },
                    sort: filterDetails.sort,
                    filters: {
                      inserted_date: {
                        start_date: filterDetails.date_from,
                        end_date: filterDetails.date_to,
                      },
                      transaction_type: filterDetails.txnType,
                      status: filterDetails.txnStatus,
                    },
                    txns,
                    isSubAcc,
                  })
                )
              );

              setFilterDetails((val) => ({
                ...val,
                acc: "",
              }));
              setSelectedAccount("");
            },
          },
          {
            name: filterDetails.txnType,
            onClick: () => {
              dispatch(
                get_all_payments(
                  removeEmptyValues({
                    params: {
                      account_id: filterDetails.acc,
                      view: user ? undefined : "admin",
                    },
                    sort: filterDetails.sort,
                    filters: {
                      inserted_date: {
                        start_date: filterDetails.date_from,
                        end_date: filterDetails.date_to,
                      },
                      status: filterDetails.txnStatus,
                    },
                    txns,
                    isSubAcc,
                  })
                )
              );

              setFilterDetails((val) => ({
                ...val,
                txnType: "",
              }));
            },
            key: "txnType",
          },
          {
            name: filterDetails.txnStatus,
            onClick: () => {
              dispatch(
                get_all_payments(
                  removeEmptyValues({
                    params: {
                      account_id: filterDetails.acc,
                      view: user ? undefined : "admin",
                    },
                    sort: filterDetails.sort,
                    filters: {
                      inserted_date: {
                        start_date: filterDetails.date_from,
                        end_date: filterDetails.date_to,
                      },
                      transaction_type: filterDetails.txnType,
                    },
                    txns,
                    isSubAcc,
                  })
                )
              );

              setFilterDetails((val) => ({
                ...val,
                txnStatus: "",
              }));
            },
            key: "txnStatus",
          },
        ]}
        handleSearch={(search_key) => {
          setSearch(search_key);
          setPage(1);
          dispatch(
            get_all_payments(
              removeEmptyValues({
                params: {
                  account_id: filterDetails?.acc,
                },
                search_key,
                page_number: 1,
                sort: filterDetails.sort,
                txns,
                isSubAcc,
                filters: {
                  inserted_date: {
                    start_date: filterDetails.date_from,
                    end_date: filterDetails.date_to,
                  },
                  transaction_type: filterDetails.txnType,
                  status: filterDetails.txnStatus,
                },
              })
            )
          );
        }}
        dotMenu={[
          "amount",
          "counterparty_account_name",
          // "settlement_date",
          "created_d_time",
          // "transaction_d_time",
        ]}
        isMore={true}
        handleSort={(val, sort) => {
          setFilterDetails((fltDetails) => ({
            ...fltDetails,
            sort: {
              sort_key: val,
              sort_order: sort ? -1 : 1,
            },
          }));
          setPage(1);
          dispatch(
            get_all_payments(
              removeEmptyValues({
                params: {
                  account_id: filterDetails?.acc,
                },
                search_key: search,
                page_number: 1,
                txns,
                isSubAcc,
                sort: {
                  sort_key: val,
                  sort_order: sort ? -1 : 1,
                },
                filters: {
                  inserted_date: {
                    start_date: filterDetails.date_from,
                    end_date: filterDetails.date_to,
                  },
                  transaction_type: filterDetails.txnType,
                  status: filterDetails.txnStatus,
                },
              })
            )
          );
        }}
      />
      <br />
      <TxnDetailModal
        open={txnOpen}
        txnDetail={txnDetail}
        onClose={() => {
          setTxnDetail({});
          setTxnOpen(false);
        }}
        isMaster={isMaster}
      />
      <TableComponent
        hasCheckbox={false}
        rowKey={(rec) => {
          return rec.id + rec.transaction_amount;
        }}
        columns={txns ? columns : ben_columns}
        data={Array.isArray(masterHistoryResponse) ? masterHistoryResponse : []}
        isTopFilter={false}
        className="tableStyled"
        topFilters={[
          { label: t("all_trans"), action: () => {} },
          { label: t("advance_search"), action: () => {} },
        ]}
        scroll={{
          x: "max-content",
        }}
        handleRowClick={(_e, rec) => {
          setTxnDetail(rec);
          setTxnOpen(true);
        }}
        pagination={{
          total: totalRecords || 0,
          current: page,
          onChange: (pagee) => {
            dispatch(
              get_all_payments(
                removeEmptyValues({
                  params: {
                    account_id: filterDetails?.acc,
                    view: user ? undefined : "admin",
                    page_number: pagee,
                  },
                  search_key: search ? search : undefined,
                  page_number: 1,
                  sort: filterDetails.sort,
                  txns,
                  isSubAcc,
                  filters: {
                    inserted_date: {
                      start_date: filterDetails.date_from,
                      end_date: filterDetails.date_to,
                    },
                    transaction_type: filterDetails.txnType,
                    status: filterDetails.txnStatus,
                  },
                })
              )
            );

            setPage(pagee);
          },
        }}
        renderEmpty={
          error?.error
            ? () => <NodataComponent msg={error?.message} noBtns />
            : undefined
        }
        renderMobItem={(item) => {
          return (
            <div class="recent-transaction table-sec">
              <div className="tx-det">
                <div class="avatar">
                  {item?.transaction_type === "credit"
                    ? item?.overparty_account_name?.substr(0, 2)
                    : item?.counterparty_account_name?.substr(0, 2)}
                </div>
                <div class="detail">
                  <div class="date-time">
                    {item?.inserted_date
                      ? moment(item?.inserted_date).format(
                          "MMM DD, YYYY, hh:mm A"
                        )
                      : "-"}
                  </div>
                  <div class="title">
                    <span className="ellipsis">
                      {item?.transaction_type === "credit"
                        ? item?.overparty_account_name
                        : item?.counterparty_account_name}
                    </span>
                    <span>
                      <img
                        style={{
                          transform: "rotate(180deg)",
                          marginLeft: 5,
                        }}
                        src={
                          item?.transaction_type === "credit" ? credit : debit
                        }
                        width={10}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div class="amount">
                <div>
                  {currencyMapper[item?.currency]}
                  {amountFormat(item?.amount)}
                </div>
              </div>
            </div>
          );
        }}
      />
    </>
  );
};

export default AccountTransaction;
