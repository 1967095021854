import React, { useEffect } from "react";
import { message, Table } from "antd";
import usePayAxios from "../../../config/useAxios";
import { gridApis } from "../../../config/GridApis";
import moment from "moment";

const rememberedDevicesColumns = [
  {
    title: "Browser",
    dataIndex: "browser",
    key: "browser",
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
  },
  {
    title: "Most Recent Activity",
    dataIndex: "activity",
    key: "activity",
  },
  {
    title: "IP Address",
    dataIndex: "ip",
    key: "ip",
  },
];

const biometricDevicesColumns = [
  {
    title: "Device Name",
    dataIndex: "device",
    key: "device",
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
  },
  {
    title: "Most Recent Activity",
    dataIndex: "activity",
    key: "activity",
  },
  {
    title: "IP Address",
    dataIndex: "ip",
    key: "ip",
  },
];

const activityHistoryColumns = [
  {
    title: "Event",
    dataIndex: "event",
    key: "event",
  },
  {
    title: "Source",
    dataIndex: "source",
    key: "source",
  },
  {
    title: "IP Address",
    dataIndex: "ip_address",
    key: "ip_address",
  },
  {
    title: "Date And Time",
    dataIndex: "date_time",
    key: "date_time",
    render: (text) => moment(text).format("DD-MM-YYYY HH:mm"),
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
  },
];

const rememberedDevicesData = [
  {
    key: "1",
    browser: "Chrome (Linux)",
    country: "United States",
    activity: "Current Session",
    ip: "127.0.0.1",
  },
  {
    key: "2",
    browser: "Firefox",
    country: "France",
    activity: "Oct 1 at 12:00 AM",
    ip: "127.0.0.1",
  },
  {
    key: "3",
    browser: "Safari (Mac OS X, 10.14)",
    country: "Finland",
    activity: "Sep 30 at 12:00 AM",
    ip: "2001:0db8:85a3:0000:0000:8a2e:0370:7334",
  },
  {
    key: "4",
    browser: "iOS App",
    country: "Cayman Islands",
    activity: "Oct 2, 2023 at 12:00 AM",
    ip: "2001:0db8:85a3:0000:0000:8a2e:0370:73",
  },
  {
    key: "5",
    browser: "Android App",
    country: "United States",
    activity: "Oct 2, 2023 at 12:00 AM",
    ip: "2001:0db8:85a3:0000:0000:8a2e:0370:7334",
  },
  {
    key: "6",
    browser: "Ramp or other service",
    country: "United States",
    activity: "Jun 2, 2023 at 12:00 AM",
    ip: "127.0.0.1",
  },
  {
    key: "7",
    browser: "PayPal, Amex, or other service",
    country: "United States",
    activity: "Jun 2, 2023 at 12:00 AM",
    ip: "127.0.0.1",
  },
];

const biometricDevicesData = [
  {
    key: "1",
    device: "iOS App (iOS)",
    country: "United States",
    activity: "Current Session",
    ip: "127.0.0.1",
  },
  {
    key: "2",
    device: "iOS App (iOS)",
    country: "France",
    activity: "Oct 1 at 12:00 AM",
    ip: "127.0.0.1",
  },
  {
    key: "3",
    device: "Android App (Android)",
    country: "Finland",
    activity: "Sep 30 at 12:00 AM",
    ip: "2001:0db8:85a3:0000:0000:8a2e:0370:7334",
  },
  {
    key: "4",
    device: "Ramp or other service",
    country: "Cayman Islands",
    activity: "Oct 2, 2023 at 12:00 AM",
    ip: "2001:0db8:85a3:0000:0000:8a2e:0370:73",
  },
];

const linkedProfilesColumns = [
  {
    title: "Profile",
    dataIndex: "profile",
    key: "profile",
  },
  {
    title: "Mercury Accounts",
    dataIndex: "accounts",
    key: "accounts",
  },
  {
    title: "Most Recent Activity",
    dataIndex: "activity",
    key: "activity",
  },
  {
    title: "IP Address",
    dataIndex: "ip",
    key: "ip",
  },
];

const linkedProfilesData = [
  {
    key: "1",
    profile: "graceh@gmail.com",
    accounts: "3 Accounts",
    activity: "Oct 2 at 2:21 PM",
    ip: "127.0.0.1",
  },
  {
    key: "2",
    profile: "grace@debug-llc.com",
    accounts: "1 Account",
    activity: "Oct 1 at 12:00 AM",
    ip: "127.0.0.1",
  },
];

const SecurityTables = () => {
  const { onCall, data } = usePayAxios({
    api: gridApis.getAllUserDevices,
    method: "get",
  });
  const { onCall: logoutById } = usePayAxios({
    api: gridApis.logoutDeviceById,
    method: "post",
  });
  const { onCall: getHistory, data: history } = usePayAxios({
    api: gridApis.getLoginHistory,
    method: "post",
  });

  useEffect(() => {
    getHistory({
      params: {
        pagination: false,
      },
    });
    onCall({});
  }, []);

  const activeSessionsColumns = [
    {
      title: "Browser",
      dataIndex: "device_type",
      key: "device_type",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (text, rec) =>
        `${rec?.country ? `${text}, ` : text ? text : "-"} ${
          rec?.country ?? ""
        }`,
    },
    {
      title: "Most Recent Activity",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => moment(text).format("MM/DD/YY"),
    },
    {
      title: "IP Address",
      dataIndex: "ip_address",
      key: "ip_address",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text) => (
        <button
          className="alt-btn"
          onClick={() => {
            logoutById({
              data: {
                device_id: text,
              },
            })
              .then((res) => {
                if (res?.error === false) {
                  onCall({})
                    .then(() => {
                      message.success(
                        <span className="messageText">{res?.message}</span>
                      );
                    })
                    .catch((_err) => {});
                }
              })
              .catch(() => {
                message.error(
                  <span className="messageText">
                    Failed to logout the device.
                  </span>
                );
              });
          }}
        >
          Logout
        </button>
      ),
    },
  ];

  return (
    <>
      <div className="alert-row flex-between">
        <div>
          <label>Active sessions</label>
          <span className="sub">
            All sessions currently logged in with {localStorage.getItem("user")}
            :
          </span>
        </div>
        {/* <button className="btn">Log Out Other Sessions</button> */}
      </div>
      <Table
        columns={activeSessionsColumns}
        dataSource={Array.isArray(data?.data) ? data?.data : []}
        pagination={false}
      />
      <br />
      <br />
      {/* <div className="alert-row flex-between">
        <div>
          <label>Linked profiles</label>
          <span className="sub">
            Linked profiles allow you to use multiple email addresses with
            Mercury. Once linked, you can switch between Mercury accounts
            without logging out. Learn more
          </span>
        </div>
        <button>Unlink All Profiles</button>
      </div> */}
      {/* <Table
        columns={linkedProfilesColumns}
        dataSource={linkedProfilesData}
        pagination={false}
      /> */}
      <br />
      <br />
      {/* <div className="alert-row flex-between">
        <div>
          <label>Remembered devices</label>
          <span className="sub">
            Two-factor authentication on these devices has been remembered for
            30 days.
          </span>
        </div>
        <button>Forget Other Devices</button>
      </div>
      <Table
        columns={rememberedDevicesColumns}
        dataSource={rememberedDevicesData}
        pagination={false}
      />
      <br />
      <br />
      <div className="alert-row flex-between">
        <div>
          <label>Registered devices with biometric login</label>
          <span className="sub">
            You enabled Touch / Face ID login on these devices.
          </span>
        </div>
        <button>Forget Biometrics</button>
      </div>
      <Table
        columns={biometricDevicesColumns}
        dataSource={biometricDevicesData}
        pagination={false}
      />
      <br />
      <br /> */}
      <div className="alert-row flex-between">
        <div>
          <label>Activity history</label>
          <span className="sub">
            Here are the last 30 days of activity on your account:
          </span>
        </div>
      </div>
      <Table
        columns={activityHistoryColumns}
        dataSource={Array.isArray(history?.data) ? history?.data : []}
        pagination={{
          pageSize: 5,
          showSizeChanger: false,
        }}
      />
    </>
  );
};

export default SecurityTables;
