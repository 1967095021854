import { message, Modal } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  APPROVE_PAYMENT,
  approvePayment,
  DELETE_PAYMENT,
  deletePayment,
  REJECT_PAYMENT,
  SUBMIT_PAYMENT,
  submitPayment,
} from "../../actions/payments";
import {
  initialize_payment,
  InitializePayment,
} from "../../actions/z_accounts";
import credit from "../../assets/icons/credit.png";
import debit from "../../assets/icons/debit.png";
import download from "../../assets/icons/file_download.png";
import pdfIcon from "../../assets/icons/pdf_file_icon.png";
import { apis } from "../../config/APIs";
import { gridApis } from "../../config/GridApis";
import { currencyMapper } from "../../config/helper";
import usePayAxios from "../../config/useAxios";
import useNotificationAxios from "../../config/useNotification";
import { amountFormat, getFileExtension } from "../../utils";
import { AccountDisplay } from "../Account/AccountCard";
import { useHandleResponseNode } from "../Cart/useHandleResponse";
import OTPComponent from "../Common/OTPComponent";
import eye from "../Payments/SendMoney/assets/eye.png";
import { p_types } from "../Payments/SendMoney/Recipients";
import RemovePayment from "../Reports/RemovePayment";
import { app_loading } from "../../actions/auth";
import { downloadFileDirectly } from "../Payments/SendMoney/PaymentReview";
import { APP_BASE_URL } from "../../config/Axios";
import EmailInput from "../Payments/SendMoney/EmailInput";

const ViewPaymentModal = ({
  open,
  onClose,
  txnDetail: data,
  callOnMount,
  hasWriteAccess = true,
  user,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("details");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deletePaymentModal, setDeletePaymentModal] = useState(false);
  const [policyViolations, setPolicyViolations] = useState({});
  const [modalType, setModalType] = React.useState("");

  const [isPreviewOpen, setIsPreviewOpen] = useState({
    open: false,
    url: "",
    fileType: "",
  });

  const [otp, setOtp] = useState({
    otpSent: false,
    otp: "",
  });
  const { onCall: downloadReceipt, loading: downloading } = usePayAxios({
    api: gridApis.generateReceiptPdf,
    method: "get",
  });

  const { onCall: verifyOtp, loading: verifyLoading } = useNotificationAxios({
    api: apis.payment_verify_otp,
    method: "post",
  });

  const { onCall: genOtp, loading } = useNotificationAxios({
    api: apis.gen_otp,
    method: "post",
  });
  const { onCall: budgetCheck, loading: budgetLoading } = usePayAxios({
    api: apis.budgetCriteriaFlowCheck,
    method: "post",
  });
  const payRes = useSelector((state) => state.z_accs?.initialize_payment);

  const reportsRejectResponse = useSelector(
    (state) => state.payments.reject_payment || {}
  );
  const reportsApproveResponse = useSelector(
    (state) => state.payments.approve_payment || {}
  );
  const deletePaymentResponse = useSelector(
    (state) => state?.payments?.delete_payment
  );
  const userReportSubmitResponse = useSelector(
    (state) => state.payments.submit_payment || {}
  );
  const [emailModal, setEmailModal] = useState(false);

  const handleConfirm = () => {
    dispatch(deletePayment({ id: data?.id, type: "COMPANY_PAYMENTS" }));
  };

  const onCancel = () => {
    setDeletePaymentModal(false);
  };

  useHandleResponseNode(
    InitializePayment,
    t("Payment Initialized Successfully."),
    "",
    payRes,
    () => {
      callOnMount();
      setOtp({
        otp: "",
        otpSent: false,
      });
    },
    false,
    () => {
      setOtp({
        otp: "",
        otpSent: false,
      });
    }
  );

  useHandleResponseNode(
    SUBMIT_PAYMENT,
    t("pay_submit_success"),
    "",
    userReportSubmitResponse,
    callOnMount
  );

  useHandleResponseNode(
    REJECT_PAYMENT,
    modalType === "remove_sch"
      ? "Schedule payment removed successfully."
      : "Payment rejected successfully.",
    "",
    reportsRejectResponse,
    () => {
      callOnMount(modalType === "remove_sch");
      setIsModalVisible(false);
    }
  );

  useHandleResponseNode(
    APPROVE_PAYMENT,
    "Payment approved successfully.",
    "",
    reportsApproveResponse,
    callOnMount
  );

  useHandleResponseNode(
    DELETE_PAYMENT,
    "Payment Deleted Successfully",
    "",
    deletePaymentResponse,
    () => {
      callOnMount();
      onClose();
    }
  );

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <RemovePayment
        open={isModalVisible && hasWriteAccess}
        type={modalType}
        onClose={handleCancel}
        id={data?.id}
      />
      {deletePaymentModal && (
        <Modal
          title="Delete"
          className="disconnect-modal"
          width={470}
          open={deletePaymentModal}
          onCancel={onCancel}
          maskClosable={false}
        >
          <>
            <p className="modal-text">Delete Payment</p>
            <div
              className="form-btm mt3"
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <button
                type="primary"
                className="send-btn cursor"
                onClick={handleConfirm}
                disabled={deletePaymentResponse?.loading}
              >
                <span>{t("confirm")}</span>
              </button>
              <button
                className="back-btn cursor"
                style={{ marginLeft: "2rem" }}
                onClick={onCancel}
              >
                <span>{t("cancel")}</span>
              </button>
            </div>
          </>
        </Modal>
      )}
      <Modal
        visible={isPreviewOpen?.open}
        onCancel={() => {
          setIsPreviewOpen({
            open: false,
            url: "",
            fileType: "",
          });
        }}
        footer={null}
        width="80%"
        centered
        className="preview_section_modal"
      >
        {isPreviewOpen?.fileType == "pdf" ? (
          <div style={{ width: "100%", height: "100vh" }}>
            <iframe
              src={isPreviewOpen?.url}
              allow="fullscreen"
              style={{ width: "100%", height: "100%", margin: "auto" }}
              title="description"
            ></iframe>
          </div>
        ) : (
          <div
            style={{ width: "100%", height: "100vh" }}
            className="d-flex-center"
          >
            <img
              src={isPreviewOpen?.url}
              alt="preview image"
              style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }}
            />
          </div>
        )}
      </Modal>
      {emailModal && (
        <Modal
          visible={emailModal}
          title="Send Payment Receipt"
          footer={[]}
          onCancel={() => {
            setEmailModal(false);
          }}
        >
          <EmailInput
            mainData={data}
            name={data?.counterparty_name}
            address={``}
            bank_location={``}
            bank_name={``}
            routing_code={
              data?.routing_code || data?.ifsc_code || data?.swift_code || ""
            }
            account_number={data?.account_number}
            cb={() => {
              setEmailModal(false);
            }}
          />
        </Modal>
      )}
      <Modal
        className="right-alinged-modal alerts txn-dt"
        title={
          <label
            style={{
              color: "#363636",
              paddingBottom: 10,
              fontSize: 21,
            }}
          >
            Payment Details
          </label>
        }
        visible={open}
        onCancel={() => {
          onClose();
          setOtp({
            otp: "",
            otpSent: false,
          });
        }}
      >
        {data?.transactions?.transactions_data?.status === "CANCELED" &&
          data?.transactions?.reason && (
            <div className="strip red">
              {t("reason_reject")}: {data?.transactions?.reason}
            </div>
          )}
        {/* <div className="action-buttons">
          <button
            className="back-btn"
            onClick={() => {
              const obj = {};
              obj.payment_id = data?.id;

              downloadReceipt({
                params: obj,
              });
            }}
            disabled={downloading}
          >
            {downloading ? "Downloading" : "Download"}
          </button>
          <button
            className="back-btn"
            onClick={() => {
              setEmailModal(true);
            }}
          >
            Email
          </button>
        </div> */}
        {(data?.payment_status?.toLowerCase() === "processed" ||
          data?.payment_status?.toLowerCase() === "success") && (
          <div className="btns-sec">
            <button
              className="share-btn"
              onClick={() => {
                const obj = {};
                obj.payment_order_id = data?.order_id;
                const searchParams = new URLSearchParams(obj);
                dispatch(app_loading(true));
                downloadFileDirectly(
                  APP_BASE_URL +
                    gridApis.generateReceiptPdf +
                    "?" +
                    searchParams?.toString(),
                  () => {
                    dispatch(app_loading(false));
                  }
                );
              }}
            >
              Download receipt
            </button>
            <button
              className="mlHalf share-btn"
              onClick={() => {
                setEmailModal(true);
              }}
            >
              Share
            </button>
          </div>
        )}
        <div className="flex-between txn-head">
          <div className="left">
            <span
              className="circle"
              style={{
                "--bg-txn":
                  data.transaction_type === "credit" ? "#EDFFEA" : "#FFEAEA",
              }}
            >
              <img
                src={data.transaction_type === "credit" ? credit : debit}
                width={10}
              />{" "}
            </span>
            <label>
              {data.transaction_type === "credit"
                ? "Received Money"
                : "Sent Money"}
              <br />
              {data.transaction_type === "credit" ? "from " : "to "}

              <b>
                {data?.payee_name} (****
                {data?.payee_account_no?.slice?.(-4)})
              </b>
              <br />
              <span
                className={`status status_${data.payment_status?.toLowerCase()}`}
              >
                {data.payment_status}
              </span>
            </label>
          </div>
          <div className="right">
            <h2
              style={{
                textAlign: "right",
              }}
            >
              {currencyMapper[data?.currency_code]} {amountFormat(data.amount)}
            </h2>
            {data.transaction_account_fee ? (
              <>
                <span className="dim">
                  Amount:
                  <label>
                    {currencyMapper[data?.currency_code]}{" "}
                    {amountFormat(data.amount - data.transaction_account_fee)}
                  </label>
                </span>
                <span className="dim">
                  Transaction Fee:
                  <label>
                    {currencyMapper[data?.currency_code]}{" "}
                    {amountFormat(data.transaction_account_fee)}
                  </label>{" "}
                </span>
              </>
            ) : null}
          </div>
        </div>
        <ul className="tab-menu">
          <li
            className={activeTab === "details" ? "active" : ""}
            onClick={() => setActiveTab("details")}
          >
            {otp.otpSent ? "OTP" : "Details"}
          </li>
          {data?.attachments?.length ? (
            <li
              className={activeTab === "attach" ? "active" : ""}
              onClick={() => setActiveTab("attach")}
            >
              Attachments
            </li>
          ) : null}
          {/* <li
          className={activeTab === "history" ? "active" : ""}
          onClick={() => setActiveTab("history")}
        >
          History
        </li>
        <li
          className={activeTab === "comments" ? "active" : ""}
          onClick={() => setActiveTab("comments")}
        >
          Comments
        </li> */}
        </ul>
        {activeTab === "attach" && (
          <div className="imgs-wrap">
            {data?.attachments?.map((ele) => {
              return (
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <div className="img">
                    <img
                      src={
                        ["csv", "xls", "xlsx"].includes(
                          getFileExtension(ele.filename)
                        )
                          ? "https://cdn-icons-png.flaticon.com/256/732/732220.png"
                          : ["doc", "docx"].includes(
                              getFileExtension(ele.filename)
                            )
                          ? "https://cdn-icons-png.flaticon.com/512/732/732226.png"
                          : getFileExtension(ele.filename) === "pdf"
                          ? pdfIcon
                          : ele.url
                      }
                    />
                    {["pdf", "jpeg", "jpg", "png"]?.includes(
                      getFileExtension(ele.filename)
                    ) ? (
                      <img
                        src={eye}
                        alt=""
                        className="file-eye"
                        onClick={() => {
                          setIsPreviewOpen({
                            open: true,
                            url: ele.url,
                            fileType:
                              getFileExtension(ele.filename) === "pdf"
                                ? "pdf"
                                : "",
                          });
                        }}
                      />
                    ) : (
                      <img
                        src={download}
                        alt=""
                        className="file-download"
                        onClick={() => {
                          window.open(ele.url, "_blank");
                        }}
                        width={15}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {activeTab === "details" && (
          <>
            {otp?.otpSent ? (
              <div className="">
                <div className="flex-center flex-col w100">
                  <div className="otp_info" style={{ maxWidth: "80%" }}>
                    <div>
                      <b
                        className="mt-2 mb-1"
                        style={{
                          fontSize: "1.6rem",
                        }}
                      >
                        Enter the OTP
                      </b>
                    </div>
                    <span>
                      Check your email!{" "}
                      <span style={{ fontWeight: "800" }}>
                        {localStorage.getItem("user")}
                      </span>{" "}
                      We've sent an OTP to your linked email.
                    </span>
                  </div>
                  <OTPComponent
                    source={"PAYMENT"}
                    label={"Enter the OTP"}
                    hideTitle={true}
                    value={otp.otp}
                    userData={otp}
                    token={otp.token}
                    loading={verifyLoading}
                    onChange={(otp) => {
                      setOtp((o) => ({
                        ...o,
                        otp,
                      }));
                    }}
                    setToken={(token) => {
                      setOtp((o) => ({
                        ...o,
                        otp: {
                          ...o.otp,
                          token,
                        },
                      }));
                    }}
                  />
                  <button
                    className="send-btn mt2"
                    id="otp"
                    disabled={verifyLoading}
                    onClick={() => {
                      if (Object.keys(policyViolations).length === 0) {
                        verifyOtp({
                          data: {
                            user_id: localStorage.getItem("user_id"),
                            email_id: localStorage.getItem("user"),
                            source: "PAYMENT",
                            otp: otp.otp,
                            otp_token: otp.token,
                          },
                        })
                          .then((res) => {
                            if (res.error === false) {
                              const payload = {
                                amount: Number(data?.amount),
                                currency_code: data?.currency_code,
                                sender_account_id: data?.sender_account_id,
                                beneficiary_id: data?.record_id,
                                detail_name: data.description,
                                recipient_amount: Number(data?.amount),
                                sender_amount: data?.bc_amount ?? 0,
                                exchange_fee: data?.exchange_fee ?? 0,
                                exchange_rate: data?.exchange_rate ?? 0,
                                purpose_code: data?.purpose ?? 1,
                                account_mode_type:
                                  data?.mode_of_payment === "WITHIN_BANK"
                                    ? "WITHINBANK"
                                    : data?.mode_of_payment,
                              };
                              dispatch(initialize_payment(payload));
                            }
                          })
                          .catch((err) => {
                            message.error(
                              <span className="messageText">
                                {err?.response?.data?.message}
                              </span>
                            );
                          });
                      }
                    }}
                  >
                    Verify OTP
                  </button>
                </div>
              </div>
            ) : (
              <div className="company-details txn-details">
                <h3> Payment Details </h3>
                <div className="item">
                  <label>Transaction Reference Number</label>
                  <span>{data.transactions?.payment_id}</span>
                </div>
                <div className="item">
                  <label>Reference Number</label>
                  <span>{data.reference_number}</span>
                </div>
                <div className="item">
                  <label>Transaction Type</label>
                  <span
                    className="i-flex-center"
                    style={{
                      color: "#333",
                      textTransform: "capitalize",
                    }}
                  >
                    <img
                      src={debit}
                      width={10}
                      style={{
                        transform: "rotate(180deg)",
                      }}
                    />{" "}
                    Debit
                  </span>
                </div>
                <div className="item">
                  <label>Status</label>
                  <b
                    className={`status status_${data.payment_status?.toLowerCase()}`}
                    style={{
                      paddingRight: 0,
                    }}
                  >
                    {data.payment_status}
                  </b>
                </div>
                <div className="item">
                  <label>Payment Date</label>
                  <span>
                    {moment(data.created_at).format("MMM DD, YYYY, hh:mm A")}
                  </span>
                </div>
                <div className="item">
                  <label>Transfer Mode</label>
                  <span>
                    {
                      [
                        ...p_types,
                        {
                          label: "ACH - Local Transfer",
                          value: "ACH_LOCAL",
                        },
                      ]?.find(
                        (ele) =>
                          ele.value === data.mode_of_payment ||
                          (data.mode_of_payment === "WITHINBANK" &&
                            ele.value === "WITHIN_BANK")
                      )?.label
                    }
                  </span>
                </div>

                <div className="item">
                  <label>Description</label>
                  <span>{data?.transactions?.detail_name}</span>
                </div>

                <div className="item">
                  <label>Notes</label>
                  <span>{data.description}</span>
                </div>
                <h3> Recipient Details </h3>
                <div className="item">
                  <label>Account Name</label>
                  <span>{data.payee_name}</span>
                </div>
                <div className="item">
                  <label>Account Number</label>
                  <span>
                    <AccountDisplay
                      accountNumber={data.payee_account_no}
                      showEye={true}
                    />
                  </span>
                </div>
                <div className="item">
                  <label>Routing / SWIFT Code</label>
                  <span>
                    {data?.recipient_account_details?.routing_code ??
                      data?.recipient_account_details?.swift_code}
                  </span>
                </div>
                <div className="item">
                  <label>Type</label>
                  <span style={{ textTransform: "capitalize" }}>
                    {data?.beneficiary_info?.recipient_type === "legal"
                      ? "Business"
                      : data?.beneficiary_info?.recipient_type}
                  </span>
                </div>
                <div className="item">
                  <label>Address</label>
                  <span>
                    {data?.recipient_shipping_addresses && (
                      <>
                        {data?.recipient_shipping_addresses?.address}
                        {", "}
                        {data?.recipient_shipping_addresses?.address_two}
                        <br />
                      </>
                    )}
                    {data?.recipient_shipping_addresses?.city && (
                      <>
                        {data?.recipient_shipping_addresses?.city}
                        <br />
                      </>
                    )}
                    {data?.recipient_shipping_addresses?.state && (
                      <>
                        {data?.recipient_shipping_addresses?.state}
                        <br />
                      </>
                    )}
                    {data?.recipient_shipping_addresses?.country && (
                      <>
                        {data?.recipient_shipping_addresses?.country}
                        <br />
                      </>
                    )}
                    {data?.recipient_shipping_addresses?.zipcode && (
                      <>{data?.recipient_shipping_addresses?.zipcode}</>
                    )}
                  </span>
                </div>
                <h3> Sender Details </h3>
                <div className="item">
                  <label>Account Name</label>
                  <span>
                    {data?.transactions?.transactions_data
                      ?.overparty_account_name
                      ? data?.transactions?.transactions_data
                          ?.overparty_account_name
                      : data?.sender_account_details?.account_name}
                  </span>
                </div>
                <div className="item">
                  <label>Account Number</label>
                  <span>
                    <AccountDisplay
                      accountNumber={
                        data?.transactions?.transactions_data
                          ?.overparty_account_number
                          ? data?.transactions?.transactions_data
                              ?.overparty_account_number
                          : data?.sender_account_details?.account_number
                      }
                      showEye={true}
                    />
                  </span>
                </div>
                <div className="item">
                  <label>Routing / SWIFT Code</label>
                  <span>{data?.sender_account_details?.routing_code}</span>
                </div>
                {/* <div className="item">
                  <label>BIC Code</label>
                  <span>{data?.sender_account_details?.bic_code}</span>
                </div> */}
              </div>
            )}
          </>
        )}
        {otp?.otpSent ? null : (
          <div className="report-btns">
            {data?.flow_status === "approved" &&
              data?.payment_status === "initiated" && (
                <button
                  className="send-btn"
                  onClick={() => {
                    budgetCheck({
                      data: {
                        amount: data.amount + "",
                      },
                    })
                      .then((res) => {
                        let obj = {};
                        if (res?.data?.errorPolicy) {
                          Object.keys(res?.data?.errorPolicy).forEach((ele) => {
                            if (
                              res?.data?.limitUpto[ele] &&
                              res?.data?.errorPolicy[ele]
                            )
                              obj[
                                ele
                              ] = `Policy violation (${ele}): Amount for ${res?.data?.errorPolicy[ele]}  exceeds the limit of $ ${res?.data?.limitUpto[ele]}`;
                          });
                        } else if (res?.error && res?.message) {
                          message.error(
                            <span className="errorMsg">{res?.message}</span>
                          );
                        }
                        if (
                          res.error === false &&
                          Object.keys(obj).length === 0
                        ) {
                          genOtp({
                            data: {
                              user_id: localStorage.getItem("user_id"),
                              email_id: localStorage.getItem("user"),
                              source: "PAYMENT",
                            },
                          })
                            .then((res) => {
                              if (res.error === false) {
                                message.success(
                                  <span className="messageText">
                                    {res?.message}
                                  </span>
                                );
                                setOtp({
                                  ...otp,
                                  otpSent: true,
                                  token: res?.data,
                                });
                              }
                              if (res.error) {
                                message.error(
                                  <span className="messageText">
                                    {res?.message}
                                  </span>
                                );
                              }
                            })
                            .catch((err) => {
                              message.success(
                                <span className="messageText">
                                  {err?.response?.data?.message}
                                </span>
                              );
                            });
                        }
                        setPolicyViolations(obj);
                      })
                      .catch((err) => {
                        if (err.response?.data?.message) {
                          message.error(
                            <span className="messageText">
                              {err?.response?.data?.message}
                            </span>
                          );
                        }
                      });
                  }}
                >
                  {t("pay")}
                </button>
              )}
            {data?.schedule_payment_date &&
              data?.payment_status === "scheduled" && (
                <button
                  className="reject"
                  onClick={() => {
                    setIsModalVisible(true);
                    setModalType("remove_sch");
                  }}
                >
                  {t("Cancel Payment")}
                </button>
              )}
            {!user ? (
              <>
                {data?.is_editable &&
                data?.flow_status === "pending_approval" ? (
                  <>
                    <button
                      className="approve"
                      disabled={
                        reportsApproveResponse?.loading || !hasWriteAccess
                      }
                      onClick={() => {
                        dispatch(
                          approvePayment({
                            id: data.id,
                            type: "COMPANY_PAYMENTS",
                          })
                        );
                      }}
                    >
                      {t("approve")}
                    </button>
                    <button
                      disabled={!hasWriteAccess}
                      className="reject"
                      onClick={() => {
                        setIsModalVisible(true);
                        setModalType("delete");
                      }}
                    >
                      {t("reject")}
                    </button>
                  </>
                ) : null}
              </>
            ) : (
              <>
                {data?.role_status === "unsubmitted" && (
                  <>
                    <button
                      className="approve"
                      disabled={
                        userReportSubmitResponse?.loading || !hasWriteAccess
                      }
                      onClick={() => {
                        dispatch(
                          submitPayment({
                            type: "COMPANY_PAYMENTS",
                            id: data.id,
                          })
                        );
                      }}
                    >
                      Submit
                    </button>
                    <button
                      disabled={!hasWriteAccess}
                      className="delete"
                      onClick={() => {
                        setDeletePaymentModal(true);
                      }}
                    >
                      Delete
                    </button>
                  </>
                )}
                {/* {data?.recallable &&
              data?.flow_status !== "unsubmitted" &&
              data?.flow_status !== "rejected" &&
              user ? (
                <>
                  <button
                    className="reject"
                    disabled={
                      userReportRecallResponse?.loading || !hasWriteAccess
                    }
                    onClick={() =>
                      dispatch(
                        recallPayment({
                          id: data.id,
                          type: "COMPANY_PAYMENTS",
                        })
                      )
                    }
                  >
                    Recall
                  </button>
                </>
              ) : null} */}
              </>
            )}
          </div>
        )}
      </Modal>
    </>
  );
};

export default ViewPaymentModal;
