import { Modal } from "antd";
import React, { useEffect, useState, useCallback, useRef } from "react";
import time from "../../assets/icons/time.png";
import session_time from "../../assets/icons/session_time.png";
import { logout } from "../../actions/auth";
import { useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import usePayAxios from "../../config/useAxios";
import { apis } from "../../config/APIs";
import jwtDecode from "jwt-decode";

const IdleLogout = () => {
  const [isIdle, setIsIdle] = useState(false);
  const [modal, setModal] = useState(false);
  const [timer, setTimer] = useState(30);
  const interval = useRef();
  const timeout = 15 * 60 * 1000; // 5 minutes in milliseconds
  const { onCall, loading } = usePayAxios({
    api: apis.refreshToken,
    method: "post",
  });
  const { reset, pause } = useIdleTimer({
    onIdle: () => {
      const token = localStorage.getItem("token");
      if (token) handleOnIdle();
    },
    onAction: () => {
      const expiry = localStorage.getItem("expiresAt");
      if (expiry) {
        const currentTime = new Date().getTime();
        if (currentTime > Number(expiry) * 1000 - 30000) {
          if (!loading)
            onCall({
              data: {
                token: localStorage.getItem("token"),
              },
            }).then((res) => {
              if (res?.data) {
                const decoded = jwtDecode(res.data);
                localStorage.setItem("expiresAt", decoded.exp);
                localStorage.setItem("token", res.data);
              }
            });
        }
      }
    },
    timeout: timeout - 30000,
    throttle: 500,
  });

  const dispatch = useDispatch();

  const handleLogout = () => {
    setModal(false);
    setIsIdle(false);
    setTimer(30);
    dispatch(logout());
  };

  function handleOnIdle() {
    setIsIdle(true);
    setModal(true);
    pause();
  }

  useEffect(() => {
    if (isIdle) {
      interval.current = setInterval(() => {
        setTimer((b) => {
          if (b - 1 <= 0) {
            handleLogout();
            clearInterval(interval.current);
          }
          return b - 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(interval.current);
    };
  }, [isIdle]);

  return (
    <Modal
      visible={modal}
      title={
        <div className="flex-title">
          <img src={time} width={18} /> Session Timeout
        </div>
      }
      onCancel={() => {
        setModal(false);
      }}
      footer={[]}
    >
      <div className="session-timeout">
        <div className="content">
          <div>
            <label>Your session is about to expire!</label>
            <span>
              You will be logged out in <b>{timer}s</b>
            </span>
            <span>Do you want to stay signed in?</span>
          </div>
          <div>
            <img src={session_time} />
          </div>
        </div>
        <div className="action-btns">
          <button
            className="back-btn"
            onClick={() => {
              setModal(false);
              reset();
              clearInterval(interval.current)
              const expiry = localStorage.getItem("expiresAt");
              if (expiry) {
                const currentTime = new Date().getTime();
                if (currentTime > Number(expiry) * 1000 - 30000) {
                  if (!loading)
                    onCall({
                      data: {
                        token: localStorage.getItem("token"),
                      },
                    }).then((res) => {
                      if (res?.data) {
                        const decoded = jwtDecode(res.data);
                        localStorage.setItem("expiresAt", decoded.exp);
                        localStorage.setItem("token", res.data);
                      }
                    });
                }
              }
            }}
          >
            Keep me signed in
          </button>
          <button
            className="send-btn"
            onClick={() => {
              handleLogout();
            }}
          >
            Logout
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default IdleLogout;
