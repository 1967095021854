import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import { Button, Col, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { headerTitles, SideMenu } from "../../actions/masterdata/masterdata";
import { get_all_payments } from "../../actions/z_accounts";
import fund from "../../assets/icons/fund.png";
import plus_b from "../../assets/images/plus_circle_b.png";
import { apis } from "../../config/APIs";
import { gridApis } from "../../config/GridApis";
import { currencyMapper } from "../../config/helper";
import usePayAxios from "../../config/useAxios";
import { amountFormat, isPayments } from "../../utils";
import { AccountDetails, AccountSummary } from "./AccDetailCard";
import AccountTransaction from "./AccountTransaction";
import ChildAccounts from "./ChildAccounts";
import Transfer from "./Transfer";

const AccountDetail = ({ hasWriteAccess }) => {
  const location = useLocation();
  const history = useHistory();
  const [showBalance, setShowBalance] = useState(false);
  const [tab, setTab] = React.useState("details");
  const [openTransfer, setOpenTransfer] = useState(false);
  const [detailView, setDetailView] = useState(false);
  const [selectAcc, setSelectAcc] = useState({});
  const dispatch = useDispatch();
  const { onCall } = usePayAxios({
    api: gridApis.getByID,
    method: "get",
  });
  const {
    onCall: masterCall,
    data: masterData,
    loading,
  } = usePayAxios({
    api: apis.accountBalanceByMasterID,
    method: "get",
  });
  const { onCall: getSubAccs, data: subAccs } = usePayAxios({
    api: gridApis.fetchAllSubAccounts,
    method: "post",
  });

  useEffect(() => {
    const locationSplit = location.pathname?.split("/");
    dispatch(
      headerTitles({
        title: "Accounts",
        description: "Manage all your global accounts.",
        hasBack: true,
        handleBack: () => {
          history.goBack();
        },
      })
    );
    dispatch({
      type: SideMenu,
      payload: {
        firstNav: "/" + locationSplit?.[1],
        secondNav: "",
        showChildren: false,
        childrenMenu: "",
      },
    });
    if (location?.state?.isSub) {
      setDetailView(location?.state?.account);
      setSelectAcc(location?.state?.account);
    } else {
      onCall({
        params: {
          account_id: location?.state?.account?.id,
        },
      }).then((res) => {
        setDetailView(res?.data);
        setSelectAcc(res?.data);
        getSubAccs({
          params: {
            per_page: 5,
          },
          data: {
            master_account_id: res?.data?.bank_account_id,
          },
        });
      });
    }
    if (location?.state?.isSub) setTab("txns");
    else setTab("details");
  }, [location?.state]);

  return (
    <div className="account-details">
      <Modal
        visible={openTransfer}
        className="right-alinged-modal"
        onCancel={() => {
          setOpenTransfer(false);
        }}
        title={"Transfer"}
      >
        {openTransfer && (
          <Transfer
            setOpenAddNew={setOpenTransfer}
            from={detailView?.id}
            cb={() => {
              onCall({
                params: {
                  account_id: location?.state?.account?.id,
                },
              }).then((res) => {
                setDetailView(res?.data);
              });
              dispatch(
                get_all_payments({
                  params: {
                    account_id: location?.state?.account?.id,
                  },
                  txns: true,
                })
              );
            }}
          />
        )}
      </Modal>
      <div style={{ padding: "20px" }}>
        {location?.state?.isSub ? null : (
          <>
            <Row justify="space-between" align="middle">
              <Col>
                <div className="send-payment" style={{ flexGrow: 1 }}>
                  <div className="item">
                    <span className="label">Available Balance</span>
                    <span className="value font-big">
                      <>
                        ${" "}
                        {showBalance ? (
                          selectAcc?.account_type === "CURRENCY" ? (
                            loading ? (
                              <span className="shimmer">
                                <span
                                  style={{
                                    width: "12rem",
                                    height: "2.4rem",
                                    marginLeft: 10,
                                  }}
                                ></span>
                              </span>
                            ) : (
                              amountFormat(
                                masterData?.data?.[0]?.availableBalanceAmount
                              )
                            )
                          ) : (
                            amountFormat(selectAcc?.balance?.balance)
                          )
                        ) : (
                          "******".toUpperCase()
                        )}
                        <Button
                          className="eye mlHalf"
                          type="link"
                          icon={
                            !showBalance ? (
                              <EyeInvisibleFilled />
                            ) : (
                              <EyeFilled />
                            )
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            const accId = selectAcc?.id;
                            if (selectAcc?.account_type === "CURRENCY") {
                              if (accId)
                                masterCall({
                                  params: {
                                    account_id: accId,
                                  },
                                });
                            }
                            setShowBalance((bal) => !bal);
                          }}
                        />
                      </>
                    </span>
                    <span className="label">
                      Your available balance in USD as of now.
                    </span>
                  </div>
                </div>
              </Col>
              <Col>
                <Row gutter={16} align="middle">
                  {/* <Col>
                <div>
                  <Input
                    className="pp-input"
                    size="large"
                    id="search-input"
                    placeholder="Search..."
                    prefix={
                      <img src={search} alt="" style={{ height: "14px" }} />
                    }
                    style={{
                      backgroundColor: "#FAFAFA",
                      border: "1px solid #EAEAEA",
                      borderRadius: "5px",
                      minWidth: "22rem",
                    }}
                  />
                </div>
              </Col> */}
                  {/* <Col>
                    <button
                      className="send-btn"
                      type="primary"
                      onClick={() => {
                        setOpenTransfer(true);
                      }}
                      disabled={!hasWriteAccess}
                    >
                      Transfer Funds
                    </button>
                  </Col> */}
                  <Col>
                    <button
                      className="send-btn"
                      onClick={() => {
                        history.push("/transfers/beneficiary/payments", {
                          from: location?.state?.account?.id,
                          send: true,
                        });
                      }}
                      disabled={!isPayments()}
                    >
                      <img src={plus_b} width={15} />
                      Send Money
                    </button>
                  </Col>

                  {/* Grid Icon */}
                  {/* <Col style={{ paddingRight: 0 }}>
                <button
                  className="sec-btn"
                  onClick={() => {
                    setShowList((b) => !b);
                  }}
                >
                  {showList ? <UnorderedListOutlined /> : <AppstoreOutlined />}
                </button>
              </Col> */}
                </Row>
              </Col>
            </Row>
            <div className="account-summary">
              <div className="acc-details">
                <div className="detail">
                  <img src={fund} style={{ width: "3rem" }} />
                  <div>
                    <span className="label">Reserved Balance</span>
                    <span className="value">
                      {currencyMapper[masterData?.data?.[0]?.currencyCode] ??
                        "$"}{" "}
                      {showBalance ? (
                        loading ? (
                          <label className="shimmer">
                            <span
                              style={{
                                width: "10rem",
                                height: "1.5rem",
                                marginLeft: 10,
                              }}
                            ></span>
                          </label>
                        ) : (
                          amountFormat(masterData?.data?.[0]?.reservedAmount)
                        )
                      ) : (
                        "******".toUpperCase()
                      )}
                    </span>
                  </div>
                </div>
                <div className="detail">
                  <img src={fund} style={{ width: "3rem" }} />
                  <div>
                    <span className="label">Total Balance</span>
                    <span className="value">
                      {currencyMapper[masterData?.data?.[0]?.currencyCode] ??
                        "$"}{" "}
                      {showBalance ? (
                        loading ? (
                          <label className="shimmer">
                            <span
                              style={{
                                width: "5rem",
                                height: "1.5rem",
                                marginLeft: 10,
                              }}
                            ></span>
                          </label>
                        ) : (
                          amountFormat(masterData?.data?.[0]?.balanceAmount)
                        )
                      ) : (
                        "******".toUpperCase()
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {/* {detailView ? ( */}
      {location?.state?.isSub ? <AccountSummary account={selectAcc} /> : null}
      <>
        {/* <BankAccount
          acc={detailView}
          subAccs={subAccs?.data}
          fromDetail={true}
          // setSelectAcc={setSelectAcc}
        /> */}
        {location?.state?.isSub ? null : (
          <div className="tabs-sec">
            <ul>
              {[
                { key: "details", label: "Account Details" },
                { key: "txns", label: "Transactions" },
                {
                  key: "associated-accounts",
                  label: `Virtual Accounts ${
                    subAccs?.total_count ? `(${subAccs?.total_count})` : ""
                  }`,
                },
              ]
                ?.filter((ele) =>
                  detailView?.account_type === "CURRENCY"
                    ? ele.key
                    : ele.key !== "associated-accounts"
                )
                .map((ele) => (
                  <li
                    onClick={() => {
                      setTab(ele.key);
                    }}
                    className={`${tab === ele.key ? "active" : ""}`}
                  >
                    {ele.label}
                  </li>
                ))}
            </ul>
          </div>
        )}
        {tab === "details" && (
          <>
            <AccountDetails account={selectAcc} />
            {/* <AccountInfo account={selectAcc} /> */}
          </>
        )}
        {tab === "txns" && (
          <div className="m1">
            <AccountTransaction
              noAccFil={true}
              from="Account"
              accountId={selectAcc?.id}
              txns={true}
              isSubAcc={location?.state?.isSub}
            />
          </div>
        )}
        {tab === "associated-accounts" && (
          <ChildAccounts
            data={subAccs?.data}
            total={subAccs?.total_count}
            onSearch={(val) => {
              getSubAccs({
                params: {
                  per_page: 5,
                },
                data: {
                  master_account_id: selectAcc?.bank_account_id,
                  search_key: val,
                },
              });
            }}
            onPage={(val, search) => {
              getSubAccs({
                params: {
                  page_number: val,
                  per_page: 5,
                },
                data: {
                  master_account_id: selectAcc?.bank_account_id,
                  search_key: search ? search : undefined,
                },
              });
            }}
          />
        )}
      </>
      {/*  ) : (
         <>
           {account?.balances?.map((balance) => (
             <div className="bal card w100 mb2">
               <Row
                 style={{
                   gap: "4rem",
                   alignItems: "center",
                 }}
                 className="bal-details"
               >
                 <Col
                   style={{
                     position: "relative",
                     marginRight: 40,
                   }}
                 >
                   <div className="bal-sec">
                     <Text className="avl-bal">Available Balance</Text>
                     <h3>
                       {`${
                         currencyMapper[balance?.currencyCode] ?? "$"
                       } ${amountFormat(balance?.availableBalanceAmount)}`}
                     </h3>
                     <Text className="avl-bal">USD • Main</Text>
                   </div>
                   <div className="v-line" />
                 </Col>
                 <Col>
                   <Text className="avl-bal">Type</Text>
                   <div className="value">{account?.accountTypeCode}</div>
                 </Col>
                 <Col>
                   <Text className="avl-bal">Balance</Text>
                   <div className="value">
                     {`${currencyMapper[balance?.currencyCode] ?? "$"}
                   ${amountFormat(balance?.balanceAmount)}`}
                   </div>
                 </Col>
                 <Col>
                   <Text className="avl-bal">Reserved Amount</Text>
                   <div className="value">{`${
                     currencyMapper[balance?.currencyCode] ?? "$"
                   }
                   ${amountFormat(balance?.reservedAmount)}`}</div>
                 </Col>
               </Row>
             </div>
           ))}
           {showList ? (
             <AccountCard
               onClick={(data) => setDetailView(data)}
               data={bankAcc}
             />
           ) : (
             <div className="paycraft-table">
               <Table
                 columns={columns}
                 dataSource={bankAcc?.filter(
                   (ele) =>
                     (ele.masterAccountId === account?.accountId ||
                       ele.accountId === account?.accountId) &&
                     ele.statusCode === "ACTIVE"
                 )}
                 pagination={false}
                 onRow={(data) => {
                   return {
                     onClick: (_event) => setDetailView(data),
                   };
                 }}
               />
             </div>
           )}
         </>
       )} */}
    </div>
  );
};

export default AccountDetail;
