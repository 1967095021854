import { Button, Space, Typography } from "antd";
import { default as React } from "react";
import copy from "./assets/copy.svg";
import bank from "./assets/bank.png";
import { handleCopy } from "../../../utils";

const { Title, Text, Paragraph } = Typography;
const Wire = ({ paymentDetails }) => {
  return (
    <div className="ach-section">
      <Paragraph className="sub-text">
        If you are filling out a wire form, please reference the section labels
        with MT103 field numbers in grey.
      </Paragraph>

      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <div className="ben-details attachment-wrap">
          {/* Beneficiary */}
          <div className="attachment-item">
            <Title level={5}>
              <img src={bank} width={18} />
              <p>
                <span>Receiving/Beneficiary Bank</span>
                <label className="dim">
                  Section 57D Account with Institution
                </label>
              </p>
            </Title>
            <div>
              <Paragraph>
                <strong>BIC / SWIFT Code:</strong>
                <span>
                  {paymentDetails?.ourparty_swiftcode}
                  <img
                    src={copy}
                    width={15}
                    onClick={() => {
                      handleCopy(paymentDetails?.ourparty_swiftcode);
                    }}
                  />
                </span>
              </Paragraph>
              <Paragraph>
                <strong>Routing Number:</strong>{" "}
                <span>
                  {paymentDetails?.ourparty_routing_code}
                  <img
                    src={copy}
                    width={15}
                    onClick={() => {
                      handleCopy(paymentDetails?.ourparty_routing_code);
                    }}
                  />
                </span>
              </Paragraph>
              <Paragraph>
                <strong>Bank Name:</strong>
                <span>{paymentDetails?.ourparty_bank_name}</span>
              </Paragraph>
              <Paragraph>
                <strong>Bank Address:</strong>
                <span>{paymentDetails?.ourparty_address}</span>
              </Paragraph>
            </div>
          </div>
          {/* Receiving Bank Details */}
          <div className="attachment-item">
            <Title level={5}>
              <img src={bank} width={18} />
              <p>
                <span>Beneficiary</span>
                <label className="dim">
                  Section 59 Beneficiary Customer Name & Address
                </label>
              </p>
            </Title>
            <div>
              <Paragraph>
                <strong>Beneficiary Name:</strong>
                <span>{paymentDetails?.counterparty_name}</span>
              </Paragraph>
              <Paragraph>
                <strong>Type of account:</strong>
                <span>Company</span>
              </Paragraph>
              <Paragraph>
                <strong>Beneficiary Address:</strong>
                <span>{paymentDetails?.counterparty_address}</span>
              </Paragraph>
            </div>
          </div>
        </div>
      </Space>
    </div>
  );
};

export default Wire;
