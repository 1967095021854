import {
  EyeFilled,
  EyeInvisibleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input } from "antd";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { currencyMapper } from "../../config/helper";
import { amountFormat } from "../../utils";
import { AccountList } from "./AccountCard";
import { Pagination } from "antd";
const ChildAccounts = ({ data: list, onSearch, total, onPage }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    onPage(page)
  };

  const [visibleBalances, setVisibleBalances] = useState({});
  const history = useHistory();
  const ref = useRef();
  return (
    <div className="mt1">
      <div
        style={{
          paddingLeft: "7px",
          display: "flex",
          direction: "row",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <div className="send-payment" style={{ flexGrow: 1 }}>
          {" "}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div className="mr1">
            <Input
              className="pp-input"
              size="large"
              onChange={(e) => {
                if (ref.current) clearTimeout(ref.current);

                ref.current = setTimeout(() => {
                  onSearch(e.target.value);
                }, 700);
              }}
              placeholder={"Search"}
              prefix={<SearchOutlined style={{ color: "#B9B9B9" }} />}
              style={{
                backgroundColor: "#FAFAFA",
                border: "1px solid #EAEAEA",
                borderRadius: "5px",
                width: "20rem",
              }}
            />
          </div>
        </div>
      </div>
      <AccountList
        data={list}
        onClick={(recr) => {
          history.replace(`/account/detail`, {
            account: recr,
            isSub: true,
          });
        }}
        isSub={true}
        showAcc={(record, data) => {
          return (
            <>
              {currencyMapper[record?.currency] ?? "$"}{" "}
              {visibleBalances[data.id]
                ? amountFormat(record?.balance)
                : "****".toUpperCase()}
              <Button
                className="eye mlHalf"
                type="link"
                icon={
                  !visibleBalances[data.id] ? (
                    <EyeInvisibleFilled />
                  ) : (
                    <EyeFilled />
                  )
                }
                onClick={(e) => {
                  e.stopPropagation();
                  setVisibleBalances((bal) => ({
                    ...bal,
                    [data.id]: !visibleBalances[data.id],
                  }));
                }}
              />
            </>
          );
        }}
      />
      <div className="flex-end">
        {total > 5 && (
          <Pagination
            current={currentPage}
            total={total}
            pageSize={5}
            onChange={handlePageChange}
            showSizeChanger={false} // Hide page size changer (optional)
          />
        )}
      </div>
    </div>
  );
};

export default ChildAccounts;
