import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import useNotificationAxios from "../../config/useNotification";
import { apis } from "../../config/APIs";
import { LeftNavContext } from "../../LeftNavProvider";

const OTPComponent = (props) => {
  const { t } = useTranslation();
  const { onCall, data, reset, loading } = useNotificationAxios({
    api: apis.resend_otp,
    method: "post",
  });
  const { width } = useContext(LeftNavContext);

  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");
  const [timeLeft, setTimeLeft] = useState(120);
  const [resendMessage, setResendMessage] = useState(false);

  const otpRefs = useRef([]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timeLeft]);

  const handleKeyUp = (event, prevRef, nextRef, setValue) => {
    const numKeyCodes = Array.from({ length: 10 }, (_, i) => i + 48);
    const numPadKeyCodes = Array.from({ length: 10 }, (_, i) => i + 96);
    if (event.keyCode === 37) {
      prevRef.select();
    } else if (event.keyCode === 39) {
      nextRef?.select();
    } else if ((event.keyCode === 8 || event.keyCode === 37) && prevRef) {
      prevRef?.focus();
      setValue("");
    } else if (
      numKeyCodes.includes(event.keyCode) ||
      numPadKeyCodes.includes(event.keyCode)
    ) {
      setValue(event.key);
      nextRef?.focus();
    }
  };

  useEffect(() => {
    props.onChange([otp1, otp2, otp3, otp4, otp5, otp6].join(""));
  }, [otp1, otp2, otp3, otp4, otp5, otp6]);

  const resendOtp = () => {
    if (!loading)
      onCall({
        data: {
          user_id: localStorage.getItem("user_id"),
          email_id: props.email_id || localStorage.getItem("user"),
          username: props.username,
          source: props.source,
          otp_token: props?.token,
          token: props?.token,
        },
        params: { device_type: "web" },
      })
        .then((res) => {
          if (res?.data) {
            setTimeLeft(120);
            setResendMessage(true);
            props?.setToken?.(res?.data);
            reset();
            setOtp1("");
            setOtp2("");
            setOtp3("");
            setOtp4("");
            setOtp5("");
            setOtp6("");
          }
        })
        .catch(() => reset());
  };

  return (
    <>
      {props.hideTitle || (
        <b className="alignCenter mt-2 text-center mb-1">
          {props.label || t("enterOtp")}
        </b>
      )}
      <div className="otpInputBlock">
        {[setOtp1, setOtp2, setOtp3, setOtp4, setOtp5, setOtp6].map(
          (setOtp, index) => {
            if (props.isFourDigit && index >= 4) return null;
            return (
              <div className="otpInputField" key={index}>
                <input
                  ref={(ref) => (otpRefs.current[index] = ref)}
                  value={[otp1, otp2, otp3, otp4, otp5, otp6][index]}
                  onKeyUp={(e) =>
                    handleKeyUp(
                      e,
                      otpRefs?.current[index > 0 ? index - 1 : 0],
                      otpRefs?.current[index + 1],
                      setOtp
                    )
                  }
                  disabled={props.loading}
                  name={`otp${index + 1}`}
                  maxLength={1}
                  autoComplete="off"
                  type={width < 900 ? "number" : "text"}
                />
              </div>
            );
          }
        )}
      </div>
      <div className="timeLeft">
        {timeLeft > 0 && (
          <div className="alignCenter">
            {t("otpExpirein")} {timeLeft} {t("paySec")}
          </div>
        )}
        {timeLeft === 0 && (
          <h5 className="otpMsg alignCenter mt-2 text-center mb-1">
            <span className="dim-text">{t("ididntReceiveTxtMsg")} </span>
            <span className="Cursor resendOtp" onClick={resendOtp}>
              {loading ? t("Sending OTP...") : t("resendOtp")}
            </span>
          </h5>
        )}
        {resendMessage && data?.message && (
          <h4 className="text-center dim-text">{t("otpSuccess")}</h4>
        )}
        {timeLeft === 0 && !data?.message && (
          <h4 className="alignCenter text-center dim-text">
            {t("otpExpired")}
          </h4>
        )}
      </div>
    </>
  );
};

export default OTPComponent;
