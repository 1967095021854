import { PlusCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Checkbox, Menu, Modal, Select, message } from "antd";
import React, { useCallback, useState } from "react";
import {
	DeleteEmployeeDocument,
	EmployeeById,
	employeebyId,
} from "./../../../../actions/employees";

import Text from "antd/lib/typography/Text";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
	GetCustomFields,
	companySetting,
	getCostCenterField,
	getCustomFields,
	getDesignations,
	getProjectsField,
	getRoles,
} from "../../../../actions/companySettings";
import { headerTitles } from "../../../../actions/masterdata/masterdata";
import DeleteIcon from "../../../../assets/icons/delete.png";
import EditIcon from "../../../../assets/icons/edit.png";
import endpointSettingsApi from "../../../../config/Axios";
import { objectToFormData } from "../../../../utils";
import TableComponent from "../../../Common/TableComp/TableComp";
import TableHeader from "../../../Sales/TableHeader";
import {
	AddEmployees,
	DeleteEmployees,
	UpdateEmployees,
	deleteEmployees,
	employees,
} from "./../../../../actions/employees";
import "./../../styles/setting.css";
import AddUsers from "./AddUser";
import UserDetails from "./UserDetails";

message.config({
	maxCount: 3,
});

const Users = ({ hasWriteAccess, isVirtual }) => {
	const employeesResponse = useSelector(
		(state) => state.employees.employees || {}
	);

	const deleteEmployeesResponse = useSelector(
		(state) => state.employees.deleteEmployees || {}
	);
	const addEmployeesResponse = useSelector(
		(state) => state.employees.addEmployees || {}
	);

	const updateEmployeesResponse = useSelector(
		(state) => state.employees.updateEmployees || {}
	);
	const totalRecords = useSelector(
		(state) => state?.employees?.employees?.data?.total_count
	);

	const deleteAttachmentResponse = useSelector(
		(state) => state.employees.deleteEmployeeDocument
	);

	const uploadAttachmentResponse = useSelector(
		(state) => state.employees.uploadEmployeeDocument
	);

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();

	const { data: { data: employeesRecord = [], total_count = 0, error } = {} } =
		employeesResponse;
	const [employeesData, setemployeesData] = React.useState([]);
	const [filters, setFilters] = React.useState({});
	const [selectedId, setSelectedId] = React.useState("");
	const [deleteRoles, setDeleteRoles] = React.useState({});
	const [search, setSearch] = React.useState("");
	const [showAddPage, setShowAddPage] = React.useState(false);
	const [clickedRecord, setClickedRecord] = React.useState("");
	const [showDetailsPage, setShowDetailsPage] = React.useState(false);
	const [isModalVisible, setIsModalVisible] = React.useState(false);
	const [data, setData] = useState([]);
	const [sum, setSum] = useState("");
	const [transfer, setTransfer] = useState(false);
	const [assignee, setAssignee] = useState(false);
	const [empId, setEmpId] = useState("");
	const [editTab, setEditTab] = useState(null);

	const columns = [
		{
			title: t("name"),
			dataIndex: "first_name",
			key: "first_name",
			render: (text) => (
				<div className="d-flex align-center">
					<div className="tableRowCircle mr1">
						{text ? text.charAt(0).toUpperCase() : "Z"}
					</div>
					{text}
				</div>
			),
		},
		{
			title: t("employee_id"),
			dataIndex: "emp_id",
			key: "emp_id",
			render: (text) => (text ? text : ""),
			width: 150,
		},
		{
			title: t("email"),
			dataIndex: "email",
			key: "email",
		},
		{
			title: t("mobile_number"),
			dataIndex: "primary_phone",
			key: "primary_phone",
			width: 150,
		},
		{
			title: t("role"),
			dataIndex: "roles",
			key: "role_name",
			render: (data) => data?.filter((ele) => ele !== "Employee").join(","),
		},

		{
			title: t("status"),
			dataIndex: "status",
			key: "status",
			render: (text) => (
				<div className={`tableStatus_${text}`}>
					{text === "Deleted" ? "Inactive" : "Active"}
				</div>
			),
		},
	];

	const tableAction = [
		{
			title: "Actions",
			key: "action",
			fixed: "right",
			width: 110,
			render: (text, record) => (
				<div>
					{record?.roles?.includes("Super Admin") ? (
						record?.email?.toLowerCase() ===
						localStorage.getItem("user")?.toLowerCase() ? (
							<button
								disabled={!hasWriteAccess}
								onClick={(e) => {
									e.stopPropagation();
									showEditModal(record.id);
									// console.log("recorddd isss",record);
								}}
							>
								<img src={EditIcon} alt={"logo"} className="action-logo mr1" />
							</button>
						) : null
					) : (
						<button
							disabled={!hasWriteAccess}
							onClick={(e) => {
								e.stopPropagation();
								showEditModal(record.id);
								// console.log("recorddd isss",record);
							}}
						>
							<img src={EditIcon} alt={"logo"} className="action-logo mr1" />
						</button>
					)}
					{record?.roles?.includes("Super Admin") ||
					record?.email?.toLowerCase() ===
						localStorage.getItem("user")?.toLowerCase() ? null : (
						<button
							disabled={!hasWriteAccess}
							onClick={(e) => {
								setSum(
									record?.pending_approvals?.advances +
										record?.pending_approvals?.trips +
										record?.pending_approvals?.reports
								);
								e.stopPropagation();
								showDeleteModal(record.id, record);
								let str = "";
								let arr = record.roles;
								arr.forEach((st, i) => {
									if (st !== "Employee") {
										str = str + "role[" + i + "]=" + st + "&";
									}
								});

								endpointSettingsApi
									.post("/v1/settings/0/users/getFilteredRoles", {
										role_name: arr,
									})
									.then((response) => {
										setData(
											response?.data?.data
												?.filter((ele) => ele.id !== record.id)
												.map((ele) => ({ label: ele.name, value: ele.id }))
										);
									});
							}}
						>
							<img src={DeleteIcon} alt={"logo"} className="action-logo" />
						</button>
					)}
				</div>
			),
		},
	];

	React.useEffect(() => {
		dispatch(
			employees({
				isVirtual,
			})
		);
		dispatch(getRoles({}));
		dispatch(getDesignations({ pagination: false }));
		dispatch(getProjectsField({ is_enable: true }));
		dispatch(getCostCenterField({ is_enable: true }));
		// dispatch(companySetting({}));

		dispatch(
			headerTitles({
				title: isVirtual ? "Virtual Users" : "users",
				description: `View ${isVirtual ? "virtual" : ""} user details and Edit`,
			})
		);
		// setIsModalVisible(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (error === false) {
			setemployeesData(employeesRecord);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [employeesResponse]);

	React.useEffect(() => {
		if (location.state && location.state?.openAddNew)
			setShowAddPage(location.state.openAddNew);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		const { data: { message: res_message = "", status = "", error } = {} } =
			addEmployeesResponse;
		if (error === false) {
			message.success(<span className="messageText">{res_message}</span>);
			setSelectedId("");
			setShowAddPage(false);
			setShowDetailsPage(false);
			setIsModalVisible(false);
			dispatch(employees(objectToFormData({ status: true, isVirtual })));
			dispatch({
				type: AddEmployees.RESET,
			});
		}
		if (error) {
			message.error(<span className="messageText">{res_message}</span>);
			dispatch({
				type: AddEmployees.RESET,
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addEmployeesResponse]);

	React.useEffect(() => {
		const {
			data: { message: res_message = "", status = "", errors = "" } = {},
		} = deleteEmployeesResponse;
		if (status) {
			message.success(<span className="messageText">{res_message}</span>);
			clearState();
			setSelectedId("");
			setShowAddPage(false);
			setShowDetailsPage(false);
			setIsModalVisible(false);
			dispatch(employees(objectToFormData({ status: true, isVirtual })));
			dispatch({
				type: DeleteEmployees.RESET,
			});
		} else if (status === false) {
			errors && message.error(<span className="messageText">{errors}</span>);
			dispatch({
				type: DeleteEmployees.RESET,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleteEmployeesResponse]);

	React.useEffect(() => {
		const {
			data: { message: res_message = "", status = "", error = "" } = {},
		} = updateEmployeesResponse;
		if (error === false) {
			message.success(<span className="messageText">{res_message}</span>);
			setSelectedId("");
			setShowAddPage(false);
			setShowDetailsPage(false);
			setIsModalVisible(false);
			dispatch(employees(objectToFormData({ status: true, isVirtual })));
			dispatch({
				type: UpdateEmployees.RESET,
			});
			dispatch({
				type: GetCustomFields.RESET,
			});
		} else if (error) {
			message.error(<span className="messageText">{res_message}</span>);
			dispatch({
				type: UpdateEmployees.RESET,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateEmployeesResponse]);

	React.useEffect(() => {
		const {
			data: { message: res_message = "", status = "", error = "" } = {},
		} = deleteAttachmentResponse;
		if (error === false) {
			message.success(
				<span className="messageText">Attachment Removed Successfully</span>
			);
			dispatch(getCustomFields({ employee_id: selectedId }));
			dispatch({
				type: DeleteEmployeeDocument.RESET,
			});
		}
		if (error) {
			message.error(<span className="messageText">{res_message}</span>);
		}
	}, [deleteAttachmentResponse]);

	React.useEffect(() => {
		const {
			data: { message: res_message = "", status = "", error = "" } = {},
		} = uploadAttachmentResponse;
		if (error === false) {
			message.success(<span className="messageText">{res_message}</span>);
		}
		if (error) {
			message.error(<span className="messageText">{res_message}</span>);
		}
	}, [uploadAttachmentResponse]);

	const handleAdd = () => {
		setSelectedId("");
		setShowAddPage(!showAddPage);
		setShowDetailsPage(false);
		// dispatch(getProjectsField({pagination:false}));
		// dispatch(getCostCenterField({pagination:false}))
		dispatch({ type: EmployeeById.RESET });
		dispatch(getCustomFields({}));
	};

	const handleSearch = (value) => {
		dispatch(employees({ search_key: value, isVirtual }));
		setFilters({
			...filters,
			search_key: value,
		});
	};

	const handleTransfer = () => {
		setTransfer(true);
	};

	const clearState = () => {
		setTransfer(false);
		setAssignee(false);
		setEmpId("");
	};

	const handleApprover = (value) => {
		setEmpId(value);
	};

	const handleAssign = (e) => {
		setAssignee(e.target.checked);
	};

	const showDeleteModal = (id, record) => {
		setDeleteRoles(record);
		setSelectedId(id);
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		clearState();
		setIsModalVisible(false);
	};

	const showEditModal = (id) => {
		setSelectedId(id);
		dispatch(getCustomFields({ employee_id: id }));
		dispatch(employeebyId({ employee_id: id }));
		setShowAddPage(true);
	};

	const handleRowClick = (evt, record) => {
		evt.preventDefault();
		if (record) {
			setClickedRecord(record?.id);
		}
		if (record) {
			dispatch(employeebyId({ employee_id: record?.id }));
			dispatch(getCustomFields({ employee_id: record?.id }));
			setShowDetailsPage(!showDetailsPage);
		} else {
			setShowDetailsPage(!showDetailsPage);
		}
	};

	const renderDeleteOption = () => {
		const roles = deleteRoles?.roles?.join();
		return (
			<>
				{deleteRoles?.roles?.length > 1 &&
				deleteRoles?.roles?.includes("Employee") ? (
					<>
						<Text>
							Employee will still be in the database to view but employee will
							be deleted and no longer will access the system.
						</Text>
						<br />
						<br />
						<Checkbox
							className="checkboxes"
							// value={assignee}
							checked={assignee}
							onChange={handleAssign}
						>
							{t("employee_assign")}
						</Checkbox>
						{sum > 0 ? (
							<Checkbox
								className="checkboxes"
								onChange={handleTransfer}
								checked={transfer}
								//value = {transfer}
								style={{ marginTop: 20, marginLeft: 2 }}
							>
								{t("transfer_pending")}
							</Checkbox>
						) : (
							""
						)}
						<br />
						<br />
						<Select
							onChange={handleApprover}
							options={data}
							style={{ width: 200 }}
							value={empId}
							disabled={!assignee}
						></Select>
					</>
				) : (
					<Text>Permanently deletes the user information.</Text>
				)}
				<br />
				<br />
				<Button
					key="1"
					className="formButton"
					onClick={() =>
						dispatch(
							deleteEmployees({
								// id: selectedId, status: "deleted", approver_id: empId,
								// transfer_pending_approvals: transfer, assign_approver: assignee
								params: {
									id: selectedId,
									approver_id: empId,
									// transfer_pending_approvals: transfer,
									assign_approver: assignee,
									// assign_approver:true
								},
							})
						)
					}
				>
					{t("delete")}
				</Button>
				<Button
					key="2"
					className="cancelButton ml1"
					onClick={() => handleCancel()}
				>
					{t("cancel")}
				</Button>
			</>
		);
	};

	const handleEvents = (value) => {
		setSearch(value);
		dispatch(employees({ search_key: value, isVirtual }));
	};

	const menu = (
		<Menu onClick={(e) => hasWriteAccess && handleMenuClick(e)}>
			<Menu.Item
				key="1"
				icon={<PlusCircleOutlined />}
				disabled={!hasWriteAccess}
			>
				{t("add_new_user")}
			</Menu.Item>
			<Menu.Item key="2" icon={<UploadOutlined />} disabled={!hasWriteAccess}>
				{t("uplaod_users")}
			</Menu.Item>
		</Menu>
	);

	// function handleButtonClick(e) {
	//   showModal();
	// }

	function handleMenuClick(e) {
		if (e?.key === "1") {
			handleAdd();
		} else {
			history.push("/upload-bulk-users");
		}
	}

	const handleCreatePaginator = useCallback((total, range) => {
		return `Showing ${range[0]} to ${range[1]} of ${total} entires`;
	}, []);
	return (
		<>
			<Modal
				title={t("delete_user")}
				visible={isModalVisible && hasWriteAccess}
				onCancel={handleCancel}
				onClose={clearState}
				footer={[]}
				className="center-aligned-modal"
			>
				{renderDeleteOption()}
			</Modal>
			{showAddPage && hasWriteAccess ? (
				<AddUsers
					handleAdd={handleAdd}
					selectedId={selectedId}
					setShowDetailsPage={setShowDetailsPage}
					setShowAddPage={setShowAddPage}
					editTab={editTab}
					isVirtual={isVirtual}
				/>
			) : showDetailsPage ? (
				<UserDetails
					handleRowClick={handleRowClick}
					showEditModal={showEditModal}
					showDeleteModal={showDeleteModal}
					selectedRecord={clickedRecord}
					hasWriteAccess={hasWriteAccess}
					setShowDetailsPage={setShowDetailsPage}
					handleAdd={handleAdd}
					setEditTab={setEditTab}
				/>
			) : (
				<>
					<TableHeader
						noFilter={true}
						handleAddButton={handleAdd}
						menu={menu}
						noSort={true}
						onSearch={handleEvents}
						hasWriteAccess={hasWriteAccess}
						noDD={true}
					/>
					<br />
					<TableComponent
						handleRowClick={handleRowClick}
						handleSearch={handleSearch}
						pagination={{
							total: totalRecords || 0,
							onChange: (pagee) =>
								dispatch(
									employees({ params: { page_number: pagee }, isVirtual })
								),
							showTotal: handleCreatePaginator,
						}}
						columns={[...columns, ...tableAction]}
						scroll={{
							x: "max-content",
						}}
						data={employeesData}
						hasCheckbox={false}
						className="settingsTable"
					/>
				</>
			)}
		</>
	);
};

export default Users;
