import { Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { LeftNavContext } from "../../LeftNavProvider";
import { Collapsed } from "../../actions/auth";
import AccountSVG from "../../assets/ff_icons/side-nav/AccountSVG";
import { DownSVG } from "../../assets/ff_icons/side-nav/AnalyticsSVG";
import CardSVG from "../../assets/ff_icons/side-nav/CardSVG";
import DashboardSVG from "../../assets/ff_icons/side-nav/DashboardSVG";
import SettingsSVG from "../../assets/ff_icons/side-nav/SettingsSVG";
import TransfersSVG from "../../assets/ff_icons/side-nav/TransfersSVG";
import TxnSVG from "../../assets/ff_icons/side-nav/TxnSVG";
import StatementsSVG from "../../assets/ff_icons/side-nav/statementsSVG";
import { LOGO, SHORT_LOGO } from "../../config/Images";
import {
  hasAccess,
  isAccount,
  isPayments,
  isSettings,
  isStatements,
  isTransfers,
} from "../../utils";
import "./leftNavigation.css";
import LeftNav from "../../mobile_components/LeftNav";

const createMobileMenuItem = (
  key,
  label,
  description,
  hasAccess,
  iconComponent,
  activeMenu,
  titleLabel,
  divider = false,
  children = []
) => ({
  key,
  label,
  description,
  divider,
  hasAccess,
  titleLabel,
  icon: React.cloneElement(iconComponent, {
    fill:
      activeMenu !== "/sales" &&
      activeMenu !== "/purchases" &&
      activeMenu !== "/transfers" &&
      activeMenu !== "/cards" &&
      key === activeMenu
        ? "#170A33"
        : "#170A3380",
    scale: 0.7,
    opacity: key === activeMenu ? 1 : null,
  }),
  children,
});

const createMenuItem = (
  key,
  label,
  description,
  hasAccess,
  iconComponent,
  activeMenu,
  titleLabel,
  divider = false,
  children = []
) => ({
  key,
  label,
  description,
  divider,
  hasAccess,
  titleLabel,
  icon: React.cloneElement(iconComponent, {
    fill:
      activeMenu !== "/sales" &&
      activeMenu !== "/purchases" &&
      activeMenu !== "/transfers" &&
      activeMenu !== "/cards" &&
      key === activeMenu
        ? "#170A33"
        : "#ffffff",
    scale: 0.7,
    opacity: key === activeMenu ? 1 : null,
  }),
  icon2: React.cloneElement(iconComponent, {
    fill: "#fff",
    scale: 0.7,
    opacity: 1,
  }),
  children,
});

const isProd = process.env.REACT_APP_DEVELOPMENT === "false";

const LeftNavigation = () => {
  // const [isModalVisible, setIsModalVisible] = React.useState(false);
  const isBasic =
    localStorage.getItem("user_account_access_type") === "BASIC" ||
    localStorage.getItem("user_account_access_type") === "PLATINUM";

  const {
    collapsed,
    setCollapsed,
    activeMenu,
    setActiveMenu,
    activeSubMenu,
    setActiveSubMenu,
    childrenMenu,
    setChildrenMenu,
    showChildren,
    setShowChildren,
    setNavChildren,
    toggle,
    setToggle,
    width,
  } = useContext(LeftNavContext);

  const dispatch = useDispatch();

  const side_menu = useSelector(
    (state) => state?.auth?.localVariables?.side_menu
  );
  const url = window.location.href;
  const history = useHistory();

  React.useEffect(() => {
    const storedMenu = localStorage.getItem("activeMenu");
    const storeActiveSubMenu = localStorage.getItem("activeSubMenu");
    const storeShowChildren = localStorage.getItem("showChildren");
    const storeChildrenMenu = localStorage.getItem("childrenMenu");
    const navChildren = localStorage.getItem("navChildren");
    setActiveMenu(storedMenu ? storedMenu : activeMenu);
    setActiveSubMenu(storeActiveSubMenu);
    setShowChildren(storeShowChildren);
    setChildrenMenu(storeChildrenMenu);
    if (navChildren) {
      setNavChildren(JSON.parse(navChildren));
    }
  }, []);

  React.useEffect(() => {
    localStorage.setItem("activeMenu", activeMenu);
    localStorage.setItem("activeSubMenu", activeSubMenu);
    localStorage.setItem("showChildren", showChildren);
    localStorage.setItem("childrenMenu", childrenMenu);
    if (["/transfers"]?.includes(activeMenu)) {
      setToggle({
        [activeMenu]: true,
      });
    }
    if (["/settings"]?.includes(activeMenu)) {
      setCollapsed(true);
    }
  }, [activeMenu, activeSubMenu, showChildren, childrenMenu]);

  React.useEffect(() => {
    const url = window.location.href;
    if (url.includes("expenses/")) {
      setActiveMenu("/expenses");
    }
    if (url.includes("transfers/")) {
      setToggle((b) => ({
        ["/transfers"]: true,
      }));
    }
  }, [url]);

  React.useEffect(() => {
    if (side_menu) {
      setActiveMenu(side_menu?.firstNav);
      setActiveSubMenu(side_menu?.secondNav);
      setShowChildren(side_menu?.showChildren);
      setChildrenMenu(side_menu?.childrenMenu);
      const itm = items?.find((ele) => ele.key === side_menu?.firstNav);
      if (itm) {
        setNavChildren(itm.children?.[0]?.children);
      }
    }
  }, [side_menu]);

  const { t } = useTranslation();
  const submenus = {
    "/settings": [
      {
        label: t("company_profile"),
        hasAccess: hasAccess("company_profile", "read"),
        key: "/profile",
        titleLabel: "Settings",
      },
      // {
      //   label: t("currencies"),
      //   hasAccess: hasAccess("currencies", "read") || true,
      //   key: "/currencies",
      // },
      {
        label: t("users_and_controls"),
        key: "",
        titleLabel: hasAccess("company_profile", "read") ? "" : "Settings",
        hasAccess:
          hasAccess("users", "read") ||
          hasAccess("roles_and_permissions", "read"),
        children: [
          {
            label: t("users"),
            hasAccess: hasAccess("users", "read"),
            key: "/users",
          },
          {
            label: t("Virtual users"),
            hasAccess: !isProd,
            key: "/virtual_users",
          },
          {
            label: t("roles_permissions"),
            hasAccess: hasAccess("roles_and_permissions", "read"),
            key: "/roles",
          },
        ],
      },
      // {
      //   label: t("categories"),
      //   key: "/categories",
      //   hasAccess: hasAccess("categories", "read"),
      // },
      // {
      //   label: t("polocies_limits"),
      //   hasAccess:
      //     hasAccess("expense_policy", "read") ||
      //     hasAccess("cards_policy", "read") ||
      //     hasAccess("payments_policy", "read") ||
      //     hasAccess("policies_and_limits", "read"),
      //   key: "/policies",
      //   children: [
      //     // {
      //     //   label: t("expense"),
      //     //   hasAccess: hasAccess("expense_policy", "read"),
      //     //   key: "/expense",
      //     // },
      //     // {
      //     //   label: t("Cards"),
      //     //   hasAccess: hasAccess("cards_policy", "read"),
      //     //   key: "/cards",
      //     // },
      //     {
      //       label: t("Transfers"),
      //       hasAccess: hasAccess("payments_policy", "read"),
      //       key: "/transfers",
      //     },
      //   ],
      // },
      // {
      //   label: t("Approval Workflow"),
      //   hasAccess: hasAccess("payment_approvals_settings", "read"),
      //   key: "/approvals",
      //   children: [
      //     {
      //       label: t("payments"),
      //       hasAccess: hasAccess("payment_approvals_settings", "read"),
      //       key: "/company_payments",
      //     },
      //     // {
      //     //   label: t("bulk_payments"),
      //     //   hasAccess: true,
      //     //   key: "/company_bulk_payments",
      //     // },
      //   ],
      // },
      // {
      //   label: t("custom"),
      //   hasAccess: isCustomisation(),
      //   key: "/customisation",

      //   children: [
      //     {
      //       label: t("modules"),
      //       key: "/custom_modules",
      //       hasAccess: isCustomisation(),
      //     },
      //     // {
      //     //   label: "PDF Templetes",
      //     //   key: "/reports",
      //     //   hasAccess: hasAccess("expense_reports_settings", "read")

      //     // }, {
      //     //   label: "Email Templates",
      //     //   key: "/trips",
      //     //   hasAccess: hasAccess("trips_settings", "read")

      //     // },
      //     // {
      //     //   label: "SMS Notifications",
      //     //   key: "/advances",
      //     //   hasAccess: hasAccess("advances_settings", "read")

      //     // }
      //   ],
      // },
      {
        label: t("Alerts"),
        hasAccess: hasAccess("alerts", "read"),
        key: "/alerts",
        titleLabel: "Personal",
      },
      {
        label: t("Security"),
        hasAccess: true,
        key: "/security",
      },
    ],
  };

  const items = [
    createMenuItem(
      "/dashboard",
      t("Dashboard"),
      "Overview of the Spends",
      true,
      <DashboardSVG />,
      activeMenu,
      "",
      true
    ),
    createMenuItem(
      "/account",
      t("Accounts"),
      "Connect and Manage Bank Accounts",
      isAccount(),
      <AccountSVG />,
      activeMenu,
      t("Accounts")
    ),
    createMenuItem(
      "/transactions",
      t("Transactions"),
      "View Transcations",
      hasAccess("account_transactions", "read"),
      <TxnSVG />,
      activeMenu
    ),
    createMenuItem(
      "/transfers",
      t("Payments"),
      "Bank Transfer, Bulk Transfer & Payment links",
      isTransfers(),
      <TransfersSVG />,
      activeMenu,
      t("Payments"),
      false,
      [
        {
          label: t("Send Money"),
          key: "/beneficiary",
          altAdminKey: "/payments",
          hasAccess: isPayments(),
          children: isBasic
            ? [
                {
                  label: t("my_payments"),
                  hasAccess: isPayments(),
                  key: "/payments",
                },
                {
                  label: t("Scheduled"),
                  hasAccess: isPayments(),
                  key: "/scheduled",
                },
              ]
            : [
                {
                  label: t("my_payments"),
                  hasAccess: isPayments(),
                  key: "/payments",
                },
                {
                  label: t("Awaiting Approvals"),
                  hasAccess: isPayments(),
                  key: "/approvals",
                },
                {
                  label: t("Scheduled"),
                  hasAccess: isPayments(),
                  key: "/scheduled",
                },
              ],
        },
        // {
        //   label: t("Request"),
        //   key: "/payment_links",
        //   hasAccess: true,
        // },
        {
          label: t("Recipients"),
          hasAccess: hasAccess("manage_recipients", "read"),
          key: "/manage_beneficiary",
        },
        // {
        //   label: t("bulk_transfer"),
        //   key: "/bulk",
        //   altAdminKey: "/my-bulk-payments",
        //   hasAccess:
        //     hasAccess("bulk_transfer_upload_payments", "read") ||
        //     hasAccess("bulk_transfers_history", "read"),
        //   children: isBasic
        //     ? [
        //         {
        //           label: t("bulk_payments"),
        //           hasAccess: hasAccess("bulk_transfers_history", "read"),
        //           key: "/my-bulk-payments",
        //         },
        //       ]
        //     : [
        //         {
        //           label: t("Awaiting Approvals"),
        //           hasAccess: hasAccess("bulk_transfers_history", "read"),
        //           key: "/approvals",
        //         },
        //         {
        //           label: t("bulk_payments"),
        //           hasAccess: hasAccess("bulk_transfers_history", "read"),
        //           key: "/my-bulk-payments",
        //         },
        //       ],
        // },
      ]
    ),
    createMenuItem(
      "/cards",
      t("Cards"),
      "Manage your Cards",
      localStorage.getItem("user_account_access_type") === "PLATINUM",
      <CardSVG />,
      activeMenu,
      t("Cards"),
      false,
      [
        {
          label: t("Debit Card"),
          key: "/debit-card",
          hasAccess: true,
        },
        // {
        //   label: t("Credit Card"),
        //   key: "/credit-card",
        //   hasAccess: true,
        // },
        // {
        //   label: t("Prepaid Cards"),
        //   hasAccess: true,
        //   key: "",
        // },
      ]
    ),
    createMenuItem(
      "/statements",
      t("statements"),
      "Review & Analyze and Download reports",
      isStatements(),
      <StatementsSVG />,
      activeMenu,
      t("statements"),
      true
    ),
    createMenuItem(
      "/settings",
      t("Settings"),
      "Customize and Configure",
      isSettings(),
      <SettingsSVG />,
      activeMenu,
      t("Settings")
    ),
  ];

  const mob_items = [
    createMobileMenuItem(
      "/dashboard",
      t("Dashboard"),
      "Overview of the Spends",
      true,
      <DashboardSVG />,
      activeMenu,
      "",
      false
    ),
    createMobileMenuItem(
      "/account",
      t("Accounts"),
      "Connect and Manage Bank Accounts",
      isAccount(),
      <AccountSVG />,
      activeMenu,
      t("Accounts")
    ),
    createMobileMenuItem(
      "/transactions",
      t("Transactions"),
      "View Transcations",
      hasAccess("account_transactions", "read"),
      <TxnSVG />,
      activeMenu
    ),
    createMobileMenuItem(
      "/transfers",
      t("Payments"),
      "Bank Transfer, Bulk Transfer & Payment links",
      isTransfers(),
      <TransfersSVG />,
      activeMenu,
      t("Payments"),
      false,
      [
        {
          label: t("Send Money"),
          key: "/beneficiary",
          altAdminKey: "/payments",
          hasAccess: isPayments(),
          children: isBasic
            ? [
                {
                  label: t("my_payments"),
                  hasAccess: isPayments(),
                  key: "/payments",
                },
                {
                  label: t("Scheduled"),
                  hasAccess: isPayments(),
                  key: "/scheduled",
                },
              ]
            : [
                {
                  label: t("my_payments"),
                  hasAccess: isPayments(),
                  key: "/payments",
                },
                {
                  label: t("Awaiting Approvals"),
                  hasAccess: isPayments(),
                  key: "/approvals",
                },
                {
                  label: t("Scheduled"),
                  hasAccess: isPayments(),
                  key: "/scheduled",
                },
              ],
        },
        // {
        //   label: t("Request"),
        //   key: "/payment_links",
        //   hasAccess: true,
        // },
        {
          label: t("Recipients"),
          hasAccess: hasAccess("manage_recipients", "read"),
          key: "/manage_beneficiary",
        },
        // {
        //   label: t("bulk_transfer"),
        //   key: "/bulk",
        //   altAdminKey: "/my-bulk-payments",
        //   hasAccess:
        //     hasAccess("bulk_transfer_upload_payments", "read") ||
        //     hasAccess("bulk_transfers_history", "read"),
        //   children: isBasic
        //     ? [
        //         {
        //           label: t("bulk_payments"),
        //           hasAccess: hasAccess("bulk_transfers_history", "read"),
        //           key: "/my-bulk-payments",
        //         },
        //       ]
        //     : [
        //         {
        //           label: t("Awaiting Approvals"),
        //           hasAccess: hasAccess("bulk_transfers_history", "read"),
        //           key: "/approvals",
        //         },
        //         {
        //           label: t("bulk_payments"),
        //           hasAccess: hasAccess("bulk_transfers_history", "read"),
        //           key: "/my-bulk-payments",
        //         },
        //       ],
        // },
      ]
    ),
    createMobileMenuItem(
      "/cards",
      t("Cards"),
      "Manage your Cards",
      localStorage.getItem("user_account_access_type") === "PLATINUM",
      <CardSVG />,
      activeMenu,
      t("Cards"),
      false,
      [
        {
          label: t("Debit Card"),
          key: "/debit-card",
          hasAccess: true,
        },
        // {
        //   label: t("Credit Card"),
        //   key: "/credit-card",
        //   hasAccess: true,
        // },
        // {
        //   label: t("Prepaid Cards"),
        //   hasAccess: true,
        //   key: "",
        // },
      ]
    ),
    createMobileMenuItem(
      "/statements",
      t("statements"),
      "Review & Analyze and Download reports",
      isStatements(),
      <StatementsSVG />,
      activeMenu,
      t("statements"),
      false
    ),
    createMobileMenuItem(
      "/settings",
      t("Settings"),
      "Customize and Configure",
      isSettings(),
      <SettingsSVG />,
      activeMenu,
      t("Settings")
    ),
  ];

  useEffect(() => {
    dispatch({
      type: Collapsed,
      payload: collapsed,
    });
  }, [collapsed]);

  const handleMenuItemClick = (menuItem) => {
    const child = menuItem.children?.find((ele) => ele.hasAccess)
      ? menuItem.children?.find((ele) => ele.hasAccess)
      : submenus[menuItem.key]?.find((ele) => ele.hasAccess);

    setActiveMenu(menuItem.key);
    setShowChildren(menuItem.children?.length > 0 ? true : false);
    setChildrenMenu(child?.key);
    setActiveSubMenu(child?.key);
    setToggle((b) => ({
      [menuItem.key]: !b[menuItem.key],
    }));
    if (["/settings"].includes(menuItem.key)) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }

    if (child?.children) {
      const path = child?.children?.find((ele) => ele.hasAccess)?.key;
      localStorage.setItem("navChildren", JSON.stringify(child?.children));
      if (path && submenus[menuItem.key]) {
        setShowChildren(true);
        setChildrenMenu(path);
        history.push(menuItem.key + (child?.key || "") + path);
        return;
      } else {
        setNavChildren(child?.children);
      }
    } else {
      setNavChildren([]);
      localStorage.setItem("navChildren", "[]");
    }
    history.push(
      menuItem.key + (child?.key || "") + (child?.altAdminKey || "")
    );
  };

  return (
    <>
      {width < 900 && (
        <LeftNav
          items={mob_items}
          activeMenu={activeMenu}
          setActiveSubMenu={setActiveSubMenu}
          setNavChildren={setNavChildren}
          activeSubMenu={activeSubMenu}
          handleMenuItemClick={handleMenuItemClick}
          toggle={toggle}
          showOtherMenu={submenus}
          otherMenu={submenus[activeMenu]}
          setChildrenMenu={setChildrenMenu}
          showChildren={showChildren}
          setShowChildren={setShowChildren}
          childrenMenu={childrenMenu}
        />
      )}
      <div
        className="d-flex left-nav"
        style={{
          zIndex: 1000,
          position: "relative",
        }}
      >
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          className={`left-menu ${collapsed ? "menu-collapsed" : ""}`}
        >
          <div
            className="logo mt2"
            onClick={() => {
              history.push("/dashboard");
              // setCollapsed((b) => !b);
            }}
          >
            {collapsed ? (
              <img src={SHORT_LOGO} alt="" />
            ) : (
              <img src={LOGO} alt="" />
            )}
          </div>
          <Menu mode="inline" defaultSelectedKeys={["1"]} collapsed={collapsed}>
            {items.map(
              (menuItem) =>
                menuItem.hasAccess && (
                  <div key={menuItem.key} id={menuItem.key}>
                    <div
                      className={
                        activeMenu === menuItem.key &&
                        menuItem.children.length === 0
                          ? `menu-item menu-selected `
                          : `menu-item`
                      }
                      onClick={() => handleMenuItemClick(menuItem)}
                    >
                      <span className="icon">{menuItem.icon}</span>
                      {!collapsed && (
                        <div className="flex-between w80">
                          <span className="nav-label">{menuItem.label}</span>
                          {menuItem.children?.length > 0 && (
                            <span
                              className={`svg  ${
                                toggle[menuItem.key] ? "" : "rotate"
                              }`}
                            >
                              <DownSVG fill={"#fff"} />
                            </span>
                          )}
                        </div>
                      )}
                      {collapsed && (
                        <div className="side-nav-hover">
                          <span className="icon">{menuItem.icon2}</span>
                          {menuItem.label}
                          <span className="dim-text">
                            {menuItem.description}
                          </span>
                        </div>
                      )}
                    </div>
                    {menuItem.divider && (
                      <div className="sub-menu-item-1">
                        <div className="line" />
                      </div>
                    )}
                    {menuItem.children?.length > 0 ? (
                      <div
                        className={`submenu-wrap ${
                          toggle[menuItem.key] ? "open" : ""
                        }`}
                      >
                        <SubMenuItems
                          activeMenu={menuItem.key}
                          activeSubMenu={activeSubMenu}
                          setActiveSubMenu={setActiveSubMenu}
                          items={items}
                          setNavChildren={setNavChildren}
                        />
                      </div>
                    ) : null}
                    <br />
                  </div>
                )
            )}
          </Menu>
        </Sider>
        {collapsed && submenus[activeMenu] && (
          <div className="sub-menu collapse">
            {/* {items.find((item) => item?.key === activeMenu) && (
            <h4 className="titleLabel">
              {items.find((item) => item?.key === activeMenu)?.titleLabel}
            </h4>
          )} */}
            {submenus[activeMenu]?.map(
              (itm) =>
                itm.hasAccess && (
                  <>
                    {itm.titleLabel && (
                      <h4 className="titleLabel">{itm.titleLabel}</h4>
                    )}
                    <div
                      key={itm.key}
                      className={
                        activeSubMenu === itm.key
                          ? `sub-menu-item sub-menu-selected ${
                              itm.children ? "has-children" : ""
                            }`
                          : `sub-menu-item ${
                              itm.children ? "has-children" : ""
                            }`
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        setActiveSubMenu(itm.key);
                        if (itm.children) {
                          const indexValue = itm.children.findIndex(
                            (item) => item.hasAccess
                          );
                          setChildrenMenu(itm.children?.[indexValue].key);
                          setShowChildren(true);
                          history.push(
                            activeMenu +
                              itm.key +
                              (itm.children?.[indexValue].key ??
                                itm.altAdminKey)
                          );
                          return;
                        } else {
                          setShowChildren(false);
                        }
                        console.log("nav", activeMenu + itm.key);
                        history.push(activeMenu + itm.key);
                      }}
                    >
                      <span className="nav-label">{itm.label}</span>
                    </div>
                    {showChildren &&
                      itm.children &&
                      activeSubMenu === itm.key && (
                        <div className="sub-menu-children">
                          {itm.children?.map(
                            (ele) =>
                              ele.hasAccess && (
                                <div
                                  className={
                                    childrenMenu === ele.key
                                      ? `sub-menu-children-item sub-menu-selected`
                                      : `sub-menu-children-item`
                                  }
                                  onClick={() => {
                                    setChildrenMenu(ele.key);
                                    history.push(
                                      activeMenu + activeSubMenu + ele.key
                                    );
                                  }}
                                >
                                  <span className="nav-label">{ele.label}</span>
                                </div>
                              )
                          )}
                        </div>
                      )}
                    {itm.divider && (
                      <div className="sub-menu-item-1">
                        {itm.navTitle && (
                          <div className="titleLabel">{itm.navTitle}</div>
                        )}
                        <div className="line" />
                      </div>
                    )}
                  </>
                )
            )}
          </div>
        )}
      </div>
    </>
  );
};

export const SubMenuItems = ({
  items,
  activeMenu,
  activeSubMenu,
  setActiveSubMenu,
  setNavChildren,
}) => {
  const submenuItems = items.find((item) => item.key === activeMenu)?.children
    ? items.find((item) => item.key === activeMenu)?.children
    : [];

  const history = useHistory();
  const [toggle, setToggle] = React.useState({});

  const groupItemsByGroup = (children) => {
    const groupedItems = {};

    children.forEach((item) => {
      const group = item.group;
      if (item.group) {
        if (!groupedItems[group]) {
          groupedItems[group] = [];
        }

        groupedItems[group].push(item);
      } else groupedItems[item.key] = item;
    });

    const result = Object.values(groupedItems);

    return result;
  };

  const newItems = groupItemsByGroup(submenuItems);

  return (
    <ul className={"submenu"}>
      {newItems.map((submenuItem, index) => (
        <React.Fragment key={index}>
          {Array.isArray(submenuItem) ? (
            <>
              {submenuItem[0].titleLabel && (
                <p
                  className="titleLabel"
                  onClick={(e) => {
                    e.stopPropagation();
                    setToggle({
                      [submenuItem[0].group]: !toggle[submenuItem[0].group],
                    });
                  }}
                >
                  {submenuItem[0].titleLabel}
                </p>
              )}
              <div
                className={`${
                  toggle[submenuItem[0].group] ? "view" : "hide"
                } expand collapse`}
              >
                {submenuItem.map(
                  (subItem) =>
                    subItem.hasAccess && (
                      <li
                        key={subItem.key}
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveSubMenu(subItem.key);
                          history.push(activeMenu + subItem.key);
                        }}
                        className={`${
                          activeSubMenu === subItem.key
                            ? "submenu-item-selected"
                            : ""
                        }`}
                      >
                        {subItem.label}
                      </li>
                    )
                )}
              </div>
            </>
          ) : (
            submenuItem.hasAccess && (
              <li
                key={submenuItem.key}
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveSubMenu(submenuItem.key);
                  if (submenuItem.children) {
                    setNavChildren(submenuItem.children);
                    localStorage.setItem(
                      "navChildren",
                      JSON.stringify(submenuItem.children)
                    );
                  } else {
                    setNavChildren([]);
                    localStorage.setItem("navChildren", "[]");
                  }
                  history.push(
                    activeMenu +
                      submenuItem.key +
                      (submenuItem?.children?.[0]?.key || "")
                  );
                }}
                className={`${
                  activeSubMenu === submenuItem.key
                    ? "submenu-item-selected"
                    : ""
                }`}
              >
                {submenuItem.label}
              </li>
            )
          )}
        </React.Fragment>
      ))}
    </ul>
  );
};
export default withRouter(LeftNavigation);
