import { Button, Col, Row, Upload, message } from "antd";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { ExcelRenderer } from "react-excel-renderer";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { get_cmp_accounts } from "../../../actions/accounts/accounts";
import SampleBulkTransfers from "../../../assets/images/Sample.xlsx";
import png_icon from "../../../assets/icons/excel1.png";
import SampleBulkPayments from "../../../assets/images/sampleBulkPayments.xlsx";
import { FormInput, FormSelect } from "../../inputs";
import DateInput from "../../inputs/DateInput";
import "../transfer.css";
import { useHistory } from "react-router-dom";
import { LeftNavContext } from "../../../LeftNavProvider";
import ButtonUpload from "../../inputs/ButtonUpload";
import { get_person_accounts } from "../../../actions/z_accounts";
import { amountFormat, getFileExtension } from "../../../utils";
import delete_cross from "../../../assets/icons/delete.png";

const UploadPaymentFirst = ({
  setdata,
  register,
  control,
  errors,
  setValue,
  setAttachments,
  attachments,
  type,
  setIsFileUpload,
  setFileErrs,
  watch,
  from,
  step,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { setNavChildren } = useContext(LeftNavContext);
  useEffect(() => {
    dispatch(get_cmp_accounts());
    dispatch(
      get_person_accounts({
        params: {
          page_number: 1,
          per_page: 1000,
        },
        person_id: "ID-1146",
      })
    );
  }, []);

  const bankAcc = useSelector(
    (state) => state?.z_accs?.accounts?.data?.data || []
  );
  const validationSchema =
    type === "bulk_payment"
      ? Yup.object().shape({
          email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
          phoneNo: Yup.string().required("Phone number is required"),
          payment_amount: Yup.number()
            .typeError("Payment amount must be a number")
            .required("Payment amount is required"),
        })
      : Yup.object().shape({
          email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
          beneficiary_name: Yup.string().required(
            "Beneficiary name is required"
          ),
          payment_bank_name: Yup.string().required("Bank name is required"),
          payment_bank_account_no: Yup.string().required(
            "Bank account number is required"
          ),
          payment_bank_ifsc_number: Yup.string().required(
            "IFSC number is required"
          ),
          payment_amount: Yup.number()
            .typeError("Payment amount must be a number")
            .required("Payment amount is required")
            .test(
              "greaterThanZero",
              "Amount must be greater than 0",
              (value) => parseFloat(value) > 0
            ),
          phone: Yup.string().required("Phone number is required"),
          purpose_code: Yup.string().required("Purpose code is required"),
        });

  const fileHandler = (fileList) => {
    let fileObj = fileList;
    setFileErrs({});
    if (
      fileList.name?.includes(".csv") ||
      fileList.name?.includes(".xlsx") ||
      fileList.name?.includes(".xls")
    ) {
      setIsFileUpload(fileList.name);
      ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
          console.log(err);
        } else {
          let newRows = [];
          resp.rows.slice(1).map((row, index) => {
            if (row && row !== "undefined") {
              if (type === "bulk_payment") {
                newRows.push({
                  email: row[0],
                  phoneNo: row[1],
                  payment_amount: row[2],
                });
              } else {
                newRows.push({
                  email: row[1],
                  beneficiary_name: row[0],
                  payment_bank_name: row[2],
                  payment_bank_account_no: String(row[3]),
                  payment_bank_ifsc_number: String(row[4]),
                  payment_amount: row[5],
                  phone: String(row[6]),
                  purpose_code: "",
                });
              }
            }
          });

          setdata(newRows);
          const validationErrorsFunc = async () => {
            const validationPromises = newRows.map(async (item, index) => {
              try {
                await validationSchema.validate(item, { abortEarly: false });
              } catch (validationError) {
                if (validationError.inner) {
                  const errorMessages = validationError.inner.reduce(
                    (acc, error) => {
                      acc[error.path] = error.message;
                      return acc;
                    },
                    {}
                  );
                  return { [index]: errorMessages };
                }
              }
              return null;
            });

            const validationResults = await Promise.all(validationPromises);
            const validationErrors = validationResults.filter(
              (result) => result !== null
            );

            setFileErrs(
              validationErrors?.reduce((acc, error) => {
                return { ...acc, ...error };
              }, {})
            );
          };
          validationErrorsFunc();
          setValue("list", newRows);
        }
      });
    } else {
      message.error(
        <span className="messageText">Please upload excel or csv file.</span>
      );
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  const handleChange = (info) => {
    setAttachments(info?.file);
    setValue("file_name", info?.file?.name);
  };
  const to_acc = watch("debit_account_id");

  const to_acnt = bankAcc?.find((ele) => ele.id === to_acc);

  return (
    <>
      <div
        className="upload_payment_form"
        style={{ display: step === 0 ? "block" : "none" }}
      >
        <Row>
          <div className="center_line"></div>
          <Col span={12}>
            <div className="pl2">
              <div className="pp-form-item field_length">
                <FormInput
                  control={control}
                  {...register("payment_batchName", {
                    required: "Batch name is required",
                    onChange: (e) => {
                      setValue("batch_name", e?.target?.value);
                    },
                  })}
                  errors={errors}
                  label={t("batch_name")}
                  required={true}
                  // value={values?.batch_name}
                  placeholder={t("enter_batch")}
                />
              </div>
              {type ? null : (
                <div className="pp-form-item field_length">
                  <FormSelect
                    inline
                    control={control}
                    {...register("debit_account_id", {
                      required: t("debit_error_msg"),
                      onChange: (e) => {
                        const dt = bankAcc?.find(
                          (ele) => ele.id === e.target.value
                        ).account_type;

                        setValue("debit_account_type", dt);
                        setValue("debit_account_id", e.target.value);
                      },
                    })}
                    required
                    errors={errors}
                    label={t("debit_account")}
                    placeholder={t("select")}
                    options={bankAcc?.map((ele) => ({
                      label: `${ele.account_name} - ${ele.account_number}`,
                      value: ele.id,
                    }))}
                    hideCreateOption
                  />
                  {to_acnt?.id && (
                    <p className="mt1">
                      Available Balance: ${" "}
                      {amountFormat(to_acnt?.balance?.balance)}
                    </p>
                  )}
                </div>
              )}

              <div className="pp-form-item field_length">
                <ButtonUpload
                  control={control}
                  {...register(`payment_files`, {
                    onChange: (e) => {
                      handleChange(e.target.value);
                    },
                  })}
                  errors={errors}
                  label={t("upload_excel")}
                  multiple={false}
                  beforeUpload={fileHandler}
                  hasId={attachments?.name}
                  renderUploaded={
                    <div className="business_image_section mt3">
                      <div className="upload-sec mb1">
                        <div className="left_section d-flex">
                          <div className="image_section mr1">
                            {(() => {
                              if (
                                ["csv", "xlsx"]?.includes(
                                  getFileExtension(
                                    attachments?.name
                                  )?.toLowerCase()
                                )
                              ) {
                                return <img src={png_icon} />;
                              } else {
                                return <img src={attachments?.url} />;
                              }
                            })()}
                          </div>
                          <div className="image_name">
                            <h4
                              className="file_name ellipsis"
                              title={attachments?.name}
                              style={{
                                maxWidth: "15rem",
                              }}
                            >
                              {attachments?.name}
                            </h4>
                            {/* <span className='file_verify'>Verify</span> */}
                          </div>
                        </div>
                        <div className="right_section">
                          <div className="active_section d-flex">
                            {/* <div
                                className="preview_section mr1"
                                onClick={() => {
                                  let attachments = getObjectByFieldNameAndValue(
                                    fieldElement?.name,
                                    watch(fieldElement.name)
                                  );
                                  setIsPreviewOpen({
                                    open: true,
                                    fileType: getFileExtension(
                                      attachments?.uploadFileName
                                    ),
                                    url: getObjectByFieldNameAndValue(
                                      fieldElement?.name,
                                      watch(fieldElement.name)
                                    )?.url,
                                  });
                                }}
                              >
                                <img
                                  src={preview_icon}
                                  alt="preview-icon"
                                  className="preview_img w100 h100"
                                />
                              </div> */}
                            <div
                              className="delete_section"
                              onClick={() => {
                                setAttachments([]);
                              }}
                            >
                              <img
                                src={delete_cross}
                                alt="delete-icon"
                                className="delete_img"
                                width={30}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                />
              </div>
              {type ? null : (
                <div className="pp-form-item field_length">
                  <DateInput
                    control={control}
                    {...register("schedule_payment_date", {
                      onChange: (e) => {
                        setValue(
                          "schedule_payment_date",
                          e?.target?.value ? moment(e?.target?.value) : null
                        );
                      },
                    })}
                    errors={errors}
                    label={t("schedule_payment")}
                    isTime={false}
                    picker={"date"}
                    dateFormat={["DD/MM/YYYY"]}
                    minDate={new Date()}
                    // value={values?.schedule_payment_date ? values?.schedule_payment_date : null}
                  />
                  <span className="note_span">{t("transfer_transaction")}</span>
                </div>
              )}
              <div className="pp-form-item field_length">
                <FormInput
                  control={control}
                  {...register("batch_description", {
                    onChange: (e) => {
                      setValue("batch_description", e?.target?.value);
                    },
                  })}
                  errors={errors}
                  label={t("desc")}
                  placeholder={t("enter_desc")}
                  // value={values?.batch_description}
                />
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="right_div">
              <h4 className="right_header">{t("download_samples")}</h4>
              <span className="right_span">
                {t("plain_template")}
                <br />
                {t("without_employee")}
              </span>
              <Button
                className="right_button"
                href={type ? SampleBulkPayments : SampleBulkTransfers}
              >
                {t("download_sample_form")}
              </Button>
              <div className="or">or</div>
              <span className="right_span">{t("csv_template")}</span>
              <Button
                className="right_button"
                onClick={() => {
                  setNavChildren([]);
                  history.push("/transfers/manage_beneficiary", {
                    fromPayLinks: from !== "bulk_payments",
                  });
                }}
              >
                {t("download_beneficiaries")}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UploadPaymentFirst;
